import {
  GET_STATUS_OPTIONS,
  GET_STATUS_OPTIONS_FAILED,
  GET_FORMS_TODO_LIST,
  SET_FORMS_TODO_LIST,
} from './types';
import { API_STATUS_CONSTANTS } from '../../app/constants/form-constants';

const initialState = {
  statusOptions: [],
  statusLoaded: false,
  formTodoList: {
    data: [],
    status: API_STATUS_CONSTANTS.init,
  },
};

export function constantStateReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_STATUS_OPTIONS:
      return {
        ...state,
        statusOptions: payload,
        statusLoaded: true,
      };
    case GET_FORMS_TODO_LIST:
      return {
        ...state,
        formTodoList: {
          data: [],
          status: API_STATUS_CONSTANTS.loading,
        },
      };
    case SET_FORMS_TODO_LIST:
      return {
        ...state,
        formTodoList: {
          data: action.payload.data,
          status: action.payload.status,
        },
      };
    case GET_STATUS_OPTIONS_FAILED:
    default:
      return state;
  }
}
