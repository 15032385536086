import {
  SAVE_CUSTOMER_JOURNEY,
  SAVE_CUSTOMER_JOURNEY_FAILED,
  GET_CUSTOMER_JOURNEY,
  GET_CUSTOMER_JOURNEY_FAILED,
  GET_EDGE_CTA_LIST,
  UPDATE_EDGE_CTA_LIST,
  CLEAR_CUSTOMER_JOURNEY,
  GET_CREATE_EVENT_SURVEY_STEP_AUTO,
  GET_CREATE_WEBSITE_STEP_AUTO,
  SET_CREATE_EVENT_SURVEY_STEP_AUTO,
  SET_CREATE_WEBSITE_STEP_AUTO,
  SET_DELETED_AUTOMATED_WEBSITE,
  RESET_DELETED_AUTOMATED_WEBSITES,
  GET_CALLS_DATA,
  UPDATE_CALLS_DATA,
  DELETE_CALLS_DATA,
} from './types';
import { API_STATUS_CONSTANTS } from '../../app/constants/form-constants';

const initialState = {
  campaign_id: '',
  campaignName: '',
  campaignStartDate: '',
  NodesList: null,
  connectionsList: [],
  loading: true,
  fragmentCount: [],
  edgeCtaList: [],
  edgeCallsData: [],
  edgeCallsDataToRemove: [],
  createEventSurvey: { data: {}, status: API_STATUS_CONSTANTS.init },
  createwebsiteSurvey: { data: {}, status: API_STATUS_CONSTANTS.init },
  deletedAutomatedWebsites: []
};

export function canvasBuilderReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_CUSTOMER_JOURNEY:
      return {
        ...state,
        loading: true,
      };

    case GET_CUSTOMER_JOURNEY:
      return {
        ...state,
        campaign_id: payload.campaign_id,
        campaignName: payload.campaignName,
        campaignStartDate: payload.campaignStartDate,
        NodesList: payload.nodes,
        connectionsList: payload.connections,
        fragmentCount: payload.fragmentCount,
        loading: false,
      };

    case GET_EDGE_CTA_LIST:
    case UPDATE_EDGE_CTA_LIST:
      return {
        ...state,
        edgeCtaList: payload,
      };
    
    case GET_CALLS_DATA:
    case UPDATE_CALLS_DATA:
      return {
        ...state,
        edgeCallsData: payload
      }
    case DELETE_CALLS_DATA:
      return {
        ...state,
        edgeCallsDataToRemove: payload
      }

    case CLEAR_CUSTOMER_JOURNEY:
      return { ...initialState };

    case SAVE_CUSTOMER_JOURNEY_FAILED:
    case GET_CUSTOMER_JOURNEY_FAILED:
      return { ...state, loading: false };

    case GET_CREATE_EVENT_SURVEY_STEP_AUTO:
      return {
        ...state,
        createEventSurvey: { data: {}, status: API_STATUS_CONSTANTS.loading },
      };
      case GET_CREATE_WEBSITE_STEP_AUTO:
        return {
          ...state,
          createwebsiteSurvey: { data: {}, status: API_STATUS_CONSTANTS.loading },
        };  
    case SET_CREATE_EVENT_SURVEY_STEP_AUTO:
      return {
        ...state,
        createEventSurvey: {
          data: action.payload.data,
          status: action.payload.status,
        },
      };
      case SET_CREATE_WEBSITE_STEP_AUTO:
        return {
          ...state,
          createwebsiteSurvey: {
            data: action.payload.data,
            status: action.payload.status,
          },
        };  

    case SET_DELETED_AUTOMATED_WEBSITE:
      return {
        ...state, 
        deletedAutomatedWebsites: [ ...state.deletedAutomatedWebsites, action.payload ]
      };
    case RESET_DELETED_AUTOMATED_WEBSITES:
      return {
        ...state,
        deletedAutomatedWebsites: []
      }
    default:
      return state;
  }
}
