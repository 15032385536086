import * as types from './types';
const initialState = {
  data: [],
  isFetching: false,
  error: undefined,
};

export const specialitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SPECIALITIES_REQUEST:
      return { ...state, isFetching: true, error: undefined };
    case types.FETCH_SPECIALITIES_FAILURE:
      return { ...state, isFetching: false, error: action.payload };
    case types.FETCH_SPECIALITIES_SUCCESS:
      return { ...state, isFetching: false, data: action.payload, error: undefined };
    default:
      return state;
  }
};
