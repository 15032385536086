export const UPDATE_SURVEY_DRAFT = 'update_survey_draft';
export const SAVE_SURVEY = 'save_survey';
export const NAVIGATE_STEP_SURVEY = 'navigate_step_survey';
export const CREATE_SURVEY_SUCCESS = 'create_survey_success';
export const CREATE_SURVEY_FAILURE = 'create_survey_failure';
export const LOAD_SURVEY = 'load_survey';
export const LOAD_SURVEY_FAILURE = 'load_survey_failure';
export const LOAD_SURVEY_SUCCESS = 'load_survey_success';
export const DELETE_FILE_FAILURE = 'delete_file_failure';
export const DELETE_FILE_SUCCESS = 'delete_file_success';
export const RESET_SURVEY = 'reset_survey';
export const SEND_FOR_REVIEW_SUCCESS = 'send_for_review_success';
export const SEND_FOR_REVIEW_FAILURE = 'send_for_review_failure';
export const LOAD_CANVAS_SUCCESS = 'store/survey/load_canvas_success';
export const LOAD_CANVAS_FAILURE = 'store/survey/load_canvas_failure';
export const LOAD_SURVEY_TAGS_LIST = 'load_survey_tags_list';
export const LOAD_SURVEY_TAGS_LIST_FAILURE = 'load_survey_tags_list_failure';
export const GET_SURVEY_TYPES_PENDING = 'get_survey_types_pending';
export const GET_SURVEY_TYPES_SUCCESS = 'get_survey_types_success';
export const GET_SURVEY_TYPES_FAILURE = 'get_survey_types_failure';

