import {
  LOAD_EMAIL_TEMPLATES_FAILURE,
  LOAD_EMAIL_TEMPLATES_SUCCESS,
  LOAD_EMAIL_USED_TECHNOLOGY_SUCCESS,
  LOAD_EMAIL_USED_TECHNOLOGY_FAILURE,
  LOAD_EMAIL_JOURNEY_FAILURE,
  LOAD_EMAIL_JOURNEY_SUCCESS,
  LOAD_CTA_LABELS_SUCCESS,
  LOAD_CTA_LABELS_FAILURE,
  NAVIGATE_STEP,
  UPDATE_EMAIL_JOURNEY_DRAFT,
  DELETE_FILE_FAILURE,
  DELETE_FILE_SUCCESS,
  CREATE_EMAIL_JOURNEY_SUCCESS,
  RESET_EMAIL_JOURNEY,
  SEND_FOR_REVIEW_SUCCESS,
  SEND_FOR_REVIEW_FAILURE,
  LOAD_EMAIL_TYPE_AND_SUBTYPE,
  LOAD_EMAIL_TAGS_LIST,
  LOAD_EMAIL_TAGS_LIST_FAILURE,
  GET_EVENT_DETAILS,
  SET_EVENT_DETAILS,
  GET_INTEGRATION_TEAM,
  SET_INTEGRATION_TEAM,
  GET_SENDERS_LIST,
  SET_SENDERS_LIST,
  RECALL_FROM_REVIEW_SUCCESS,
  RECALL_FROM_REVIEW_FAILURE,
  RESET_EMAIL_EDIT_LOADED_STATE,
  GET_EMAIL_EVENT_FORM_LINK_STATUS,
  GET_LINKED_WEBSITES,
  SET_MESSAGE_DETAILS,
  SET_CHANNEL_DETAILS,
} from './types';
import { API_STATUS_CONSTANTS } from '../../app/constants/form-constants';

const initialState = {
  requestInProgress: false,
  saveSuccess: false,
  activeStep: 0,
  emailEditDataLoaded: false,
  journeyDraft: {
    stepId: -1,
    general: {},
    cta: [],
    campaignDetails: [],
    reminders: {},
    files: [],
    additionalUrls: [],
    internalAudianceSender: [],
    tags: [],
    canvasCustomTag: [],
    isSaved: 1,
    isReminderEdited: 0,
    validateErrors: {},
    missingFieldErrors: {},
    prevNodeEndDate: '',
    switchEmailId: null,
    requiredFieldsCount: 0,
  },
  eventDetails: {
    data: {},
    status: API_STATUS_CONSTANTS.init,
  },
  integrationTeam: {
    data: [],
    status: API_STATUS_CONSTANTS.init,
  },
  sendersList: {
    data: [],
    status: API_STATUS_CONSTANTS.init,
  },
  isEventFormLinked: false,
  linkedWebsites: [],
  emailTemplates: [],
  validateErrors: {},
  emailType: [],
  emailSubtype: [],
  emailTypesLoaded: false,
  isSentForReview: false,
  formStatus: {},
  stepDisplayId: '',
  tagsListData: [],
  stepCanvasData: '',
  teamsIntegrationDetails: {},
  isLinkToSwitchEnabled: false,
};

export function emailJourneyCreateReducer(state = initialState, action) {
  let res = [];
  switch (action.type) {
    case LOAD_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        requestInProgress: false,
        emailTemplates: action.payload,
        emailTemplateLoaded: true,
      };
    case LOAD_EMAIL_TEMPLATES_FAILURE: // handle error
      return { ...state, requestInProgress: false };
    case LOAD_CTA_LABELS_SUCCESS:
      let res_arr = [];
      if (action.payload) {
        res_arr = action.payload;
      }
      return {
        ...state,
        requestInProgress: false,
        ctaLabels: res_arr,
        ctaLabelsLoaded: true,
      };
    case LOAD_CTA_LABELS_FAILURE: // handle error
      return { ...state, requestInProgress: false };
    case LOAD_EMAIL_JOURNEY_SUCCESS:
      res = loadSurveyData(action.payload, action.stepId, action.prevNodeEndDate);
      return {
        ...state,
        requestInProgress: false,
        journeyDraft: res,
        isLinkToSwitchEnabled: !!res.general?.isSwitchTemplate,
        emailEditDataLoaded: true,
        isSentForReview: !action.payload?.status.userEditable,
        formStatus: action.payload.status,
        stepDisplayId: action.payload.stepDisplayId,
        stepCanvasData: action.payload.canvasData,
        teamsIntegrationDetails: action.payload.teamsIntegrationDetails,
        switchEmail: action.payload.switchEmail
      };
    case LOAD_EMAIL_JOURNEY_FAILURE: // handle error
      return { ...state, requestInProgress: false };
    case DELETE_FILE_FAILURE: // handle error
      return { ...state, requestInProgress: false };
    case DELETE_FILE_SUCCESS:
      return { ...state, requestInProgress: false };
    case LOAD_EMAIL_USED_TECHNOLOGY_SUCCESS:
      return {
        ...state,
        requestInProgress: false,
        emailUsedTechnology: action.payload,
        emailUsedTechnologyLoaded: true,
      };
    case LOAD_EMAIL_USED_TECHNOLOGY_FAILURE: // handle error
      return { ...state, requestInProgress: false };
    case CREATE_EMAIL_JOURNEY_SUCCESS:
      return {
        ...state,
        journeyDraft: { ...state.journeyDraft, isSaved: 1 },
        requestInProgress: false,
        emailEditDataLoaded: false,
      };
    case UPDATE_EMAIL_JOURNEY_DRAFT:
      return { ...state, journeyDraft: action.payload, validateErrors: {} };
    case NAVIGATE_STEP:
      return { ...state, activeStep: action.step };
    case RESET_EMAIL_JOURNEY:
      return initialState;
    case SEND_FOR_REVIEW_SUCCESS:
      return { ...state, isSentForReview: true, emailEditDataLoaded: false };
    case RECALL_FROM_REVIEW_SUCCESS:
      return { ...state, isSentForReview: false, emailEditDataLoaded: false };
    case LOAD_EMAIL_TYPE_AND_SUBTYPE:
      return {
        ...state,
        emailType: action.payload.type,
        emailSubtype: action.payload.subtype,
        emailTypesLoaded: true,
      };
    case RECALL_FROM_REVIEW_FAILURE:
    case SEND_FOR_REVIEW_FAILURE: // handle error
      return { ...state, emailEditDataLoaded: false };
    case GET_EVENT_DETAILS:
      return {
        ...state,
        eventDetails: {
          data: {},
          status: API_STATUS_CONSTANTS.loading,
        },
      };
    case SET_EVENT_DETAILS:
      return {
        ...state,
        eventDetails: {
          data: action.payload.data,
          status: action.payload.status,
        },
      };
    case GET_INTEGRATION_TEAM:
      return {
        ...state,
        integrationTeam: {
          data: [],
          status: API_STATUS_CONSTANTS.loading,
        },
      };
    case SET_INTEGRATION_TEAM:
      return {
        ...state,
        integrationTeam: {
          data: action.payload.data,
          status: action.payload.status,
        },
      };
    case GET_SENDERS_LIST:
      return {
        ...state,
        sendersList: {
          data: [],
          status: API_STATUS_CONSTANTS.loading,
        },
      };
    case SET_SENDERS_LIST:
      return {
        ...state,
        sendersList: {
          data: action.payload.data,
          status: action.payload.status,
        },
      };
    case GET_EMAIL_EVENT_FORM_LINK_STATUS:
      return {
        ...state,
        isEventFormLinked: action.payload,
      };
    case GET_LINKED_WEBSITES:
      return {
        ...state,
        linkedWebsites: action.payload,
      };
    case LOAD_EMAIL_TAGS_LIST:
      return {
        ...state,
        tagsListData: action.payload,
      };
    case RESET_EMAIL_EDIT_LOADED_STATE:
      return {
        ...state,
        emailEditDataLoaded: false,
      };
    case SET_MESSAGE_DETAILS:
      return {
        ...state,
        teamsIntegrationDetails: {
          ...state.teamsIntegrationDetails,
          messageDetails: action.payload,
        },
      };
    case SET_CHANNEL_DETAILS:
      return {
        ...state,
        teamsIntegrationDetails: {
          ...state.teamsIntegrationDetails,
          channelDetails: action.payload,
        },
      };
    case LOAD_EMAIL_TAGS_LIST_FAILURE:
    default:
      return state;
  }
}

export function loadSurveyData(payload, stepId, prevNodeEndDate) {
  const temp = payload;
  let res = {
    stepId: stepId,
    general: {},
    cta: [],
    reminders: [],
    files: [],
    additionalUrls: [],
    internalAudianceSender: [],
    tags: [],
    canvasCustomTag: [],
    isSaved: 1,
    isReminderEdited: 0,
    validateErrors: {},
    default_values: {},
    campaignDetails: [],
    prevNodeEndDate: '',
    createdBy: '',
    missingFieldErrors: [],
    switchEmailId: null,
  };

  if (temp.mail) {
    res.general = temp.mail;
  }
  if (temp.cta) {
    res.cta = temp.cta;
  }
  if (temp.mailReminder) {
    if (temp.mailReminder?.length > 1) {
      res.reminders = temp.mailReminder;
    } else if (temp.mailReminder?.length) {
      const [reminder] = temp.mailReminder;
      if (reminder?.reminderIndex || reminder?.subject || reminder?.whenDelay) {
        res.reminders = temp.mailReminder;
      }
    }
  }
  if (temp.managedFiles) {
    res.files = temp.managedFiles;
  }
  if (temp.additionalUrls) {
    res.additionalUrls = temp.additionalUrls;
  }
  if (temp.internalAudianceSender) {
    res.internalAudianceSender = temp.internalAudianceSender;
  }
  if (temp.tags) {
    res.tags = temp.tags;
  }
  if (temp.canvasCustomTag) {
    res.canvasCustomTag = temp.canvasCustomTag;
  }
  if (prevNodeEndDate?.endDate) {
    res.prevNodeEndDate = prevNodeEndDate.endDate;
  }
  if (temp.createdBy) {
    res.createdBy = temp.createdBy.id;
  }
  if (temp.mail && temp.mail.switchEmailId) {
    res.switchEmailId = temp.mail.switchEmailId;
  }

  return res;
}
