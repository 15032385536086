import { CircularProgress, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import App from './App';
import { checkOauth, checkLocalStorageVersion } from './auth/authActions';

export const AuthenticatedApp = () => {
  const dispatch = useDispatch();
  const [checkingOauth, setCheckingOauth] = useState(false);
  const [localStorageVersionChecked, setLocalStorageVersionChecked] = useState(false);

  const isAuthenticated = useSelector(
    state =>
      state.authentication.isAuthenticated &&
      state.authentication.accessToken &&
      state.authentication.profile,
  );

  const { error } = useSelector(state => state.authError);

  const shouldRenderApp = isAuthenticated && localStorageVersionChecked;

  useEffect(() => {
    dispatch(checkLocalStorageVersion());

    setLocalStorageVersionChecked(true);
  }, []);

  useEffect(() => {
    if (!localStorageVersionChecked) return;

    if (!isAuthenticated && !checkingOauth) {
      setCheckingOauth(true);
      dispatch(checkOauth());

      return;
    }

    setCheckingOauth(false);
  }, [dispatch, isAuthenticated, localStorageVersionChecked]);

  if (shouldRenderApp) {
    return <App></App>;
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item>
        {
          error ? (
            <p>You are not yet authenticated to use Orchestra.</p>
          ) : (
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <CircularProgress />
              </Grid>
              <Grid item>
                <p>Signing in</p>
              </Grid>
            </Grid>
          )
        }
      </Grid>
    </Grid>
  );
};
