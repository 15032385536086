import React, { useMemo } from 'react';
import { Avatar } from '@mui/material';
export const UserAvatar = ({ user }) => {
  const userName = user.name;
  const fallback = useMemo(
    () =>
      userName
        .split(' ')
        .map((el) => el.trim())
        .map((el) => el[0])
        .reduce((prev, el) => (prev.length < 2 ? `${prev}${el}` : prev), ''),
    [userName],
  );
  return (
    <Avatar
      alt={user.name}
      src={
        user.wwid
          ? `https://static.janssen-emea.com/sites/default/files/France/uploads/1to1/Photos/${user.wwid}.jpg`
          : undefined
      }
    >
      {fallback}
    </Avatar>
  );
};
