import { useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import ContactSupportForm from './ContactSupportForm';

import './ContactSupport.scss';

export enum SupportOptions {
    DEFAULT = '',
    BUG = 'Bug',
    IMPROVEMENT = 'Improvement',
    EMAIL_TEMPLATES = 'Question on Email Templates'
}

interface ContactSupportProps {
    handleClose: () => void
}

const ContactSupport = ({ handleClose }: ContactSupportProps) => {
    const [supportOption, setSupportOption] = useState<SupportOptions>(SupportOptions.DEFAULT);

    const renderForm = () => {
        switch (supportOption) {
            case SupportOptions.BUG:
            case SupportOptions.IMPROVEMENT:
            case SupportOptions.EMAIL_TEMPLATES:
                return <ContactSupportForm topic={supportOption} handleClose={handleClose} />;
            default:
                return null;
        }
    }

    return (
        <Box className='contact-support-form'>
            <h1 className='contact-support-title'>Contact Support</h1>

            <FormControl fullWidth size='small'>
                <InputLabel id='support-option-label'>Topic</InputLabel>
                <Select
                    labelId='support-option-label'
                    label='Topic'
                    value={supportOption}
                    onChange={(event) => setSupportOption(event.target.value as SupportOptions)}
                    fullWidth
                    size='small'
                >
                    <MenuItem value={SupportOptions.BUG}>Bug</MenuItem>
                    <MenuItem value={SupportOptions.IMPROVEMENT}>Improvement</MenuItem>
                    <MenuItem value={SupportOptions.EMAIL_TEMPLATES}>Question on Email Templates</MenuItem>
                </Select>
            </FormControl>

            { renderForm() }
        </Box>
    )
}

export default ContactSupport;
