export const UPDATE_CALL_DRAFT = 'update_call_draft';
export const SAVE_CALL = 'save_call';
export const NAVIGATE_STEP_CALLS = 'navigate_step_calls';
export const CREATE_CALL_SUCCESS = 'create_call_success';
export const CREATE_CALL_FAILURE = 'create_call_failure';
export const LOAD_CALL = 'load_call';
export const LOAD_CALL_FAILURE = 'load_call_failure';
export const LOAD_CALL_SUCCESS = 'load_call_success';
export const SEND_FOR_REVIEW_SUCCESS = 'send_for_review_success';
export const SEND_FOR_REVIEW_FAILURE = 'send_for_review_failure';
export const LOAD_CANVAS_SUCCESS = 'store/calls/load_canvas_success';
export const LOAD_CANVAS_FAILURE = 'store/calls/load_canvas_failure';
export const RESET_CALLS = 'reset_calls';
export const LOAD_CALLS_TAGS_LIST = 'load_calls_tags_list';
export const LOAD_CALLS_TAGS_LIST_FAILURE = 'load_calls_tags_list_failure';
export const LOAD_PARENT_CALLS_SUCCESS = 'load_parent_calls_success';
export const LOAD_PARENT_CALLS_FAILED = 'load_parent_calls_failed';
export const LOAD_KEY_MESSAGES_SUCCESS = 'load_key_messages_success';
export const LOAD_KEY_MESSAGES_FAILED = 'load_key_messages_failed';