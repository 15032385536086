import {
  GET_TECHNICALBRIEF_MAIL1TO1,
  GET_TECHNICALBRIEF_SMS,
  GET_TECHNICALBRIEF_SURVEY,
  GET_TECHNICALBRIEF_FRAGMENT,
  GET_TECHNICALBRIEF_WEBSITE,
  GET_TECHNICALBRIEF_AUTOMATED_EMAIL,
  GET_FAILED,
  GET_EMAIL_TYPES_AND_SUBTYPES,
  GET_TECHNOLOGY_LIST,
  GET_EVENT_NUMBER_DETAILS_LIST,
  SET_EVENT_NUMBER_DETAILS_LIST,
  NOTIFICATION_CREATION_SUCCESS,
  NOTIFICATION_CREATION_FAILURE,
  LOAD_TAGS_LIST,
  LOAD_TAGS_LIST_FAILURE,
  RESET_STATE,
  GET_SAVE_EVENT_SPEAKER_TB,
  SET_SAVE_EVENT_SPEAKER_TB,
  CTA_URL_LIST,
  GET_AUTOMATED_MASS_MAIL_TEMPLATE,
  LOAD_EMAIL_TEMPLATES_SUCCESS,
  GET_TB_EMAIL_AND_MASS_MAIL_TEMPLATE,
  SET_TB_EMAIL_AND_MASS_MAIL_TEMPLATE,
  SET_MESSAGE_DETAILS,
  SET_CHANNEL_DETAILS,
  GET_SWITCH_EMAIL,
  GET_SWITCH_EMAIL_FAILURE
} from './types';
import { API_STATUS_CONSTANTS } from '../../app/constants/form-constants';

const initialState = {
  id: '',
  mail: {},
  automated: {},
  sms: {},
  survey: {},
  tags: [],
  website: {},
  canvasCustomTag: [],
  managedFiles: [],
  cta: [],
  mailReminder: {},
  mailReminders: [],
  fragment: {},
  canvasData: '',
  loading: true,
  emailTypes: [],
  emailSubtypes: [],
  technologyLists: [],
  ctaUrlListData: [],
  automatedMassMailTemplate: [],
  eventDetails: {
    data: {},
    status: API_STATUS_CONSTANTS.init,
  },
  emailTemplateData: [],
  emailAndMassMailTemplates: {
    data: [],
    status: API_STATUS_CONSTANTS.init,
  },
  createdBy: {},
  stepDisplayId: '',
  tagsListData: [],
  internalAudianceSender: [],
  additionalUrls: [],
  checklistProgress: [],
  assignedTo: {},
  status: {},
  libraryGeneratedId: '',
  createdAt: '',
  endDate: '',
  startDate: '',
  eventSpeakerSaveStatus: API_STATUS_CONSTANTS.init,
  teamsIntegrationDetails: {},
  type: {},
  notificationSent: false
};

export function technicalBriefFormData(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_TECHNICALBRIEF_MAIL1TO1:
      return {
        ...state,
        id: payload.id,
        status: payload.status,
        mail: payload.mail,
        tags: payload.tags,
        canvasCustomTag: payload.canvasCustomTag,
        managedFiles: payload.managedFiles,
        cta: payload.cta,
        mailReminders: payload.mailReminder,
        canvasData: payload.canvasData,
        createdBy: payload.createdBy,
        stepDisplayId: payload.stepDisplayId,
        loading: false,
        startDate: payload.startDate,
        endDate: payload.endDate,
        createdAt: payload.createdAt,
        libraryGeneratedId: payload.libraryGeneratedId,
        additionalUrls: payload.additionalUrls,
        internalAudianceSender: payload.internalAudianceSender,
        checklistProgress: payload.checklistProgress,
        teamsIntegrationDetails: payload.teamsIntegrationDetails,
        type: payload.type,
        notificationSent: payload.notificationSent
      };
    case GET_TECHNICALBRIEF_AUTOMATED_EMAIL:
      return {
        ...state,
        id: payload.id,
        status: payload.status,
        automated: payload.automated,
        tags: payload.tags,
        canvasCustomTag: payload.canvasCustomTag,
        managedFiles: payload.managedFiles,
        cta: payload.cta,
        internalAudianceSender: payload.internalAudianceSender,
        automatedExternalAudiance: payload.automatedExternalAudiance,
        mailReminder: payload.mailReminder,
        canvasData: payload.canvasData,
        createdBy: payload.createdBy,
        stepDisplayId: payload.stepDisplayId,
        createdAt: payload.createdAt,
        additionalUrls: payload.additionalUrls,
        libraryGeneratedId: payload.libraryGeneratedId,
        checklistProgress: payload.checklistProgress,
        loading: false,
        teamsIntegrationDetails: payload.teamsIntegrationDetails,
        type: payload.type,
        notificationSent: payload.notificationSent
      };
    case GET_TECHNICALBRIEF_SMS:
      return {
        ...state,
        id: payload.id,
        status: payload.status,
        sms: payload.sms,
        tags: payload.tags,
        canvasCustomTag: payload.canvasCustomTag,
        managedFiles: payload.managedFiles,
        canvasData: payload.canvasData,
        createdBy: payload.createdBy,
        stepDisplayId: payload.stepDisplayId,
        checklistProgress: payload.checklistProgress,
        loading: false,
      };
    case GET_TECHNICALBRIEF_SURVEY:
      return {
        ...state,
        id: payload.id,
        status: payload.status,
        survey: payload.survey,
        tags: payload.tags,
        canvasCustomTag: payload.canvasCustomTag,
        cta: payload.cta,
        managedFiles: payload.managedFiles,
        canvasData: payload.canvasData,
        createdBy: payload.createdBy,
        stepDisplayId: payload.stepDisplayId,
        checklistProgress: payload.checklistProgress,
        loading: false,
      };
    case GET_TECHNICALBRIEF_FRAGMENT:
      return {
        ...state,
        id: payload.id,
        status: payload.status,
        fragment: {
          ...state.fragment,
          ...payload.fragment
        },
        tags: payload.tags,
        canvasCustomTag: payload.canvasCustomTag,
        cta: payload.cta,
        managedFiles: payload.managedFiles,
        canvasData: payload.canvasData,
        createdBy: payload.createdBy,
        stepDisplayId: payload.stepDisplayId,
        checklistProgress: payload.checklistProgress,
        loading: false,
        teamsIntegrationDetails: payload.teamsIntegrationDetails,
        type: payload.type,
        notificationSent: payload.notificationSent
      };
    case GET_TECHNICALBRIEF_WEBSITE:
      return {
        ...state,
        id: payload.id,
        status: payload.status,
        website: payload.website,
        tags: payload.tags,
        canvasCustomTag: payload.canvasCustomTag,
        managedFiles: payload.managedFiles,
        canvasData: payload.canvasData,
        createdBy: payload.createdBy,
        stepDisplayId: payload.stepDisplayId,
        checklistProgress: payload.checklistProgress,
        loading: false,
        teamsIntegrationDetails: payload.teamsIntegrationDetails
      };
    case GET_EMAIL_TYPES_AND_SUBTYPES:
      return {
        ...state,
        emailTypes: payload.type,
        emailSubtypes: payload.subtype,
      };
    case GET_TECHNOLOGY_LIST:
      return {
        ...state,
        technologyLists: payload.technologyList,
      };
    case CTA_URL_LIST:
      return {
        ...state,
        ctaUrlListData: payload.ctaUrlList,
      };
    case GET_AUTOMATED_MASS_MAIL_TEMPLATE:
      return {
        ...state,
        automatedMassMailTemplate: payload.massMailTemplate,
      };
    case GET_EVENT_NUMBER_DETAILS_LIST:
      return {
        ...state,
        eventDetails: {
          data: {},
          status: API_STATUS_CONSTANTS.loading,
        },
      };
    case SET_EVENT_NUMBER_DETAILS_LIST:
      return {
        ...state,
        eventDetails: {
          data: action.payload.data,
          status: action.payload.status,
        },
      };
    case LOAD_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        emailTemplateData: payload.types,
      };
    case GET_TB_EMAIL_AND_MASS_MAIL_TEMPLATE:
      return {
        ...state,
        emailAndMassMailTemplates: {
          data: [],
          status: API_STATUS_CONSTANTS.loading,
        },
      };
    case SET_TB_EMAIL_AND_MASS_MAIL_TEMPLATE: // handle error
      return {
        ...state,
        emailAndMassMailTemplates: {
          data: action.payload.data,
          status: action.payload.status,
        },
      };
    case GET_FAILED:
      return {
        ...state,
      };
    case RESET_STATE:
      return { ...initialState };
    case LOAD_TAGS_LIST:
      return {
        ...state,
        tagsListData: payload,
      };
    case GET_SAVE_EVENT_SPEAKER_TB:
      return {
        ...state,
        eventSpeakerSaveStatus: API_STATUS_CONSTANTS.loading,
      };
    case SET_SAVE_EVENT_SPEAKER_TB:
      if (payload.status === API_STATUS_CONSTANTS.success) {
        const newSpeakerList = getUpdatedEventSpeakerList(state, action);
        return {
          ...state,
          website: {
            ...state.website,
            eventSpeakers: [...newSpeakerList],
          },
          eventSpeakerSaveStatus: payload.status,
        };
      } else {
        return {
          ...state,
          eventSpeakerSaveStatus: payload.status,
        };
      }
    case SET_MESSAGE_DETAILS:
      return {
        ...state,
        teamsIntegrationDetails: {
          ...state.teamsIntegrationDetails,
          messageDetails: action.payload
        }
      }
    case SET_CHANNEL_DETAILS:
      return {
        ...state,
        teamsIntegrationDetails: {
          ...state.teamsIntegrationDetails,
          channelDetails: action.payload
        }
      }
    case GET_SWITCH_EMAIL: 
      return {
        ...state,
        fragment: {
          ...state.fragment,
          switchEmail: action.payload
        }
      }
    case GET_SWITCH_EMAIL_FAILURE:
    case LOAD_TAGS_LIST_FAILURE:
    case NOTIFICATION_CREATION_SUCCESS:
    case NOTIFICATION_CREATION_FAILURE:
    default:
      return state;
  }
}

export function getUpdatedEventSpeakerList(state, action) {
  let newSpeakerList = [];
  const speakerData = state.website?.eventSpeakers?.map((ele) => {
    return { ...ele };
  });
  const newSpeakerData = action.payload.data;
  const index = speakerData?.findIndex((ele) => ele.id === newSpeakerData?.id);
  if (index !== -1) {
    speakerData[index] = { ...newSpeakerData };
    newSpeakerList = [...speakerData];
  } else {
    newSpeakerList = [newSpeakerData, ...speakerData];
  }
  return newSpeakerList;
}
