import { ENQUEUE_NOTIFICATION, REMOVE_NOTIFICATION } from './types';
export const enqueueNotification = (message, variant = 'error', duration = 2000) => {
  const notification = {
    key: `${new Date().getTime()}${Math.random()}`,
    message,
    dismissed: false,
    options: { variant, autoHideDuration: duration },
  };
  return { type: ENQUEUE_NOTIFICATION, payload: notification };
};
export const removeNotification = (notificationKey) => {
  return { type: REMOVE_NOTIFICATION, payload: notificationKey };
};
