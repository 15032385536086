import { camelToUnderscoreObj } from '../utils';

const internalAudianceSenderMapping = data =>
  data.map(el => ({
    sender: {
      id: el.id,
      name: el.name,
    },
  }));

export const mailTemplatesMapping = data =>
  data.map(el => ({
    node_id: el.ID,
    description: el.DESCRIPTION,
    imr_crp_url: el.IMR_CRP_URL,
    imr_number: el.IMR_NUMBER,
    imr_url: el.IMR_URL,
    name: el.NAME,
    s3_url: el.URL,
    subject: el.SUBJECT,
    subtype: el.EMAIL_SUBTYPE_ID,
    type: el.EMAIL_TYPE_ID,
    event_number: el.EVENT_MUMBER,
    event_related: Number(el.EVENT_RELATED),
    is_event_type: el.IS_EVENT_TYPE === 'Y' ? 1 : 0,
  }));

export const tagsMapping = tags => tags.filter(tag => !!tag.tag).map(tag => tag.tag);
export const tagsSaveMapping = tags => tags.map(tag => ({ tag: { id: tag.id } }));
export const customTagsMapping = tags =>
  tags
    .filter(tag => !tag.tag)
    .map(tag => ({
      id: tag.id,
      text: tag.name,
      tagCategory: tag.tagCategory,
    }));
export const customTagsSaveMapping = tags =>
  tags.map(tag => ({
    name: tag.text,
    tagCategory: { id: tag.tagCategory.id },
  }));

const tbStepMapping = (data, stepName) => ({
  id: data.id,
  canvasData: data.nodes ? JSON.stringify(data.nodes[0].canvasData) : null,
  createdAt: data.createdDate,
  stepDisplayId: data.displayId,
  libraryGeneratedId: data.nodes ? data.nodes[0].canvasData.id : null,
  tags: tagsMapping(data.tags),
  canvasCustomTag: customTagsMapping(data.tags),
  managedFiles: data.attachments,
  status: data.status,
  createdBy: data.createdBy,
  [stepName]: {
    startDate: data.startDate,
    endDate: data.endDate,
    assignedTo: data.assignedTo,
    requestOwner: data.requestOwner,
  },
  checklistProgress: data.checklistProgress,
  notificationSent: data.notificationSent
});

export const urlsMapping = (urls, isSeo) =>
  urls
    .filter(url => (isSeo ? url.type.id === 4 : url.type.id !== 4))
    .map(url => ({
      description: url.description,
      id: url.id,
      pageTitle: url.title,
      token: url.token,
      url: url.url,
      urlType: url.type.id,
    }));

export const urlsReverseMapping = urls =>
  urls.map(url => ({
    description: url.description,
    id: url.id,
    title: url.pageTitle,
    url: url.url,
    type: {
      id: url.urlType,
    },
    token: url.token,
  }));

export const tbSmsMapping = data => {
  const mappedObject = tbStepMapping(data, 'sms');

  return {
    ...mappedObject,
    sms: {
      ...mappedObject.sms,
      checklist: data.payload?.checklist,
      id: null,
      message: data.name,
      sendTime: data.payload?.send_time,
      eloquaCampaignId: data.payload?.eloqua_campaign_id,
    },
  };
};

export const tbPostMailMapping = data => {
  const mappedObject = tbStepMapping(data, 'postMail');

  return {
    ...mappedObject,
    postMail: data.payload?.post_mail,
  };
};

export const tbSurveyMapping = data => {
  const mappedObject = tbStepMapping(data, 'survey');

  return {
    ...mappedObject,
    survey: {
      ...mappedObject.survey,
      checklist: data.payload?.checklist,
      id: null,
      resultInIConnect: !!data.payload?.result_in_i_connect,
      surveyName: data.payload?.survey_name,
      surveyObjective: data.payload?.survey_objective,
      csatSurvey: data.payload?.csat_survey,
      webSurvey: data.payload?.web_survey,
    },
  };
};

export const ctaMapping = ctas => 
  ctas.map(cta => ({
    chatterMessage: cta.payload?.chatter_message,
    chatterNotification: !!cta.payload?.chatter_notification,
    ctaCustomLabel: cta.label,
    ctaDisplayId: cta.displayId,
    ctaTemplate: cta.template,
    ctaAnchorUrl: cta.payload?.ctaAnchorUrl,
    ctaUrl: cta.url,
    defaultMessage: cta.payload?.default_message,
    emailNotification: !!cta.payload?.email_notification,
    accessWithoutLogin: !!cta.payload?.access_without_login,
    webpageOnlyOrEntireWebsite: cta.payload?.webpage_only_or_entire_website,
    followUpActivity: cta.payload?.follow_up_activity,
    id: cta.id,
    isDefaultCTA: !!cta.payload?.is_default_cta,
    isDefaultMessage: !!cta.payload?.is_default_message,
    isEventCta: !!cta.payload?.is_event_cta,
    isPredefinedCtaLabel: !!cta.payload?.is_predefined_cta_label,
    websiteUrl: cta.websiteUrl,
    isSwitchCTA: cta.isSwitch || false,
  }));


export const ctaReverseMapping = ctas =>
  ctas.map(cta => ({
    payload: {
      chatter_message: cta.chatterMessage,
      chatter_notification: cta.chatterNotification,
      default_message: cta.defaultMessage,
      email_notification: cta.emailNotification,
      access_without_login: cta.accessWithoutLogin,
      webpage_only_or_entire_website: cta.webpageOnlyOrEntireWebsite,
      follow_up_activity: cta.followUpActivity,
      is_default_cta: cta.isDefaultCTA,
      is_default_message: cta.isDefaultMessage,
      is_event_cta: cta.isEventCta,
      is_predefined_cta_label: cta.isPredefinedCtaLabel,
      ctaAnchorUrl: cta.ctaAnchorUrl,
    },
    label: cta.ctaCustomLabel,
    displayId: cta.ctaDisplayId,
    template: cta.ctaTemplate,
    url: cta.ctaUrl,
    id: cta.id,
  }));

export const reminderMapping = reminders =>
  reminders.map(reminder => ({
    clicked: !!reminder.payload?.clicked,
    id: reminder.id,
    displayId: reminder.displayId,
    opened: !!reminder.payload?.opened,
    preHeader: reminder.payload?.pre_header,
    reminderIndex: reminder.payload?.reminder_index,
    reminderSendDate: reminder.payload?.reminder_send_date,
    reminderSendTime: reminder.payload?.reminder_send_time,
    subject: reminder.name,
    subjectType: !!reminder.payload?.subject_type,
    whenDelay: reminder.payload?.when_delay,
  })).sort((a, b) => a.reminderIndex - b.reminderIndex);

export const duplicateDataToReminders = (data, reminders) =>
  reminders.map(reminder => ({
    ...reminder,
    startDate: data.startDate,
    endDate: data.endDate,
  }));

export const reminderReverseMapping = reminders =>
  reminders.map(reminder => ({
    id: reminder.id,
    name: reminder.subject,
    startDate: reminder.startDate,
    endDate: reminder.endDate,
    assignedTo: reminder.assignedTo,
    payload: {
      reminder_send_date: reminder.reminderSendDate,
      reminder_send_time: reminder.reminderSendTime,
      subject_type: reminder.subjectType,
      pre_header: reminder.preHeader,
      reminder_index: reminder.reminderIndex,
      subject: reminder.subject,
    },
  }));

export const teamReverseMapping = teams =>
  teams
    .filter(team => !!team && team.id !== 'default')
    .map(team => ({
      id: team.id,
    }));

export const tbFragmentMapping = data => {
  const mappedObject = tbStepMapping(data, 'fragment');

  return {
    ...mappedObject,
    cta: ctaMapping(data.cta),
    fragment: {
      ...mappedObject.fragment,
      createdBy: data.createdBy,
      creator: data.creator,
      submitter: data.submitter,
      checklist: data.payload?.checklist,
      comments: data.payload?.comments,
      eventNumber: data.payload?.event_number,
      eventRelated: !!data.payload?.event_related,
      eventStartDate: data.payload?.event_start_date,
      id: null,
      imrUrl: data.payload?.imr_url,
      emailTemplate: {
        id: data.step?.id,
        name: data.step?.name,
        imrNumber: data.step?.payload?.imr_number,
        displayId: data.step?.displayId
      },
      title: data.name,
      switchEmailId: data.payload?.switch_email_id,
      additionalContacts: data.payload?.additional_contacts
    },
    teamsIntegrationDetails: {
      teamDetails: data.step.nodes[0].journey.product[0].therapeutic.therapeuticCountry
        .filter(tc => data.step.nodes[0].journey.countries.map(c => c.id).includes(tc.country.id) && tc.teamsTeam !== null) 
        .map(tc => tc.teamsTeam),
      channelDetails: data.step.nodes[0].journey.teamsChannel,
      messageDetails: data.teamsMessage,
      campaignDetails: {
        id: data.step.nodes[0].journey.id,
        name: data.step.nodes[0].journey.name
      }
    },
    type: data.step.type
  };
};

export const tbMail1to1Mapping = data => {
  const mappedObject = tbStepMapping(data, 'mail');

  return {
    ...mappedObject,
    mail: {
      ...mappedObject.mail,
      crmOrg: data.payload?.crm_org,
      allTheapeuticArea: !!data.payload?.all_theapeutic_area,
      checklist: data.payload?.checklist,
      comment: data.payload?.comment,
      country: data.payload?.country,
      designTeam: data.teams.find(team => team.type.id === 3),
      distributionComments: data.payload?.distribution_comments,
      description: data.payload?.description,
      imrNumber: data.payload?.imr_number,
      imrDate: data.payload?.imr_date,
      haNumber: data.payload?.ha_number,
      subtype: data.emailSubtype,
      type: data.emailType,
      name: data.name,
      emailWithFragment: !!data.payload?.email_with_fragment,
      estimatedDate: data.payload?.estimated_date,
      eventMode: data.payload?.event_mode,
      eventNumber: data.payload?.event_number,
      eventStartDate: data.payload?.event_start_date,
      eventType: !!data.payload?.event_type,
      externalAgency: !!data.payload?.external_agency,
      fromEmail: data.payload?.from_email,
      fromName: data.payload?.from_name,
      id: null,
      imrCrpUrl: data.payload?.imr_crp_url,
      imrUrl: data.payload?.imr_url,
      integrationTeam: data.teams.find(team => team.type.id === 1),
      isNewRequest: true, // Keeping the value for compatibility but default to true since AFIC-2548
      isNewTemplate: true, // Keeping the value for compatibility but default to true since AFIC-2548
      language: data.payload?.language,
      meetingId: data.payload?.meeting_id,
      meetingSecretCode: data.payload?.meeting_secret_code,
      phoneNumber: data.payload?.phone_number,
      phoneSecretCode: data.payload?.phone_secret_code,
      preHeader: data.payload?.pre_header,
      releaseDate: data.payload?.release_date,
      specificInstruction: data.payload?.specific_instruction,
      additionalInformation: data.payload?.additional_information,
      subject: data.payload?.subject,
      additionalSubjects: data.payload?.additional_subjects,
      technology: data.payload?.technology,
      templateCanvasEmailNode: data.payload?.template_canvas_email_node,
      switchEmailId: data.payload?.switch_email_id,
      isSwitchTemplate: !!data.payload?.is_switch_template,
      additionalContacts: data.payload?.additional_contacts
    },
    additionalUrls: urlsMapping(data.urls),
    internalAudianceSender: internalAudianceSenderMapping(data.internalAudience),
    mailReminder: reminderMapping(data.reminders),
    cta: ctaMapping(data.cta),
    teamsIntegrationDetails: {
      teamDetails: data.nodes[0].journey.product[0].therapeutic.therapeuticCountry
        .filter(tc => data.nodes[0].journey.countries.map(c => c.id).includes(tc.country.id) && tc.teamsTeam !== null) 
        .map(tc => tc.teamsTeam),
      channelDetails: data.nodes[0].journey.teamsChannel,
      messageDetails: data.teamsMessage,
      campaignDetails: {
        id: data.nodes[0].journey.id,
        name: data.nodes[0].journey.name
      }
    },
    type: data.type,
    switchEmail: data.switchEmail
  };
};

export const tbWebsiteMapping = data => {
  const mappedObject = tbStepMapping(data, 'website');

  return {
    ...mappedObject,
    website: {
      ...mappedObject.website,
      additionalInformation: data.payload?.additional_information,
      allowToken: data.payload?.allow_token,
      checklist: data.payload?.checklist,
      comments: data.payload?.comments,
      description: data.payload?.description,
      eloquaForm: data.payload?.eloqua_form,
      eventName: data.payload?.event_name,
      eventNumber: data.payload?.event_number,
      eventSpeakers: data.speakers,
      existingProjectId: data.payload?.existing_project_id,
      formIncluded: !!data.payload?.form_included,
      id: null,
      imrReferenceNumber: data.payload?.imr_reference_number,
      isExisting: data.payload?.is_existing === undefined ? true : data.payload?.is_existing,
      jmcSeo: urlsMapping(data.urls, true),
      jmcUrls: urlsMapping(data.urls),
      productionUrl: data.payload?.production_url,
      projectName: data.name,
      projectType: data.projectType,
      relatedContent: data.payload?.related_content,
      reviewUrl: data.payload?.review_url,
      scope: data.payload?.scope,
      websiteType: data.websiteType,
      estimatedDate: data.payload?.estimated_date,
      releaseDate: data.payload?.release_date,
      specificInstruction: data.payload?.specific_instruction,
      designTeam: data.teams?.find(team => team.type.id === 3),
      productionTeam: data.teams?.find(team => team.type.id === 1),
    },
    teamsIntegrationDetails: {
      teamDetails: data.nodes[0].journey.product[0].therapeutic.therapeuticCountry
        .filter(tc => data.nodes[0].journey.countries.map(c => c.id).includes(tc.country.id) && tc.teamsTeam !== null) 
        .map(tc => tc.teamsTeam),
      channelDetails: data.nodes[0].journey.teamsChannel,
      messageDetails: data.teamsMessage,
      campaignDetails: {
        id: data.nodes[0].journey.id,
        name: data.nodes[0].journey.name
      }
    }
  };
};

export const tbAutomatedMapping = data => {
  const mappedObject = tbStepMapping(data, 'automated');

  return {
    ...mappedObject,
    automated: {
      ...mappedObject.automated,
      crmOrg: data.payload?.cmr_org,
      allTheapeuticArea: !!data.payload?.all_theapeutic_area,
      checklist: data.payload?.checklist,
      country: data.payload?.country,
      description: data.payload?.description,
      designTeam: data.teams.find(team => team.type.id === 3),
      distributionComments: data.payload?.distribution_comments,
      eloquaCampaignId: data.payload?.eloqua_campaign_id,
      estimatedDate: data.payload?.estimated_date,
      eventMode: data.payload?.event_mode,
      eventNumber: data.payload?.event_number,
      eventRelated: !!data.payload?.event_related,
      eventStartDate: data.payload?.event_start_date,
      eventType: !!data.payload?.event_type,
      externalAgency: !!data.payload?.external_agency,
      fromEmail: data.payload?.from_email,
      fromName: data.payload?.from_email,
      id: null,
      imrCrpUrl: data.payload?.imr_crp_url,
      imrNumber: data.payload?.imr_number,
      imrDate: data.payload?.imr_date,
      haNumber: data.payload?.ha_number,
      imrUrl: data.payload?.imr_url,
      integrationTeam: data.teams.find(team => team.type.id === 1),
      isDefaultSetting: data.payload ? !!data.payload.is_default_setting : true,
      isNewTemplate: true, // Keeping the value for compatibility but default to true since AFIC-2548
      language: data.payload?.language,
      meetingId: data.payload?.meeting_id,
      meetingSecretCode: data.payload?.meeting_secret_code,
      name: data.name,
      phoneNumber: data.payload?.phone_number,
      phoneSecretCode: data.payload?.phone_secret_code,
      preHeader: data.payload?.pre_header,
      releaseDate: data.payload?.release_date,
      replyToDetails: data.replyTo,
      senderDetails: data.sender,
      specificInstruction: data.payload?.specific_instruction,
      additionalInformation: data.payload?.additional_information,
      subject: data.payload?.subject,
      additionalSubjects: data.payload?.additional_subjects,
      subtype: data.emailSubtype,
      technology: data.payload?.technology,
      templateCanvasEmailNode: data.payload?.template_canvas_email_node,
      type: data.emailType,
      sendTime: data.payload?.send_time,
      switchEmailId: data.payload?.switch_email_id,
      isSwitchTemplate: !!data.payload?.is_switch_template,
      additionalContacts: data.payload?.additional_contacts
    },
    additionalUrls: urlsMapping(data.urls),
    internalAudianceSender: internalAudianceSenderMapping(data.internalAudience),
    mailReminder: reminderMapping(data.reminders),
    automatedExternalAudiance: {
      firstReminderEmailIntractionType: data.payload?.first_reminder_email_intraction_type,
      id: null,
      initialEmailIntractionTypeRem1: data.payload?.initial_email_intraction_type_rem1,
      initialEmailIntractionTypeRem2: data.payload?.initial_email_intraction_type_rem2,
      isBasedInitialEmailActivityRem1: !!data.payload?.is_based_initial_email_activity_rem1,
      isBasedInitialEmailActivityRem2: !!data.payload?.is_based_initial_email_activity_rem2,
      isBasedOnEventAttendees: !!data.payload?.is_based_on_event_attendees,
      isBasedOnEventAttendeesRem1: !!data.payload?.is_based_on_event_attendees_rem1,
      isBasedOnEventAttendeesRem2: !!data.payload?.is_based_on_event_attendees_rem2,
      isBasedOnFirstReminder: !!data.payload?.is_based_on_first_reminder,
      isBasedOnJmcAccount: !!data.payload?.is_based_on_jmc_account,
      isBasedOtherEmailActivity: !!data.payload?.is_based_other_email_activity,
      isBasedOtherEmailActivityRem1: !!data.payload?.is_based_other_email_activity_rem1,
      isBasedOtherEmailActivityRem2: !!data.payload?.is_based_other_email_activity_rem2,
      isSpecificProductTargetClass: !!data.payload?.is_specific_product_target_class,
      isSpecificSpeciality: !!data.payload?.is_specific_speciality,
      isUseBestTime: !!data.payload?.is_use_best_time,
      isUseBestTimeRem1: !!data.payload?.is_use_best_time_rem1,
      isUseBestTimeRem2: !!data.payload?.is_use_best_time_rem2,
      isWithJmcAccount: !!data.payload?.is_with_jmc_account,
      otherCriteria: data.payload?.other_criteria,
      otherCriteriaRem1: data.payload?.other_criteria_rem1,
      otherCriteriaRem2: data.payload?.other_criteria_rem2,
      otherEmailIntractionType: data.payload?.other_email_intraction_type,
      otherEmailIntractionTypeRem1: data.payload?.other_email_intraction_type_rem1,
      otherEmailIntractionTypeRem2: data.payload?.other_email_intraction_type_rem2,
      otherEmailNode: data.payload?.other_email_node,
      otherEmailNodeRem1: data.payload?.other_email_node_rem1,
      otherEmailNodeRem2: data.payload?.other_email_node_rem2,
      speciality: data.payload?.speciality,
      targetClass: data.payload?.target_class,
    },
    cta: ctaMapping(data.cta),
    teamsIntegrationDetails: {
      teamDetails: data.nodes[0].journey.product[0].therapeutic.therapeuticCountry
        .filter(tc => data.nodes[0].journey.countries.map(c => c.id).includes(tc.country.id) && tc.teamsTeam !== null) 
        .map(tc => tc.teamsTeam),
      channelDetails: data.nodes[0].journey.teamsChannel,
      messageDetails: data.teamsMessage,
      campaignDetails: {
        id: data.nodes[0].journey.id,
        name: data.nodes[0].journey.name
      }
    },
    type: data.type,
    switchEmail: data.switchEmail
  };
};

export const eventMapping = data => {
  const mappedObject = tbStepMapping(data, 'event');

  return {
    ...mappedObject,
    event: {
      ...mappedObject.event,
      attendees: data.payload?.attendees,
      meetingStyle: data.payload?.meeting_style,
      name: data.name,
      number: data.payload?.number,
      objective: data.payload?.objective,
      isMultiEvent: data.payload?.is_multi_event,
    },
  };
};

export const callsMapping = data => {
  const mappedObject = tbStepMapping(data, 'calls');

  return {
    ...mappedObject,
    calls: {
      ...mappedObject.calls,
      objective: data.name,
      trackCustomerRequests: !!data.payload?.track_customer_requests,
      basedOnParentCalls: !!data.payload?.based_on_parent_calls,
      basedOnKeyMessages: !!data.payload?.based_on_key_messages,
    },
    parentCalls: data.payload?.parent_calls,
    keyMessages: data.payload?.key_messages
  };
};

export const saveTbFormDataMapping = (data, formtype) => {
  if (formtype === 'survey') {
    if (data[formtype]?.surveyName) {
      data.name = data[formtype].surveyName;
      delete data[formtype].surveyName;
    }
  }

  if (formtype === 'fragment') {
    if (data[formtype]?.title) {
      data.name = data[formtype].title;
      delete data[formtype].title;
    }

    delete data[formtype].emailTemplate;
  }

  if (formtype === 'website') {
    if (data[formtype]?.websiteType) {
      data.websiteType = { id: data[formtype].websiteType.id };
      delete data[formtype].websiteType;
    }

    if (data[formtype]?.projectType) {
      data.projectType = { id: data[formtype].projectType.id };
      delete data[formtype].projectType;
    }

    if (data[formtype]?.projectName) {
      data.name = data[formtype]?.projectName;
      delete data[formtype].projectName;
    }

    if (data[formtype]?.productionTeam || data[formtype]?.designTeam) {
      data.teams = teamReverseMapping([
        data[formtype]?.integrationTeam,
        data[formtype]?.designTeam,
      ]);
      delete data[formtype].productionTeam;
      delete data[formtype].designTeam;
    }

    if (data[formtype]?.jmcUrls || data[formtype]?.jmcSeo) {
      data.urls = urlsReverseMapping([
        ...(data[formtype].jmcUrls || []),
        ...(data[formtype]?.jmcSeo || []),
      ]);
      delete data[formtype].jmcUrls;
      delete data[formtype].jmcSeo;
    }

    if (data[formtype]?.requestOwner) {
      data.requestOwner = data[formtype].requestOwner;
      delete data[formtype].requestOwner;
    }
  }

  if (formtype === 'mail') {
    if (data[formtype]?.subtype) {
      data.emailSubtype = data[formtype].subtype;
      delete data[formtype].subtype;
    }

    if (data[formtype]?.type) {
      data.emailType = data[formtype].type;
      delete data[formtype].type;
    }

    if (data[formtype]?.name) {
      data.name = data[formtype].name;
      delete data[formtype].name;
    }

    if (data[formtype]?.integrationTeam || data[formtype]?.designTeam) {
      data.teams = teamReverseMapping([
        data[formtype]?.integrationTeam,
        data[formtype]?.designTeam,
      ]);
      delete data[formtype].integrationTeam;
      delete data[formtype].designTeam;
    }

    if (data[formtype]?.requestOwner) {
      data.requestOwner = data[formtype].requestOwner;
      delete data[formtype].requestOwner;
    }
  }

  if (formtype === 'automated') {
    if (data[formtype]?.subtype) {
      data.emailSubtype = data[formtype].subtype;
      delete data[formtype]?.subtype;
    }

    if (data[formtype]?.type) {
      data.emailType = data[formtype].type;
      delete data[formtype].type;
    }

    if (data[formtype]?.integrationTeam || data[formtype]?.designTeam) {
      data.teams = teamReverseMapping([
        data[formtype]?.integrationTeam,
        data[formtype]?.designTeam,
      ]);
      delete data[formtype].integrationTeam;
      delete data[formtype].designTeam;
    }

    if (data[formtype]?.replyToDetails) {
      data.replyTo = data[formtype].replyToDetails;
      delete data[formtype].replyToDetails;
    }

    if (data[formtype]?.senderDetails) {
      data.sender = data[formtype].senderDetails;
      delete data[formtype].senderDetails;
    }

    if (data[formtype]?.name) {
      data.name = data[formtype].name;
      delete data[formtype].name;
    }

    if (data[formtype]?.requestOwner) {
      data.requestOwner = data[formtype].requestOwner;
      delete data[formtype].requestOwner;
    }

    data.payload = {
      ...camelToUnderscoreObj(data[formtype]),
      ...(data.automatedExternalAudiance
        ? camelToUnderscoreObj(data.automatedExternalAudiance)
        : {}),
    };

    delete data.automatedExternalAudiance;
  } else {
    data.payload = camelToUnderscoreObj(data[formtype]);
  }

  if (data.managedFiles) {
    data.attachments = data.managedFiles.map(file => ({ ...file, type: 1 }));
    delete data.managedFiles;
  }

  if (data.cta) {
    data.cta = ctaReverseMapping(data.cta);
  }

  if (data.internalAudianceSender) {
    data.internalAudience = data.internalAudianceSender.map(el => el.sender);
    delete data.internalAudianceSender;
  }

  if (data.mailReminder) {
    data.reminders = reminderReverseMapping(data.mailReminder);
    delete data.mailReminder;
  }

  delete data[formtype];
  delete data.canvasCustomTag;
  delete data.tags;

  return data;
};

export const postMailsMapping = data =>
  data.map(element => ({
    name: element.NAME,
    startDate: element.START_DATE,
    endDate: element.END_DATE,
    status: element.STATUS,
    description: element.DESCRIPTION,
    numberOfTargets: element.NUMBER_OF_TARGETS,
  }));

export const customerRequestsMapping = data => {
  const mappedObject = tbStepMapping(data, 'customerRequests');

  return {
    ...mappedObject,
    parent: data.parent
  }
}