import axios from '../../api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootStateOrAny } from 'react-redux';

export const getStepStatuses = createAsyncThunk(
  'stepStatuses',
  async () => {
    const response = await axios.get('/steps/statuses');
    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { stepStatuses } = getState() as RootStateOrAny;

      if (!stepStatuses.pending && !stepStatuses.error) {
        return false;
      }
    },
  },
);
