import React, { Fragment } from 'react';
import './scss/global.scss';

const Parent = props => {
  return (
    <Fragment>
      <div className="landing-wrapper">
        <div className="global-module-wrapper" style={{ height: '100%' }}>{props.children}</div>
      </div>
    </Fragment>
  );
};

export default Parent;
