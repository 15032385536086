export const UPDATE_WEBSITE_DRAFT = 'update_website_draft';
export const SAVE_WEBSITE = 'save_website';
export const NAVIGATE_STEP_WEB = 'navigate_step_web';
export const CREATE_WEBSITE_SUCCESS = 'create_website_success';
export const CREATE_WEBSITE_FAILURE = 'create_website_failure';
export const LOAD_WEBSITE = 'load_website';
export const LOAD_WEBSITE_FAILURE = 'load_website_failure';
export const LOAD_WEBSITE_SUCCESS = 'load_website_success';
export const DELETE_FILE_FAILURE = 'delete_file_failure';
export const DELETE_FILE_SUCCESS = 'delete_file_success';
export const RESET_WEBSITE = 'reset_website';
export const SEND_FOR_REVIEW_SUCCESS = 'send_for_review_success';
export const SEND_FOR_REVIEW_FAILURE = 'send_for_review_failure';
export const LOAD_WEBSITE_TAGS_LIST = 'load_website_tags_list';
export const LOAD_WEBSITE_TAGS_LIST_FAILURE = 'load_website_tags_list_failure';
export const LOAD_JMC_EXISTING_LIST_SUCCESS = 'load_jmc_existing_list_success';
export const LOAD_JMC_EXISTING_LIST_FAILURE = 'load_jmc_existing_list_failure';
export const GET_EVENT_DETAILS = 'get_jmc_event_details';
export const SET_EVENT_DETAILS = 'set_jmc_event_details';
export const GET_EVENT_SPEAKERS = 'get_jmc_event_speakers';
export const SET_EVENT_SPEAKERS = 'set_jmc_event_speakers';
export const GET_SAVE_EVENT_SPEAKER = 'get_save_event_speakers';
export const SET_SAVE_EVENT_SPEAKER = 'set_save_event_speakers';
export const GET_JMC_TEMPLATE_TAGS = 'get_jmc_template_tags';
export const SET_JMC_TEMPLATE_TAGS = 'set_jmc_template_tags';
export const GET_WEBSITE_TYPES_SUCCESS = 'get_website_types_success';
export const GET_WEBSITE_TYPES_PENDING = 'get_website_types_pending';
export const GET_WEBSITE_TYPES_FAILURE = 'get_website_types_failure';
export const SET_MESSAGE_DETAILS = 'set_message_details';
export const SET_CHANNEL_DETAILS = 'set_channel_details';
export const LOAD_CTA_LABELS_SUCCESS = 'load_cta_labels_success';
export const LOAD_CTA_LABELS_FAILURE = 'load_cta_labels_failure';
