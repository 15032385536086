export const NAVIGATE_STEP = 'navigate_step';
export const LOAD_POST_MAIL_JOURNEY_SUCCESS = 'load_post_mail_journey_success';
export const LOAD_POST_MAIL_JOURNEY_FAILURE = 'load_post_mail_journey_failure';
export const UPDATE_POST_MAIL_JOURNEY_DRAFT = 'update_post_mail_journey_draft';
export const LOAD_POST_MAIL_TAGS_LIST = 'load_post_mail_tags_list';
export const LOAD_POST_MAIL_TAGS_LIST_FAILURE = 'load_post_mail_tags_list_failure';
export const CREATE_POST_MAIL_JOURNEY_SUCCESS = 'create_post_mail_journey_success';
export const CREATE_POST_MAIL_JOURNEY_FAILURE = 'create_post_mail_journey_failure';
export const LOAD_POST_MAILS_SUCCESS = 'load_post_mails_success';
export const LOAD_POST_MAILS_FAILURE = 'load_post_mails_failure';
export const SEND_FOR_REVIEW_FAILURE = 'send_for_review_failure';
export const RESET_POST_MAIL_JOURNEY = 'reset_post_mail_journey';