import { LOAD_NODES_IN_BRIEF_SUCCESS, LOAD_NODES_IN_BRIEF_FAILURE } from './types';
const initialState = {
  nodes: [],
  loaded: false,
};

export const techBriefReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_NODES_IN_BRIEF_SUCCESS:
      return {
        nodes: action.payload.nodes.reduce((acc, cur) => {
          acc.push(cur);

          if (cur.step.fragments) {
            return acc.concat(
              cur.step.fragments.map((fragment) => ({
                ...fragment,
                type: {
                  name: 'Fragment',
                  code: 'FRA',
                },
              })),
            );
          }

          return acc;
        }, []),
        loaded: true,
      };
    case LOAD_NODES_IN_BRIEF_FAILURE:
      return { ...state };
    default:
      return state;
  }
};
