import { createReducer } from "@reduxjs/toolkit";
import { initialState, FeatureState } from "./types";
import { fetchFeaturesForCountries, fetchFeaturesForTeams, fetchProfileFeatures } from "./actions";

export default createReducer(initialState as FeatureState, builder => {
    builder
        .addCase(fetchProfileFeatures.pending, state => {
            state.profile.pending = true;
        })
        .addCase(fetchProfileFeatures.fulfilled, (state, { payload }) => {
            state.profile.pending = false;
            state.profile.fullfilled = true;
            state.profile.data = payload;
        })
        .addCase(fetchProfileFeatures.rejected, state => {
            state.profile.pending = false;
            state.profile.error = true;
        })

        .addCase(fetchFeaturesForCountries.pending, state => {
            state.country.pending = true;
        })
        .addCase(fetchFeaturesForCountries.fulfilled, (state, { payload }) => {
            state.country.pending = false;
            state.country.fullfilled = true;
            state.country.data = payload;
        })
        .addCase(fetchFeaturesForCountries.rejected, state => {
            state.country.pending = false;
            state.country.error = true;
        })

        .addCase(fetchFeaturesForTeams.pending, state => {
            state.team.pending = true;
        })
        .addCase(fetchFeaturesForTeams.fulfilled, (state, { payload }) => {
            state.team.pending = false;
            state.team.fullfilled = true;
            state.team.data = payload;
        })
        .addCase(fetchFeaturesForTeams.rejected, state => {
            state.team.pending = false;
            state.team.error = true;
        })
});