import { getUserRole } from '../../helpers/user.helpers';
import * as types from './authTypes';
const initialState = {
  accessToken: null,
  refreshToken: null,
  isFetchingCode: false,
  isFetchingAccessToken: false,
  isAuthenticated: undefined,
  profile: null,
  originalRole: null,
};

export const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_TOKEN_SUCCESS:
      return { ...initialState };
    case types.FETCH_CODE_REQUEST:
      return { ...state, isFetchingCode: true };
    case types.FETCH_CODE_SUCCESS:
      return { ...state, isFetchingCode: false };
    case types.FETCH_ACCESS_TOKENS_REQUEST:
    case types.REFRESH_TOKEN_REQUEST:
      return { ...state, isFetchingAccessToken: true };
    case types.FETCH_CODE_FAILURE:
    case types.FETCH_PROFILE_FAILURE:
    case types.FETCH_ACCESS_TOKENS_FAILURE:
    case types.REFRESH_TOKEN_FAILURE:
      return { isFetchingAccessToken: false, accessToken: null, refreshToken: null };
    case types.FETCH_ACCESS_TOKENS_SUCCESS:
    case types.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        isFetchingAccessToken: false,
        accessToken: action.payload?.accessToken,
        refreshToken: action.payload?.refreshToken,
        isAuthenticated: true,
      };
    case types.EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
      };
    case types.FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        profile: {
          ...action.payload,
          role: getUserRole(action.payload)
        },
        originalRole: getUserRole(action.payload)
      };
    case types.UPDATE_PROFILE: {
      return {
        ...state,
        profile: {...state.profile, profile: action.payload}
      }
    }
    case types.RESET_STATE: {
      return initialState;
    }
    default:
      break;
  }
  return state;
};
