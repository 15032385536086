import { convertWordsList } from '../../app/components/switch-editor/utils/wordRestrictions';
import {
  LOAD_TAGS_LIST_STARTED,
  LOAD_TAGS_LIST_FINISHED,
  TEMPLATE_PREVIEW_STARTED,
  TEMPLATE_PREVIEW_FINISHED,
  SAVE_CONTENT_STARTED,
  SAVE_CONTENT_FINISHED,
  LOAD_CONTENT_STARTED,
  LOAD_CONTENT_FINISHED,
  SET_CONTENT_APPROVAL_STATUS_STARTED,
  SET_CONTENT_APPROVAL_STATUS_FINISHED,
  INIT_NEW_ANNOTATION,
  SAVE_NEW_ANNOTATION_FINISHED,
  CANCEL_NEW_ANNOTATION,
  UPDATE_ANNOTATION_FINISHED,
  LOAD_ANNOTATIONS_TAGS_LIST_STARTED,
  LOAD_ANNOTATIONS_TAGS_LIST_FINISHED,
  LOAD_ANNOTATIONS_LIST_FINISHED,
  LOAD_ANNOTATIONS_LIST_STARTED,
  DELETE_ANNOTATION_FINISHED,
  SAVE_NEW_ANNOTATION_STARTED,
  DELETE_CONTENT_STARTED,
  DELETE_CONTENT_FINISHED,
  CREATE_FRAGMENT_STARTED,
  CREATE_FRAGMENT_FINISHED,
  SAVE_ATTACHMENTS_STARTED,
  SAVE_ATTACHMENTS_FINISHED,
  LOAD_BRIEF_REQUEST_COMMENTS_STARTED,
  LOAD_BRIEF_REQUEST_COMMENTS_FINISHED,
  ADD_BRIEF_REQUEST_COMMENT_FINISHED,
  ADD_BRIEF_REQUEST_COMMENT_STARTED,
  LOAD_BRIEF_REQUEST_STARTED,
  LOAD_BRIEF_REQUEST_FINISHED,
  HANDLE_UPDATED_BRIEF_REQUEST,
  ADD_FRAGMENT_ADDITIONAL_CONTACT_FINISHED,
  REMOVE_FRAGMENT_ADDITIONAL_CONTACT_FINISHED,
  SAVE_USER_TEMPLATE_STARTED,
  SAVE_USER_TEMPLATE_FINISHED,
  LOAD_BEST_PRACTICES_FINISHED,
  LOAD_DELIVERABILITY_STARTED,
  LOAD_DELIVERABILITY_FINISHED,
  LOAD_WORD_RESTRICTIONS_FINISHED,
} from './types';

const initialState = {
  tags: [],
  preview: {
    error: null,
    content: null,
  },
  context: null,
  isLoadingTags: false,
  isLoadingTemplates: false,
  isLoadingTemplatesLastEmails: false,
  isLoading: false,
  isCreatingAnnotation: false,
  isCreatingComment: false,
  isLoadingAnnotations: false,
  isLoadingAnnotationsTags: false,
  isLoadingComments: false,
  isLoadingBriefRequest: false,
  isLoadingDeliverability: false,

  isLoadingContent: true,
  content: null,
  deliverability: null,
  contentVersion: null,
  additionalContacts: [],
  contentIsPendingApproval: false,

  annotations: [],
  annotationsTags: [],
  editingAnnotation: null,

  comments: [],
  briefRequest: null,

  fragment: null,
  isLoadingFragment: false,

  bestPractices: {
    byName: {},
    byType: {},
  },

  wordRestrictions: {
    spam: [],
    restricted: [],
  },
};

export function SwitchReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_TAGS_LIST_STARTED:
      return { ...state, isLoadingTags: true };
    case LOAD_TAGS_LIST_FINISHED:
      return { ...state, tags: action.payload, isLoadingTags: false };
    case TEMPLATE_PREVIEW_STARTED:
      return { ...state, isLoading: true };
    case TEMPLATE_PREVIEW_FINISHED:
      return { ...state, preview: action.payload.preview, context: action.payload.context, isLoading: false };
    case SAVE_CONTENT_STARTED:
    case SAVE_ATTACHMENTS_STARTED:
      return { ...state, isLoading: true };
    case SAVE_CONTENT_FINISHED:
    case SAVE_ATTACHMENTS_FINISHED:
      return { ...state, isLoading: false };
    case LOAD_DELIVERABILITY_STARTED:
      return { ...state, isLoadingDeliverability: true };
    case LOAD_DELIVERABILITY_FINISHED:
      return { ...state, isLoadingDeliverability: false, deliverability: action.payload };
    case LOAD_CONTENT_STARTED:
      return { ...state, isLoadingContent: true };
    case LOAD_CONTENT_FINISHED:
      return {
        ...state,
        isLoadingContent: false,
        content: action.payload,
        contentVersion: action.payload?.version || null,
        additionalContacts: action.payload?.additionalContacts || [],
        contentIsPendingApproval: action.payload?.isPendingApproval || false,
      };
    case SET_CONTENT_APPROVAL_STATUS_STARTED:
      return { ...state, isLoading: true };
    case SET_CONTENT_APPROVAL_STATUS_FINISHED:
      return {
        ...state,
        isLoading: false,
        contentIsPendingApproval: action.payload.pendingApproval,
        contentVersion: action.payload.version,
      };
    case INIT_NEW_ANNOTATION:
      return { ...state, editingAnnotation: action.payload };
    case CANCEL_NEW_ANNOTATION:
      return { ...state, editingAnnotation: null };
    case SAVE_NEW_ANNOTATION_STARTED:
      return {
        ...state,
        isCreatingAnnotation: true,
      };
    case SAVE_NEW_ANNOTATION_FINISHED:
      return {
        ...state,
        editingAnnotation: null,
        annotations: [...state.annotations, action.payload],
        isCreatingAnnotation: false,
      };
    case UPDATE_ANNOTATION_FINISHED:
      return {
        ...state,
        annotations: state.annotations.map(annotation => {
          return annotation.id === action.payload.annotationId
            ? {
                ...annotation,
                comments:
                  action.payload.comment !== undefined
                    ? [...action.payload.comment]
                    : annotation.comments,
                done: action.payload.done !== undefined ? action.payload.done : annotation.done,
              }
            : annotation;
        }),
      };
    case LOAD_ANNOTATIONS_TAGS_LIST_STARTED:
      return { ...state, isLoadingAnnotationsTags: true };
    case LOAD_ANNOTATIONS_TAGS_LIST_FINISHED:
      return { ...state, annotationsTags: action.payload, isLoadingAnnotationsTags: false };
    case LOAD_ANNOTATIONS_LIST_STARTED:
      return { ...state, isLoadingAnnotations: true };
    case LOAD_ANNOTATIONS_LIST_FINISHED:
      return { ...state, annotations: action.payload, isLoadingAnnotations: false };
    case DELETE_ANNOTATION_FINISHED:
      return { ...state, annotations: state.annotations.filter(a => a.id !== action.payload) };
    case DELETE_CONTENT_STARTED:
      return { ...state, isLoading: true };
    case DELETE_CONTENT_FINISHED:
      return {
        ...state,
        isLoading: false,
        content: null,
        contentVersion: null,
        contentIsPendingApproval: false,
        additionalContacts: [],
      };
    case CREATE_FRAGMENT_STARTED:
      return {
        ...state,
        isLoadingFragment: true,
        fragment: null,
      };
    case CREATE_FRAGMENT_FINISHED:
      return {
        ...state,
        isLoadingFragment: false,
        fragment: action.payload.fragment,
      };
    case LOAD_BRIEF_REQUEST_COMMENTS_STARTED:
      return { ...state, isLoadingComments: true };
    case LOAD_BRIEF_REQUEST_COMMENTS_FINISHED:
      return { ...state, comments: action.payload, isLoadingComments: false };
    case ADD_BRIEF_REQUEST_COMMENT_STARTED:
      return { ...state, isLoadingComments: true };
    case ADD_BRIEF_REQUEST_COMMENT_FINISHED:
      return {
        ...state,
        comments: [...state.comments, action.payload],
        isLoadingComments: false,
      };
    case LOAD_BRIEF_REQUEST_STARTED:
      return { ...state, isLoadingBriefRequest: true, briefRequest: null };
    case LOAD_BRIEF_REQUEST_FINISHED:
      return {
        ...state,
        isLoadingBriefRequest: false,
        briefRequest: action.payload,
      };
    case HANDLE_UPDATED_BRIEF_REQUEST:
      return { ...state, briefRequest: action.payload };
    case ADD_FRAGMENT_ADDITIONAL_CONTACT_FINISHED:
      // Add action.payload to state.content.additionalContacts
      if(!action.payload) return { ... state };
      return {
        ...state,
          additionalContacts: (state.additionalContacts || []).concat([action.payload]),
      };
    case REMOVE_FRAGMENT_ADDITIONAL_CONTACT_FINISHED:
      // Remove action.payload from state.content.additionalContacts
      return {
        ...state,
        additionalContacts: state.additionalContacts.filter(({ id }) => id !== action.payload),
      };
    case SAVE_USER_TEMPLATE_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case SAVE_USER_TEMPLATE_FINISHED:
      return {
        ...state,
        isLoading: false,
      };
    case LOAD_BEST_PRACTICES_FINISHED:
      const bestPractices = {
        byName: {},
        byType: {},
      };
      (action.payload || []).forEach(({type, name, content}) => {
        if(type) bestPractices.byType[type] = content;
        else if(name) bestPractices.byName[name] = content;
      });
      return {
        ...state,
        bestPractices,
      };

    case LOAD_WORD_RESTRICTIONS_FINISHED:
      return {
        ...state,
        wordRestrictions: {
          restricted: convertWordsList(action.payload.restricted || []),
          spam: convertWordsList(action.payload.spam || []),
        }
      };
    default:
      return { ...state };
  }
}
