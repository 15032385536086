import * as types from './types';

const initialState = {
  data: null,
  pending: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  if (action.type === types.GET_GENERIC_MAILS_PENDING) {
    return {
      ...state,
      pending: true,
    };
  }

  if (action.type === types.GET_GENERIC_MAILS_SUCCESS) {
    const replyToList = action.payload.filter(el => el.type === 'R');
    const senderList = action.payload.filter(el => el.type === 'S');

    return {
      ...state,
      data: {
        replyTo: replyToList,
        sender: senderList,
      },
      pending: false,
    };
  }

  if (action.type === types.GET_GENERIC_MAILS_FAILURE) {
    return {
      ...state,
      error: action.payload,
      pending: false,
    };
  }

  return state;
};

export default reducer;
