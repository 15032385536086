export const FETCH_CODE_REQUEST = 'FETCH_CODE_REQUEST';
export const FETCH_CODE_SUCCESS = 'FETCH_CODE_SUCCESS';
export const FETCH_CODE_FAILURE = 'FETCH_CODE_FAILURE';

export const FETCH_ACCESS_TOKENS_REQUEST = 'FETCH_ACCESS_TOKENS_REQUEST';
export const FETCH_ACCESS_TOKENS_SUCCESS = 'FETCH_ACCESS_TOKENS_SUCCESS';
export const FETCH_ACCESS_TOKENS_FAILURE = 'FETCH_ACCESS_TOKENS_FAILURE';

export const FETCH_PROFILE_REQUEST = 'FETCH_PROFILE_REQUEST';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAILURE = 'FETCH_PROFILE_FAILURE';

export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';
export const RESET_TOKEN_SUCCESS = 'RESET_TOKEN_SUCCESS';

export const FETCH_PROFILE_FEATURES = 'FETCH_PROFILE_FEATURES';
export const FETCH_PROFILE_FEATURES_SUCCESS = 'FETCH_PROFILE_FEATURES_SUCCESS';
export const FETCH_PROFILE_FEATURES_FAILURE = 'FETCH_PROFILE_FEATURES_FAILURE';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';

export const FETCH_COUNTRY_FEATURES = 'FETCH_COUNTRY_FEATURES';
export const FETCH_COUNTRY_FEATURES_SUCCESS = 'FETCH_COUNTRY_FEATURES_SUCCESS';
export const FETCH_COUNTRY_FEATURES_FAILURE = 'FETCH_COUNTRY_FEATURES_FAILURE';
export const FETCH_FEATURES_FOR_COUNTRIES = 'FETCH_FEATURES_FOR_COUNTRIES';
export const FETCH_FEATURES_FOR_COUNTRIES_SUCCESS = 'FETCH_FEATURES_FOR_COUNTRIES_SUCCESS';
export const FETCH_FEATURES_FOR_COUNTRIES_FAILURE = 'FETCH_FEATURES_FOR_COUNTRIES_FAILURE';

export const FETCH_FEATURES_FOR_TEAMS = 'FETCH_FEATURES_FOR_TEAMS';
export const FETCH_FEATURES_FOR_TEAMS_SUCCESS = 'FETCH_FEATURES_FOR_TEAMS_SUCCESS';
export const FETCH_FEATURES_FOR_TEAMS_FAILURE = 'FETCH_FEATURES_FOR_TEAMS_FAILURE';

export const RESET_STATE = 'RESET_STATE';
