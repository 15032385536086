import { Container, Typography, Box, Alert, AlertTitle } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const AccessDenied = () => {
    const { t } = useTranslation();

    return (
        <Container maxWidth='lg' sx={{ mt: 6, mb: 6 }}>
            <Helmet>
                <title>{t('routingTitles.accessDenied')}</title>
            </Helmet>

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <Alert severity='warning'>
                    <AlertTitle>Access Denied</AlertTitle>

                    <Typography>
                        It seems you do not have access to the requested resources.
                    </Typography>
                    <Typography>
                        If you think this is a mistake, contact us to review your access rights.
                    </Typography>
                </Alert>
            </Box>
        </Container>
    )
}

export default AccessDenied;