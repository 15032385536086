import { LOAD_CUSTOMER_REQUESTS_SUCCESS, NAVIGATE_STEP, RESET_CUSTOMER_REQUESTS, UPDATE_CUSTOMER_REQUESTS_DRAFT, UPDATE_CUSTOMER_REQUESTS_SUCCESS } from "./types";

const initialState = {
    loaded: false,
    activeStep: 0,
    stepDisplayId: '',
    draft: {
        stepId: -1,
        general: {},
        prevNodeEndDate: '',
        saved: 1
    },
    formStatus: {},
    parent: {},
}

export const CustomerRequestsReducer = (state = initialState, action: { type: string, payload: any, step?: number, stepId?: number, prevNodeEndDate?: any }) => {    
    switch (action.type) {
        case NAVIGATE_STEP:
            return { ...state, activeStep: action.step }
        case LOAD_CUSTOMER_REQUESTS_SUCCESS:
            const result = loadCustomerRequestsData(action.payload, action.stepId, action.prevNodeEndDate); 

            return {
                ...state,
                loaded: true,
                draft: result,
                formStatus: action.payload.status,
                stepDisplayId: action.payload.stepDisplayId,
                parent: action.payload.parent
            }
        case UPDATE_CUSTOMER_REQUESTS_DRAFT:
            return {
                ...state,
                draft: action.payload,
            }
        case UPDATE_CUSTOMER_REQUESTS_SUCCESS:
            return {
                ...state,
                loaded: false
            }
        case RESET_CUSTOMER_REQUESTS:
            return initialState;
        default:
            return state;
    }
}

const loadCustomerRequestsData = (payload: any, stepId?: number, prevNodeEndDate?: any) => {
    const result = {
        stepId: stepId,
        general: payload.customerRequests,
        prevNodeEndDate: ''
    }

    if (prevNodeEndDate?.endDate) {
        result.prevNodeEndDate = prevNodeEndDate.endDate
    }

    return result;
}