import { Grid, Typography, TextField } from '@mui/material';
import { ChangeEvent, KeyboardEvent } from 'react';
import { inputLabelSX } from '../../briefRequests/helpers';

/**
 * Usage exemple :
 * const [someStateValue, setSomeStateValue] = useState<string>('');
 *
 * return (
 *  <TextFieldInput
 *    label="Some input label"
 *    value={someStateValue}
 *    onChange={setSomeStateValue}
 *  />
 * )
 */

type TextFieldProps = {
  label?: string;
  placeholder?: string;
  value: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  onKeyDown?: (event: KeyboardEvent) => void;
  onBlur?: () => void;
  sx?: any;
  multiline?: boolean;
  rows?: number;
  maxChars?: number;
  width?: number | string;
  error?: boolean;
  errorMessage?: string | null;
  helperText?: string;
  FormHelperTextProps?: any;
  disableMaxCharsIndicator?: boolean;
  required?: boolean;
  hasRequiredIndicator?: boolean;
};

const TextFieldInput = (props: TextFieldProps) => {
  // expose thge changed value in the onChange event - not the event logic
  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const text = event.target.value as string;
    if (props.maxChars && text.length <= props.maxChars) {
      props.onChange(text);
    } else {
      props.onChange(text.substring(0, props.maxChars));
    }
  };

  return (
    <Grid container>
      {props.label ? (
        <Grid item xs={12}>
          {props.maxChars && !props.disableMaxCharsIndicator ? (
            <Typography sx={{ ...inputLabelSX(), float: 'right' }}>
              {props.maxChars - props.value.length} chars remaining
            </Typography>
          ) : null}
          <Typography sx={{ ...inputLabelSX() }}>{props.label} {props.hasRequiredIndicator ? '*' : ''}</Typography>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <TextField
          fullWidth
          required={props.required}
          value={props.value}
          onChange={handleChange}
          onBlur={props.onBlur}
          sx={{ borderColor: '#DDDEDF', bgcolor: '#FFFFFF', ...props.sx, width: props.width ? props.width : null }}
          size="small"
          disabled={props.disabled}
          multiline={props.multiline}
          placeholder={props.placeholder}
          onKeyDown={props.onKeyDown}
          rows={props.rows}
          error={props.error}
          helperText={props.error ? (props.errorMessage ? props.errorMessage : '') : props.helperText}
          FormHelperTextProps={props.FormHelperTextProps ? props.FormHelperTextProps: {}}
        />
      </Grid>
    </Grid>
  );
};

export default TextFieldInput;
