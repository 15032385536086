import axios from '../../api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchProfileFeatures = createAsyncThunk('profileFeatures', async (profileId) => {
    const response = await axios.get(`profile/${profileId}/features`);

    return response.data.profileFeatures ? response.data.profileFeatures : [];
});

export const fetchFeaturesForCountries = createAsyncThunk('countryFeatures', async (countryIds: string[]) => {
    const response = await axios.get(`country/features`, {
        params: {
            countries: countryIds.join(',')
        }
    });

    return response.data.reduce((acc: any[], cur: any) => {
        return [
            ...acc,
            ...cur.countryFeatures
        ]
    }, []);
});

export const fetchFeaturesForTeams = createAsyncThunk('teamFeatures', async (teamIds: string[]) => {
    const response = await axios.get(`team/features`, {
        params: {
            teams: teamIds.join(',')
        }
    });

    return response.data.reduce((acc: any[], cur: any) => {
        return [
          ...acc,
          ...cur.teamFeatures
        ]
    }, []);
});
