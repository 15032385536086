export const getUserProducts = user =>
  user?.userAccess.reduce((acc, cur) => {
    acc = [...acc, ...(cur.therapeutic?.products || [])];

    if (cur.product && !acc.find(p => p.id === cur.product.id)) {
      acc.push(cur.product);
    }

    return acc;
  }, []);

/**
 * 
 * @param {*} user 
 * @returns user role Admin is returned in lowercase to preserve backwards compatibility 
 */
export const getUserRole = user => { 
  if (!user || !user.profile) return 'User' 
  if (user.profile.name === 'Admin') return 'admin';
  else return user.profile.name;
}


export const getUniqueUserCountries = user => {
  const userAccess = user.userAccess;
  //User access can have duplicate country values,
  //filtering only the unique countries
  if (!userAccess) {
    return [];
  }

  const countries = {};
  userAccess.forEach(ele => {
    const country = ele.country;
    if (!countries[country.id]) {
      countries[country.id] = { name: country.name, code: country.code };
    }
  });

  return Object.keys(countries).map(key => {
    const { name, code } = countries[key];
    return { id: key, name, code };
  });
};
