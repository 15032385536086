import { State } from '../default';

const commonState = {
    pending: true,
    error: false,
    fullfilled: false
}

export const initialState = {
    campaign: {
        data: null,
        ...commonState
    },
    campaignTypes: {
        data: [],
        ...commonState
    },
    products: {
        data: [],
        ...commonState,
        pending: false
    },
    targetBookmarks: {
        data: [],
        ...commonState,
        pending: false
    },
    tags: {
        data: [],
        ...commonState,
        pending: false
    },
    form: {
        ...commonState,
        pending: false
    },
}

interface DataArrayState extends State { data: any[] }
interface DataObjectState extends State { data: any }

export interface TagState extends DataArrayState { }

export interface CloneCampaignState {
    campaign: DataObjectState
    campaignTypes: DataArrayState
    products: DataArrayState
    targetBookmarks: DataArrayState
    tags: TagState
    form: State
}