import { response } from 'express';
import axios from '../../api';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

export const getLightViewList = createAsyncThunk('lightViewList', async () => {
    const response = await axios.get('/campaigns/light-view-list');

    return response.data;
});

export const getProductList = createAsyncThunk('productList', async (campaignTypeId: string) => {
    const response = await axios.get(`/subjects/${campaignTypeId}`);

    return response.data;
});

export const getCountryList = createAsyncThunk('countryList', async () => {
    const response = await axios.get('/country');

    return response.data;
});

export const getUserList = createAsyncThunk('userList', async () => {
    const response = await axios.get('/users');

    return response.data;
});

export const getCampaignTypeList = createAsyncThunk('campaignTypeList', async () => {
    const response = await axios.get('/campaign-types/all');

    return response.data;
});

export const getStepTypeList = createAsyncThunk('stepTypeList', async () => {
    const response = await axios.get('/steps/types');

    return response.data;
});

export const saveCampaignBuilder = createAsyncThunk('saveCampaignBuilder', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axios.post('/campaigns/campaign-builder', payload);

        return response.data;
    } catch (error) {
        // @ts-ignore
        return rejectWithValue(error.response.data);
    }
});

export const updateCampaignBuilder = createAsyncThunk('updateCampaignBuilder', async (payload: { id: number, data: any }) => {
    try {
        const response = await axios.put(`/campaigns/campaign-builder/${payload.id}`, payload.data);

        return response.data;
    } catch (error) {
        console.log(error);
    }
});

export const getEvent = createAsyncThunk('event', async (eventNumber: string) => {
    const response = await axios.get(`/steps/event-details/${eventNumber}`);

    return response.data;
});

export const getCampaignDetails = createAsyncThunk('campaign', async (id: number) => {
    const response = await axios.get(`/campaigns/${id}`, {
        params: {
            join: [
                'campaignType',
                'owner',
                'product',
                'nodes',
                'nodes.step',
                'nodes.step.type',
                'nodes.step.cta',
                'nodes.step.fragments',
                'nodes.step.fragments.step',
                'nodes.step.fragments.step.type',
                'nodes.step.fragments.cta',
            ]
        }
    });

    return response.data;
});

export const clearCampaignDetails = createAction('clearCampaign');

export const deleteCampaign = createAsyncThunk('deleteCampaign', async (id: number) => {
    await axios.delete(`/campaigns/campaign-builder/${id}`);
})