import { createTheme } from '@mui/material/styles';

export default createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1536,
    },
  },
  typography: {
    h6: {
      fontSize: 12,
    },
    // h4: {
    //   fontSize: 18,
    // },
    fontFamily: 'Karbon, sans-serif',
  },
  palette: {
    primary: {
      main: '#019CDC',
    },
    secondary: {
      main: '#ffffff',
      dark: '#f8f8f8',
    },
    text: {
      primary: '#162B4D',
    },
    background: {
      default: '#f7f7f7',
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variant: 'body2',
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          zIndex: 1310,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiDataGrid: {
      defaultProps: {
        density: 'comfortable',
        pagination: true,
        pageSize: 10,
      },
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
        // The code below results in a console error and seems to be redundant. Leaving it here in case there's problems elsewhere in the application
        // fix for 'no results/no rows' overlay that doesn't render correctly when data grid has horizontal scrolling
        // main: {
        //   '> div:first-child:not([class])': {
        //     width: '100% !important',
        //     // 72px is the height of the header. Change this when the header height changes
        //     height: 'calc(100% - 72px) !important',
        //     top: 'unset !important',
        //     bottom: 0,
        //   },
        // },
      },
    },
  },
});
