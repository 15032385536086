import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { AdminListViewValue } from "./types";
import axios from '../../api';

export const setAdminListViewValue = createAction('setAdminListViewValue', (value: AdminListViewValue) => {
    return { payload: value };
});

export const getIntegrationTeamMembers = createAsyncThunk('integrationTeamMembers', async () => {
    const response = await axios.get('/team/integration/members');
    return response.data;
  });
  
export const assignStepsToMember = createAsyncThunk('assignStepsToMember', 
    async (payload: { stepDisplayIds: string[], userId: number | null }) => {
        await axios.post('/steps/assign', {
            stepDisplayIds: payload.stepDisplayIds,
            userId: payload.userId,
        });
});