export const GET_TECHNICALBRIEF_MAIL1TO1 = 'get_technicalbrief_mail1to1';
export const GET_TECHNICALBRIEF_AUTOMATED_EMAIL = 'get_technicalbrief_automated_email';
export const GET_TECHNICALBRIEF_SMS = 'get_technicalbrief_sms';
export const GET_TECHNICALBRIEF_SURVEY = 'get_technicalbrief_survey';
export const GET_TECHNICALBRIEF_FRAGMENT = 'get_technicalbrief_fragment';
export const GET_TECHNICALBRIEF_WEBSITE = 'get_technicalbrief_website';
export const GET_FAILED = 'get_failed';
export const SAVE_TECHNICALBRIEF_SMS = 'save_technicalbrief_sms';
export const GET_EMAIL_TYPES_AND_SUBTYPES = 'get_email_types_and_subtypes';
export const NOTIFICATION_CREATION_SUCCESS = 'notification_creation_success';
export const NOTIFICATION_CREATION_FAILURE = 'notification_creation_failure';
export const LOAD_TAGS_LIST = 'load_tags_list';
export const LOAD_TAGS_LIST_FAILURE = 'load_tags_list_failure';
export const GET_SAVE_EVENT_SPEAKER_TB = 'get_save_event_speaker_tb';
export const SET_SAVE_EVENT_SPEAKER_TB = 'set_save_event_speaker_tb';
export const RESET_STATE = 'reset_state';
export const GET_TECHNOLOGY_LIST = 'get_technology_list';
export const LOAD_EMAIL_TEMPLATES_SUCCESS = 'load_email_template';
export const CTA_URL_LIST = 'cta_url_list';
export const GET_EVENT_NUMBER_DETAILS_LIST = 'get_event_number_details_list';
export const SET_EVENT_NUMBER_DETAILS_LIST = 'set_event_number_details_list';
export const GET_TB_EMAIL_AND_MASS_MAIL_TEMPLATE = 'get_tb_email_and_mass_mail_template';
export const SET_TB_EMAIL_AND_MASS_MAIL_TEMPLATE = 'set_tb_email_and_mass_mail_template';
export const GET_AUTOMATED_MASS_MAIL_TEMPLATE = 'get_automated_mass_mail_template';
export const SET_MESSAGE_DETAILS = 'set_message_details';
export const SET_CHANNEL_DETAILS = 'set_channel_details';
export const GET_SWITCH_EMAIL = 'get_switch_email';
export const GET_SWITCH_EMAIL_FAILURE = 'get_switch_email_failure';