import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { removeNotification } from '../../../store/notification/action';
import { Button } from '@mui/material';

let displayed = [];

export const Notifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(({ notification }) => notification.notifications);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  React.useEffect(() => {
    notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (!key) {
        return;
      }
      if (dismissed) {
        closeSnackbar(key);
        return;
      }
      if (displayed.includes(key)) {
        return;
      }

      enqueueSnackbar(message, {
        key,
        ...options,
        action: (myKey) => (
          <Button onClick={() => closeSnackbar(myKey)} style={{ color: '#333' }}>
            x
          </Button>
        ),
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          const exitedKey = typeof myKey === 'number' ? myKey.toString() : myKey;
          dispatch(removeNotification(exitedKey));
          removeDisplayed(exitedKey);
        },
      });

      storeDisplayed(key);
    });
  }, [closeSnackbar, dispatch, enqueueSnackbar, notifications]);

  return null;
};
