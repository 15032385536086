export const LOAD_EMAIL_USED_TECHNOLOGY_SUCCESS = 'load_email_used_technology_success';
export const LOAD_EMAIL_USED_TECHNOLOGY_FAILURE = 'load_email_used_technology_failure';
export const UPDATE_EVENT_DRAFT = 'update_event_draft';
export const RESET_EVENTS = 'reset_events';
export const NAVIGATE_STEP = 'navigate_step';
export const CREATE_EVENT_SUCCESS = 'create_event_success';
export const CREATE_EVENT_FAILURE = 'create_event_failure';
export const LOAD_EVENT_FAILURE = 'load_event_failure';
export const LOAD_EVENT_SUCCESS = 'load_event_success';
export const SEND_FOR_REVIEW_SUCCESS = 'send_for_review_success';
export const SEND_FOR_REVIEW_FAILURE = 'send_for_review_failure';
export const LOAD_CANVAS_SUCCESS = 'store/events/load_canvas_success';
export const LOAD_CANVAS_FAILURE = 'store/events/load_canvas_failure';
export const DELETE_FILE_FAILURE = 'delete_file_failure';
export const DELETE_FILE_SUCCESS = 'delete_file_success';
export const LOAD_EVENT_TAGS_LIST = 'load_event_tags_list';
export const LOAD_EVENT_TAGS_LIST_FAILURE = 'load_event_tags_list_failure';
export const GET_EVENT_DETAILS = 'get_event_form_event_details';
export const SET_EVENT_DETAILS = 'set_event_form_event_details';
