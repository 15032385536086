export const RESET_CREATE_CAMPAIGN = 'reset_create_campaign';
export const LOAD_CAMPAIGN_TYPE = 'load_campaign_type';
export const LOAD_CAMPAIGN_TYPE_FAILURE = 'load_campaign_type_failure';
export const LOAD_CAMPAIGN_TYPE_SUCCESS = 'load_campaign_type_success';
export const LOAD_CAMPAIGN_STATUS_FAILURE = 'load_campaign_status_failure';
export const LOAD_CAMPAIGN_STATUS_SUCCESS = 'load_campaign_status_success';
export const UPDATE_CAMPAIGN_DRAFT = 'update_campaign_draft';
export const INCREASE_STEP = 'increase_step';
export const UPDATE_STEP = 'update_step';
export const DECREASE_STEP = 'decrease_step';
export const UPDATE_CAMPAIGN_TYPE = 'update_campaign_type';
export const LOAD_SUBJECT = 'load_subject';
export const LOAD_SUBJECT_FAILURE = 'load_subject_failure';
export const LOAD_SUBJECT_SUCCESS = 'load_subject_success';
export const VALIDATE_NAME = 'validate_name';
export const VALIDATE_NAME_SUCCESS = 'validate_name_success';
export const VALIDATE_NAME_FAILURE = 'validate_name_failure';
export const CREATE_CAMPAIGN = 'create_campaign';
export const CREATE_CAMPAIGN_SUCCESS = 'create_campaign_success';
export const CREATE_CAMPAIGN_FAILURE = 'create_campaign_failure';
export const GET_DISPLAY_TAGS_LIST = 'get_display_tags_list';
export const SET_DISPLAY_TAGS_LIST = 'set_display_tags_list';
export const VALIDATE_MANDATORY_TAGS = 'validate_mandatory_tags';
