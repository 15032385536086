import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '../default';

import { getAgencyList } from './actions';
import { AgencyState } from './types';

export default createReducer(initialState as AgencyState, builder => {
  builder
    .addCase(getAgencyList.pending, state => {
      state.pending = true;
    })
    .addCase(getAgencyList.fulfilled, (state, { payload }) => {
      state.pending = false;
      state.fullfilled = true;
      state.data = payload;
    })
    .addCase(getAgencyList.rejected, state => {
      state.pending = false;
      state.error = true;
    });
});
