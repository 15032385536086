import {
  LOAD_WEBSITE_SUCCESS,
  LOAD_WEBSITE_FAILURE,
  NAVIGATE_STEP_WEB,
  UPDATE_WEBSITE_DRAFT,
  DELETE_FILE_FAILURE,
  DELETE_FILE_SUCCESS,
  CREATE_WEBSITE_SUCCESS,
  RESET_WEBSITE,
  SEND_FOR_REVIEW_SUCCESS,
  SEND_FOR_REVIEW_FAILURE,
  LOAD_WEBSITE_TAGS_LIST,
  LOAD_WEBSITE_TAGS_LIST_FAILURE,
  LOAD_JMC_EXISTING_LIST_SUCCESS,
  LOAD_JMC_EXISTING_LIST_FAILURE,
  GET_EVENT_DETAILS,
  SET_EVENT_DETAILS,
  GET_EVENT_SPEAKERS,
  SET_EVENT_SPEAKERS,
  GET_SAVE_EVENT_SPEAKER,
  SET_SAVE_EVENT_SPEAKER,
  SET_JMC_TEMPLATE_TAGS,
  GET_WEBSITE_TYPES_PENDING,
  GET_WEBSITE_TYPES_SUCCESS,
  GET_WEBSITE_TYPES_FAILURE,
  SET_MESSAGE_DETAILS,
  SET_CHANNEL_DETAILS,
  LOAD_CTA_LABELS_SUCCESS,
  LOAD_CTA_LABELS_FAILURE
} from './types';
import { API_STATUS_CONSTANTS } from '../../app/constants/form-constants';

const initialState = {
  requestInProgress: false,
  activeStep: 0,
  websiteEditDataLoaded: false,
  websiteDraft: {
    stepId: -1,
    general: {},
    files: [],
    tags: [],
    canvasCustomTag: [],
    isSaved: 1,
    campaignDetails: [],
    validateErrors: {},
    prevNodeEndDate: '',
    createdBy: '',
    missingFieldErrors: [],
  },
  eventDetails: {
    data: {},
    status: API_STATUS_CONSTANTS.init,
  },
  eventSpeakersStatus: API_STATUS_CONSTANTS.init,
  eventSpeakerSaveStatus: API_STATUS_CONSTANTS.init,
  validateErrors: {},
  isSentForReview: false,
  formStatus: {},
  stepDisplayId: '',
  tagsListData: [],
  jmcExistingList: [],
  ctaTemplateList: [],
  teamsIntegrationDetails: {}
};

export function websiteCreateReducer(state = initialState, action) {
  let res = [];
  switch (action.type) {
    case LOAD_WEBSITE_SUCCESS:
      res = loadWebsiteData(action.payload, action.stepId, action.prevNodeEndDate);
      
      return {
        ...state,
        requestInProgress: false,
        websiteDraft: { ...state.websiteDraft, ...res },
        websiteEditDataLoaded: true,
        isSentForReview: !action.payload?.status.userEditable,
        formStatus: action.payload.status,
        stepDisplayId: action.payload.stepDisplayId,
        teamsIntegrationDetails: action.payload.teamsIntegrationDetails
      };
    case LOAD_WEBSITE_FAILURE: // handle error
      return { ...state, requestInProgress: false };
    case DELETE_FILE_FAILURE: // handle error
      return { ...state, requestInProgress: false };
    case DELETE_FILE_SUCCESS:
      return { ...state, requestInProgress: false };
    case UPDATE_WEBSITE_DRAFT:
      return { ...state, websiteDraft: action.payload, validateErrors: {} };
    case NAVIGATE_STEP_WEB:
      return { ...state, activeStep: action.step };
    case CREATE_WEBSITE_SUCCESS:
      return {
        ...state,
        requestInProgress: false,
        websiteEditDataLoaded: false,
      };
    case RESET_WEBSITE:
      return initialState;
    case SEND_FOR_REVIEW_SUCCESS:
      return { ...state, isSentForReview: true, websiteEditDataLoaded: false };
    case SEND_FOR_REVIEW_FAILURE: // handle error
      return { ...state, websiteEditDataLoaded: false };
    case LOAD_WEBSITE_TAGS_LIST:
      return {
        ...state,
        tagsListData: action.payload,
      };
    case LOAD_JMC_EXISTING_LIST_SUCCESS:
      return {
        ...state,
        jmcExistingList: action.payload,
      };
    case LOAD_CTA_LABELS_SUCCESS:
      return {
        ...state,
        ctaTemplateList: action.payload
      };
    case GET_EVENT_DETAILS:
      return {
        ...state,
        eventDetails: {
          data: {},
          status: API_STATUS_CONSTANTS.loading,
        },
      };
    case SET_EVENT_DETAILS:
      return {
        ...state,
        eventDetails: {
          data: action.payload.data,
          status: action.payload.status,
        },
      };
    case GET_EVENT_SPEAKERS:
      return {
        ...state,
        eventSpeakersStatus: API_STATUS_CONSTANTS.loading,
      };
    case SET_EVENT_SPEAKERS:
      return {
        ...state,
        websiteDraft: {
          ...state.websiteDraft,
          general: { ...state.websiteDraft.general, eventSpeakers: action.payload.data },
        },
        eventSpeakersStatus: action.payload.status,
      };
    case GET_SAVE_EVENT_SPEAKER:
      return {
        ...state,
        eventSpeakerSaveStatus: API_STATUS_CONSTANTS.loading,
      };
    case SET_SAVE_EVENT_SPEAKER:
      if (action.payload.status === API_STATUS_CONSTANTS.success) {
        const newSpeakerList = getUpdatedEventSpeakerList(state, action);
        return {
          ...state,
          websiteDraft: {
            ...state.websiteDraft,
            general: { ...state.websiteDraft.general, eventSpeakers: [...newSpeakerList] },
          },
          eventSpeakerSaveStatus: action.payload.status,
        };
      } else {
        return {
          ...state,
          eventSpeakerSaveStatus: action.payload.status,
        };
      }
    case SET_JMC_TEMPLATE_TAGS:
      if (action.payload.status === API_STATUS_CONSTANTS.success) {
        return {
          ...state,
          websiteDraft: {
            ...state.websiteDraft,
            tags: action.payload.data?.tags,
            canvasCustomTag: action.payload.data?.canvasCustomTag,
          },
        };
      } else {
        return state;
      }
    case SET_MESSAGE_DETAILS:
      return {
        ...state,
        teamsIntegrationDetails: {
          ...state.teamsIntegrationDetails,
          messageDetails: action.payload
        }
      }
    case SET_CHANNEL_DETAILS:
      return {
        ...state,
        teamsIntegrationDetails: {
          ...state.teamsIntegrationDetails,
          channelDetails: action.payload
        }
      }
    case LOAD_WEBSITE_TAGS_LIST_FAILURE:
    case LOAD_JMC_EXISTING_LIST_FAILURE:
    case LOAD_CTA_LABELS_FAILURE:
    default:
      return state;
  }
}

export function getUpdatedEventSpeakerList(state, action) {
  let newSpeakerList = [];
  const speakerData = state.websiteDraft.general?.eventSpeakers?.map(ele => {
    return { ...ele };
  });
  const newSpeakerData = action.payload.data;
  const index = speakerData?.findIndex(ele => ele.id === newSpeakerData?.id);
  if (index !== -1) {
    speakerData[index] = { ...newSpeakerData };
    newSpeakerList = [...speakerData];
  } else {
    newSpeakerList = [newSpeakerData, ...speakerData];
  }
  return newSpeakerList;
}

export function loadWebsiteData(payload, stepId, prevNodeEndDate) {
  const temp = payload;
  const res = {
    stepId: stepId,
    general: {},
    files: [],
    tags: [],
    canvasCustomTag: [],
    isSaved: 1,
    campaignDetails: [],
    validateErrors: {},
    default_values: {},
    prevNodeEndDate: '',
    createdBy: '',
    missingFieldErrors: [],
  };

  if (temp.website) {
    res.general = temp.website;
  }
  if (temp.managedFiles) {
    res.files = temp.managedFiles;
  }
  if (temp.tags) {
    res.tags = temp.tags;
  }
  if (temp.canvasCustomTag) {
    res.canvasCustomTag = temp.canvasCustomTag;
  }
  if (prevNodeEndDate?.endDate) {
    res.prevNodeEndDate = prevNodeEndDate.endDate;
  }
  if (temp.createdBy) {
    res.createdBy = temp.createdBy.id;
  }

  return res;
}

export const websiteTypesReducer = (
  state = {
    data: null,
    pending: false,
    error: null,
  },
  action,
) => {
  switch (action.type) {
    case GET_WEBSITE_TYPES_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_WEBSITE_TYPES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        pending: false,
      };
    case GET_WEBSITE_TYPES_FAILURE:
      return {
        ...state,
        error: action.payload,
        pending: false,
      };
    default:
      return state;
  }
};
