export const CAMPAIGN_NAME_MAX_LENGTH = 75;
export const TEXTFIELD_MAX_LENGTH = 255;

// api states
export const API_STATUS_CONSTANTS = {
  init: null,
  loading: 0,
  success: 1,
  failed: -1,
};

//step name constants
export const enumStepNames = {
  email1to1: 'Email 1to1',
  massMail: 'Mass Mail',
  automatedMail: 'Automated Mail',
  survey: 'Survey',
  sms: 'SMS',
  website: 'Website',
  fragment: 'Fragment',
  event: 'Event',
  calls: 'Calls',
  reminder: 'Reminder',
  exit: 'Exit',
};

export const enumStepId = {
  email1to1: 1,
  massMail: 2,
  calls: 3,
  survey: 4,
  event: 5,
  sms: 6,
  website: 7,
  fragment: 8,
  reminder: 9,
  exit: 10,
  start: 11,
  postMailing: 12,
};
