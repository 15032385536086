import * as types from './authTypes';
import { AuthService } from './AuthService';
import axios from 'axios'; 
import { profile } from '../constants/api-constants'; 
import { UPDATE_PROFILE } from './authTypes';

export const checkOauth = () => async (dispatch) => {
  dispatch({ type: types.FETCH_CODE_REQUEST });
  try {
    const service = new AuthService();
    await service.authorize();
    dispatch({ type: types.FETCH_CODE_SUCCESS });
    dispatch({ type: types.FETCH_ACCESS_TOKENS_REQUEST });
    const tokens = await service.fetchAccessToken();
    dispatch({ type: types.FETCH_ACCESS_TOKENS_SUCCESS, payload: tokens });
    if (tokens) {
      const profile = await service.getProfile(tokens.accessToken);
      dispatch({ type: types.FETCH_PROFILE_SUCCESS, payload: profile });
    }
  } catch (error) {
    dispatch({ type: types.FETCH_CODE_FAILURE, payload: error });
    dispatch({ type: types.FETCH_ACCESS_TOKENS_FAILURE, payload: error });
    dispatch({ type: types.FETCH_PROFILE_FAILURE, payload: error });
  }
};

export const fetchProfile = () => async (dispatch) => {
  dispatch({ type: types.FETCH_PROFILE_REQUEST });
  try {
    const service = new AuthService();
    const profile = await service.getProfile();
    dispatch({ type: types.FETCH_PROFILE_SUCCESS, payload: profile });
  } catch (error) {
    dispatch({ type: types.FETCH_PROFILE_FAILURE, payload: error });
  }
};

export const updateProfile = profileId => async (dispatch) => {
  try {
     const res = await axios.post(`${profile}/update-user-profile`, {profileId});
     dispatch({ type: UPDATE_PROFILE, payload: res.data });
  } catch (error) {
    console.error('update user profile failed', error);
    return false;
  }
}

export const refreshToken = () => async (dispatch) => {
  dispatch({ type: types.REFRESH_TOKEN_REQUEST });
  try {
    const service = new AuthService();
    const tokens = await service.refreshToken();
    dispatch({ type: types.REFRESH_TOKEN_SUCCESS, payload: tokens });
  } catch (error) {
    dispatch({ type: types.REFRESH_TOKEN_FAILURE, payload: error });
  }
};

export const checkLocalStorageVersion = () => dispatch => {
  const service = new AuthService();
  const isStorageCleared = service.checkLocalStorageVersion();

  if (isStorageCleared) {
    dispatch({ type: types.RESET_STATE });
  }
}
