export const LOAD_EMAIL_TEMPLATES = 'load_email_templates';
export const LOAD_EMAIL_TEMPLATES_SUCCESS = 'load_email_templates_success';
export const LOAD_EMAIL_TEMPLATES_FAILURE = 'load_email_templates_failure';
export const LOAD_EMAIL_USED_TECHNOLOGY_SUCCESS = 'load_email_used_technology_success';
export const LOAD_EMAIL_USED_TECHNOLOGY_FAILURE = 'load_email_used_technology_failure';
export const UPDATE_EMAIL_JOURNEY_DRAFT = 'update_email_journey_draft';
export const SAVE_EMAIL_JOURNEY = 'save_email_journey';
export const NAVIGATE_STEP = 'navigate_step';
export const CREATE_EMAIL_JOURNEY_SUCCESS = 'create_email_journey_success';
export const CREATE_EMAIL_JOURNEY_FAILURE = 'create_email_journey_failure';
export const LOAD_EMAIL_JOURNEY = 'load_email_journey';
export const LOAD_EMAIL_JOURNEY_FAILURE = 'load_email_journey_failure';
export const LOAD_EMAIL_JOURNEY_SUCCESS = 'load_email_journey_success';
export const LOAD_CTA_LABELS_SUCCESS = 'load_cta_labels_success';
export const LOAD_CTA_LABELS_FAILURE = 'load_cta_labels_failure';
export const DELETE_FILE_FAILURE = 'delete_file_failure';
export const DELETE_FILE_SUCCESS = 'delete_file_success';
export const RESET_EMAIL_JOURNEY = 'reset_email_campaign';
export const SEND_FOR_REVIEW_SUCCESS = 'email_send_for_review_success';
export const SEND_FOR_REVIEW_FAILURE = 'email_send_for_review_failure';
export const LOAD_EMAIL_TYPE_AND_SUBTYPE = 'load_email_type_and_subtype';
export const LOAD_EMAIL_TYPE_AND_SUBTYPE_FAILURE = 'load_email_type_and_subtype_failure';
export const LOAD_EMAIL_TAGS_LIST = 'load_email_tags_list';
export const LOAD_EMAIL_TAGS_LIST_FAILURE = 'load_email_tags_list_failure';
export const GET_EVENT_DETAILS = 'get_email_event_details';
export const SET_EVENT_DETAILS = 'set_email_event_details';
export const GET_INTEGRATION_TEAM = 'get_email_integration_team';
export const SET_INTEGRATION_TEAM = 'set_email_integration_team';
export const GET_SENDERS_LIST = 'get_email_senders_list';
export const SET_SENDERS_LIST = 'set_email_senders_list';
export const RECALL_FROM_REVIEW_SUCCESS = 'email_recall_from_review_success';
export const RECALL_FROM_REVIEW_FAILURE = 'email_recall_from_review_failure';
export const RESET_EMAIL_EDIT_LOADED_STATE = 'reset_email_edit_loaded_state';
export const GET_EMAIL_EVENT_FORM_LINK_STATUS = 'get_email_event_form_link_status';
export const SET_MESSAGE_DETAILS = 'set_message_details';
export const GET_LINKED_WEBSITES = 'get_linked_websites';
export const SET_CHANNEL_DETAILS = 'set_channel_details';
