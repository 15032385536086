import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '../default';

import { getBriefList, createBrief, updateBrief, deleteBrief } from './actions';
import { BriefState } from './types';

export default createReducer(initialState as BriefState, builder => {
  builder

    // Brief Listing
    .addCase(getBriefList.pending, state => {
      state.pending = true;
    })
    .addCase(getBriefList.fulfilled, (state, { payload }) => {
      state.pending = false;
      state.fullfilled = true;
      state.errorMessage = null;
      state.data = payload;
    })
    .addCase(getBriefList.rejected, state => {
      state.pending = false;
      state.error = true;
    })

    // Brief Creation
    .addCase(createBrief.pending, state => {
      state.pending = true;
    })
    .addCase(createBrief.fulfilled, (state, { payload }) => {
      state.pending = false;
      state.error = false;
      state.errorMessage = null;
      state.data = state.data.concat(payload);
    })
    .addCase(createBrief.rejected, (state, { payload }: any) => {
      state.pending = false;
      state.error = true; 
      state.errorMessage = payload.message;
    })

    // Brief Update
    .addCase(updateBrief.pending, state => {
      state.pending = true;
    })
    .addCase(updateBrief.fulfilled, (state, { payload }) => {
      state.pending = false;
      const dataWithoutUpdatedRecord = state.data.filter(brief => brief.id !== payload.id);
      state.data = dataWithoutUpdatedRecord.concat(payload);
    })
    .addCase(updateBrief.rejected, state => {
      state.pending = false;
      state.error = true;
    })

    // Brief Deletion
    .addCase(deleteBrief.pending, state => {
      state.pending = true;
    })
    .addCase(deleteBrief.fulfilled, (state, { payload }) => {
      state.pending = false;
      state.data = state.data.filter(brief => brief.id !== payload.id);
    })
    .addCase(deleteBrief.rejected, state => {
      state.pending = false;
      state.error = true;
    });
});
