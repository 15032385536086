import React from 'react';
import { useCallback, useState } from 'react';
import { Menu, MenuItem, Button } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import styles from '../country-select/CountrySelect.module.scss';
import { useTranslation } from 'react-i18next';


const CountrySelect = ({ color }) => {
  const { i18n } = useTranslation();
  const [selectedCountry, selectCountry] = useState(i18n.language);
  const [anchor, setAnchor] = useState(null);

  const [options] = useState(i18n.languages)

  const handleClick = useCallback((event) => {
    setAnchor(event.currentTarget);
  }, []);

  const handleMenuItemClick = function (country) {
    selectCountry(country);
    i18n.changeLanguage(country)
    setAnchor(null);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  return (
    <React.Fragment>
      <Button
        className={styles.country}
        color="inherit"
        sx={ color ? { color: `${color} !important` } : undefined }
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {selectedCountry}
        <KeyboardArrowDown />
      </Button>
      <Menu
        style={{ zIndex: 1350 }}
        anchorEl={anchor}
        keepMounted
        open={Boolean(anchor)}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            onClick={() => handleMenuItemClick(option)}
            selected={selectedCountry === option}
            className={styles.menu}
          >
            {option.toUpperCase()}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default CountrySelect;
