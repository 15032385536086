import * as types from './types';

export const projectTypesReducer = (
  state = {
    data: null,
    pending: false,
    error: null,
  },
  action,
) => {
  if (action.type === types.GET_PROJECT_TYPES_PENDING) {
    return {
      ...state,
      pending: true,
    };
  }

  if (action.type === types.GET_PROJECT_TYPES_SUCCESS) {
    return {
      ...state,
      data: action.payload,
      pending: false,
    };
  }

  if (action.type === types.GET_PROJECT_TYPES_FAILURE) {
    return {
      ...state,
      error: action.payload,
      pending: false,
    };
  }

  return state;
};
