import {
  LOAD_EMAIL_USED_TECHNOLOGY_SUCCESS,
  LOAD_EMAIL_USED_TECHNOLOGY_FAILURE,
  LOAD_AUTO_EMAIL_FAILURE,
  LOAD_AUTO_EMAIL_SUCCESS,
  LOAD_CTA_LABELS_SUCCESS,
  LOAD_CTA_LABELS_FAILURE,
  RESET_AUTOMATED_MAIL,
  NAVIGATE_STEP,
  CREATE_AUTO_EMAIL_SUCCESS,
  UPDATE_AUTO_EMAIL_DRAFT,
  SEND_FOR_REVIEW_SUCCESS,
  SEND_FOR_REVIEW_FAILURE,
  DELETE_FILE_FAILURE,
  DELETE_FILE_SUCCESS,
  CREATE_AUTO_EMAIL_FAILURE,
  LOAD_AUTO_EMAIL_TYPE_AND_SUBTYPE,
  LOAD_AUTO_EMAIL_TAGS_LIST,
  LOAD_AUTO_EMAIL_TAGS_LIST_FAILURE,
  GET_EVENT_DETAILS,
  SET_EVENT_DETAILS,
  GET_INTEGRATION_TEAM,
  SET_INTEGRATION_TEAM,
  GET_SENDERS_LIST,
  SET_SENDERS_LIST,
  RECALL_FROM_REVIEW_SUCCESS,
  RECALL_FROM_REVIEW_FAILURE,
  GET_MASS_MAIL_TEMPLATE,
  SET_MASS_MAIL_TEMPLATE,
  GET_EMAIL_AND_MASS_MAIL_TEMPLATE,
  SET_EMAIL_AND_MASS_MAIL_TEMPLATE,
  GET_MASS_MAIL_SEND_TO_LIST,
  SET_MASS_MAIL_SEND_TO_LIST,
  GET_MASS_MAIL_REPLY_TO_LIST,
  SET_MASS_MAIL_REPLY_TO_LIST,
  RESET_MASS_MAIL_EDIT_LOADED_STATE,
  GET_MASS_MAIL_EVENT_FORM_LINK_STATUS,
  GET_LINKED_WEBSITES,
  SET_MESSAGE_DETAILS,
  SET_CHANNEL_DETAILS
} from './types';
import { API_STATUS_CONSTANTS } from '../../app/constants/form-constants';

const initialState = {
  requestInProgress: false,
  activeStep: 0,
  emailEditDataLoaded: false,
  autoEmailDraft: {
    stepId: -1,
    general: {},
    cta: [],
    reminders: [],
    files: [],
    additionalUrls: [],
    internalAudianceSender: [],
    automatedExternalAudiance: {},
    tags: [],
    canvasCustomTag: [],
    isSaved: 1,
    isReminderEdited: 0,
    campaignDetails: [],
    validateErrors: {},
    missingFieldErrors: {},
    prevNodeEndDate: '',
  },
  eventDetails: {
    data: {},
    status: API_STATUS_CONSTANTS.init,
  },
  integrationTeam: {
    data: [],
    status: API_STATUS_CONSTANTS.init,
  },
  sendersList: {
    data: [],
    status: API_STATUS_CONSTANTS.init,
  },
  emailTemplates: {
    data: [],
    status: API_STATUS_CONSTANTS.init,
  },
  emailAndMassMailTemplates: {
    data: [],
    status: API_STATUS_CONSTANTS.init,
  },
  sendToList: {
    data: [],
    status: API_STATUS_CONSTANTS.init,
  },
  replyToList: {
    data: [],
    status: API_STATUS_CONSTANTS.init,
  },
  isEventFormLinked: false,
  linkedWebsites: [],
  validateErrors: {},
  emailType: [],
  emailSubtype: [],
  emailTypesLoaded: false,
  isSentForReview: false,
  formStatus: {},
  stepDisplayId: '',
  tagsListData: [],
  stepCanvasData: '',
  teamsIntegrationDetails: {},
  isLinkToSwitchEnabled: false,
};

export function automatedEmailReducer(state = initialState, action) {
  switch (action.type) {
    case GET_MASS_MAIL_TEMPLATE:
      return {
        ...state,
        emailTemplates: {
          data: [],
          status: API_STATUS_CONSTANTS.loading,
        },
      };
    case SET_MASS_MAIL_TEMPLATE: // handle error
      return {
        ...state,
        emailTemplates: {
          data: action.payload.data,
          status: action.payload.status,
        },
      };
    case GET_EMAIL_AND_MASS_MAIL_TEMPLATE:
      return {
        ...state,
        emailAndMassMailTemplates: {
          data: [],
          status: API_STATUS_CONSTANTS.loading,
        },
      };
    case SET_EMAIL_AND_MASS_MAIL_TEMPLATE: // handle error
      return {
        ...state,
        emailAndMassMailTemplates: {
          data: action.payload.data,
          status: action.payload.status,
        },
      };
    case CREATE_AUTO_EMAIL_SUCCESS:
      return {
        ...state,
        autoEmailDraft: {...state.autoEmailDraft, isSaved :1},
        requestInProgress: false,
        emailEditDataLoaded: false,
      };
    case LOAD_CTA_LABELS_SUCCESS:
      let res_arr = [];
      if (action.payload) {
        res_arr = action.payload;
      }
      return {
        ...state,
        requestInProgress: false,
        ctaLabels: res_arr,
        ctaLabelsLoaded: true,
      };
    case LOAD_CTA_LABELS_FAILURE: // handle error
      return { ...state, requestInProgress: false };
    case DELETE_FILE_FAILURE: // handle error
      return { ...state, requestInProgress: false };
    case DELETE_FILE_SUCCESS:
      return { ...state, requestInProgress: false };
    case LOAD_AUTO_EMAIL_SUCCESS:
      const temp = action.payload;
      let res = {
        stepId: action.stepId,
        general: { isDefaultSetting: true },
        cta: [],
        reminders: [],
        files: [],
        additionalUrls: [],
        internalAudianceSender: [],
        automatedExternalAudiance: {},
        tags: [],
        canvasCustomTag: [],
        isSaved: 1,
        isReminderEdited: 0,
        campaignDetails: [],
        validateErrors: {},
        default_values: {},
        prevNodeEndDate: '',
        createdBy: '',
        missingFieldErrors: [],
      };

      if (temp.automated) {
        res.general = temp.automated;
      }

      if (temp.cta) {
        res.cta = temp.cta;
      }

      if (temp.mailReminder) {
        if (temp.mailReminder?.length > 1) {
          res.reminders = temp.mailReminder;
        } else if (temp.mailReminder?.length) {
          const [reminder] = temp.mailReminder;
          if (reminder?.reminderIndex || reminder?.subject || reminder?.whenDelay) {
            res.reminders = temp.mailReminder;
          }
        }
      }

      if (temp.managedFiles) {
        res.files = temp.managedFiles;
      }

      if (temp.additionalUrls) {
        res.additionalUrls = temp.additionalUrls;
      }

      if (temp.internalAudianceSender) {
        res.internalAudianceSender = temp.internalAudianceSender;
      }

      if (temp.automatedExternalAudiance) {
        res.automatedExternalAudiance = temp.automatedExternalAudiance;
      }

      if (temp.tags) {
        res.tags = temp.tags;
      }

      if (temp.canvasCustomTag) {
        res.canvasCustomTag = temp.canvasCustomTag;
      }

      if (action.prevNodeEndDate?.endDate) {
        res.prevNodeEndDate = action.prevNodeEndDate?.endDate;
      }

      if (temp.createdBy) {
        res.createdBy = temp.createdBy.id;
      }

      return {
        ...state,
        requestInProgress: false,
        autoEmailDraft: res,
        isLinkToSwitchEnabled: !!res.general?.isSwitchTemplate,
        emailEditDataLoaded: true,
        isSentForReview: !action.payload.status.userEditable,
        formStatus: action.payload.status,
        stepDisplayId: action.payload.stepDisplayId,
        stepCanvasData: action.payload.canvasData,
        teamsIntegrationDetails: action.payload.teamsIntegrationDetails,
        switchEmail: action.payload.switchEmail
      };
    case LOAD_AUTO_EMAIL_FAILURE: // handle error
      return { ...state, requestInProgress: false };
    case CREATE_AUTO_EMAIL_FAILURE: // handle error
      return { ...state, requestInProgress: false };
    case LOAD_EMAIL_USED_TECHNOLOGY_SUCCESS:
      return {
        ...state,
        requestInProgress: false,
        emailUsedTechnology: action.payload,
        emailUsedTechnologyLoaded: true,
      };
    case LOAD_EMAIL_USED_TECHNOLOGY_FAILURE: // handle error
      return { ...state, requestInProgress: false };
    case UPDATE_AUTO_EMAIL_DRAFT:
      return { ...state, autoEmailDraft: action.payload, validateErrors: {} };
    case RESET_AUTOMATED_MAIL:
      return initialState;
    case NAVIGATE_STEP:
      return { ...state, activeStep: action.step };
    case SEND_FOR_REVIEW_SUCCESS:
      return { ...state, isSentForReview: true, emailEditDataLoaded: false };
    case RECALL_FROM_REVIEW_SUCCESS:
      return { ...state, isSentForReview: false, emailEditDataLoaded: false };
    case LOAD_AUTO_EMAIL_TYPE_AND_SUBTYPE:
      return {
        ...state,
        emailType: action.payload.type,
        emailSubtype: action.payload.subtype,
        emailTypesLoaded: true,
      };
    case RECALL_FROM_REVIEW_FAILURE:
    case SEND_FOR_REVIEW_FAILURE: // handle error
      return { ...state, emailEditDataLoaded: false };

    case GET_EVENT_DETAILS:
      return {
        ...state,
        eventDetails: {
          data: {},
          status: API_STATUS_CONSTANTS.loading,
        },
      };
    case SET_EVENT_DETAILS:
      return {
        ...state,
        eventDetails: {
          data: action.payload.data,
          status: action.payload.status,
        },
      };
    case GET_INTEGRATION_TEAM:
      return {
        ...state,
        integrationTeam: {
          data: [],
          status: API_STATUS_CONSTANTS.loading,
        },
      };
    case SET_INTEGRATION_TEAM:
      return {
        ...state,
        integrationTeam: {
          data: action.payload.data,
          status: action.payload.status,
        },
      };
    case GET_SENDERS_LIST:
      return {
        ...state,
        sendersList: {
          data: [],
          status: API_STATUS_CONSTANTS.loading,
        },
      };
    case SET_SENDERS_LIST:
      return {
        ...state,
        sendersList: {
          data: action.payload.data,
          status: action.payload.status,
        },
      };
    case GET_MASS_MAIL_SEND_TO_LIST:
      return {
        ...state,
        sendToList: {
          data: [],
          status: API_STATUS_CONSTANTS.loading,
        },
      };
    case SET_MASS_MAIL_SEND_TO_LIST:
      return {
        ...state,
        sendToList: {
          data: action.payload.data,
          status: action.payload.status,
        },
      };
    case GET_MASS_MAIL_REPLY_TO_LIST:
      return {
        ...state,
        replyToList: {
          data: [],
          status: API_STATUS_CONSTANTS.loading,
        },
      };
    case SET_MASS_MAIL_REPLY_TO_LIST:
      return {
        ...state,
        replyToList: {
          data: action.payload.data,
          status: action.payload.status,
        },
      };
    case GET_MASS_MAIL_EVENT_FORM_LINK_STATUS:
      return {
        ...state,
        isEventFormLinked: action.payload,
      };
    case GET_LINKED_WEBSITES:
        return {
          ...state,
          linkedWebsites: action.payload,
        };
    case LOAD_AUTO_EMAIL_TAGS_LIST:
      return {
        ...state,
        tagsListData: action.payload,
      };
    case RESET_MASS_MAIL_EDIT_LOADED_STATE:
      return {
        ...state,
        emailEditDataLoaded: false,
      };
    case SET_MESSAGE_DETAILS:
      return {
        ...state,
        teamsIntegrationDetails: {
          ...state.teamsIntegrationDetails,
          messageDetails: action.payload
        }
      }
    case SET_CHANNEL_DETAILS:
      return {
        ...state,
        teamsIntegrationDetails: {
          ...state.teamsIntegrationDetails,
          channelDetails: action.payload
        }
      }
    case LOAD_AUTO_EMAIL_TAGS_LIST_FAILURE:
    default:
      return state;
  }
}
