import {
  LOAD_EMAIL_USED_TECHNOLOGY_SUCCESS,
  LOAD_EMAIL_USED_TECHNOLOGY_FAILURE,
  LOAD_EVENT_FAILURE,
  LOAD_EVENT_SUCCESS,
  RESET_EVENTS,
  NAVIGATE_STEP,
  UPDATE_EVENT_DRAFT,
  SEND_FOR_REVIEW_SUCCESS,
  SEND_FOR_REVIEW_FAILURE,
  CREATE_EVENT_SUCCESS,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_FAILURE,
  LOAD_EVENT_TAGS_LIST,
  LOAD_EVENT_TAGS_LIST_FAILURE,
  GET_EVENT_DETAILS,
  SET_EVENT_DETAILS,
} from './types';
import { API_STATUS_CONSTANTS } from '../../app/constants/form-constants';

const initialState = {
  requestInProgress: false,
  activeStep: 0,
  eventLoaded: false,
  eventDraft: {
    stepId: -1,
    general: {},
    files: [],
    tags: [],
    canvasCustomTag: [],
    isSaved: 1,
    campaignDetails: [],
    validateErrors: {},
    prevNodeEndDate: '',
    createdBy: '',
  },
  eventDetails: {
    data: {},
    status: API_STATUS_CONSTANTS.init,
  },
  emailTemplates: {},
  validateErrors: {},
  isSentForReview: false,
  formStatus: {},
  stepDisplayId: '',
  tagsListData: [],
};

export function eventReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_EVENT_SUCCESS:
      const temp = action.payload;
      const res = {
        stepId: action.stepId,
        general: {},
        files: [],
        tags: [],
        canvasCustomTag: [],
        isSaved: 1,
        campaignDetails: [],
        validateErrors: {},
        default_values: {},
        prevNodeEndDate: '',
      };
      if (temp.event) {
        res.general = temp.event;
      }
      if (temp.managedFiles) {
        res.files = temp.managedFiles;
      }
      if (temp.tags) {
        res.tags = temp.tags;
      }
      if (temp.canvasCustomTag) {
        res.canvasCustomTag = temp.canvasCustomTag;
      }
      if (action.prevNodeEndDate?.endDate) {
        res.prevNodeEndDate = action.prevNodeEndDate?.endDate;
      }

      if (temp.createdBy) {
        res.createdBy = temp.createdBy.id;
      }

      return {
        ...state,
        requestInProgress: false,
        eventDraft: { ...state.eventDraft, ...res },
        eventLoaded: true,
        isSentForReview: !action.payload.status.userEditable,
        formStatus: action.payload.status,
        stepDisplayId: action.payload.stepDisplayId,
      };
    case LOAD_EVENT_FAILURE: // handle error
      return { ...state, requestInProgress: false };
    case DELETE_FILE_FAILURE: // handle error
      return { ...state, requestInProgress: false };
    case DELETE_FILE_SUCCESS:
      return { ...state, requestInProgress: false };
    case CREATE_EVENT_SUCCESS:
      return {
        ...state,
        requestInProgress: false,
        eventLoaded: false,
      };
    case LOAD_EMAIL_USED_TECHNOLOGY_SUCCESS:
      return {
        ...state,
        requestInProgress: false,
        emailUsedTechnology: action.payload,
        emailUsedTechnologyLoaded: true,
      };
    case LOAD_EMAIL_USED_TECHNOLOGY_FAILURE: // handle error
      return { ...state, requestInProgress: false };
    case UPDATE_EVENT_DRAFT:
      return { ...state, eventDraft: action.payload, validateErrors: {} };
    case RESET_EVENTS:
      return initialState;
    case NAVIGATE_STEP:
      return { ...state, activeStep: action.step };
    case SEND_FOR_REVIEW_SUCCESS:
      return { ...state, isSentForReview: true, eventLoaded: false };
    case SEND_FOR_REVIEW_FAILURE: // handle error
      return { ...state, eventLoaded: false };
    case LOAD_EVENT_TAGS_LIST:
      return {
        ...state,
        tagsListData: action.payload,
      };
    case GET_EVENT_DETAILS:
      return {
        ...state,
        eventDetails: {
          data: {},
          status: API_STATUS_CONSTANTS.loading,
        },
      };
    case SET_EVENT_DETAILS:
      if (action.payload.status && !action.payload.data.err) {
        const newDataObj = getEventMappedData(action.payload.data);
        return {
          ...state,
          eventDetails: {
            data: action.payload.data,
            status: action.payload.status,
          },
          eventDraft: {
            ...state.eventDraft,
            general: { ...state.eventDraft.general, ...newDataObj },
          },
        };
      } else {
        return {
          ...state,
          eventDetails: {
            data: action.payload.data,
            status: action.payload.status,
          },
        };
      }
    case LOAD_EVENT_TAGS_LIST_FAILURE:
    default:
      return state;
  }
}

const getEventMappedData = data => {
  const meetingStyle = { 'Face to Face': 'face-to-face', Virtual: 'virtual', Mixed: 'mixed', Hybrid: 'mixed' };

  return {
    objective: data.EVENT_DESCRIPTION,
    name: data.EVENT_NAME,
    meetingStyle: meetingStyle[data.MEETING_STYLE],
    attendees: data.EVENT_EXPECTED_ATTENDEES_NUMBER,
    startDate: data.START_DATE || '',
    endDate: data.END_DATE || '',
  };
};
