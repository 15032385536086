import axios from 'axios';
import { authApi } from '../constants/api-constants';
import { REFRESH_TOKEN_FAILURE, REFRESH_TOKEN_SUCCESS } from './authTypes';
export class AuthService {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }
  async authorize() {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get('code')) {
      return;
    }
    
    window.location.href = `${authApi}/oauth`;
  }

  async fetchAccessToken() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const oldCode = localStorage.getItem('code');
    if (oldCode && oldCode === code) {
      localStorage.removeItem('code');
      window.location = window.location.href.split('?')[0];
      return;
    }
    if (code) {
      localStorage.setItem('code', code);
      const res = await axios
        .post(`${authApi}/token`, { code: urlParams.get('code') })
        .catch(() => {
          window.location = window.history.replaceState({}, document.title, '/');
        })
        .finally(() => window.history.replaceState({}, document.title, '/'));

      return {
        accessToken: res.data.access_token,
        refreshToken: res.data.refresh_token,
      };
    }
  }

  getAccessToken() {
    try {
      const persisted = JSON.parse(localStorage.getItem('persist:orchestra'));
      return JSON.parse(persisted.authentication).accessToken ?? null;
    } catch (error) {
      return null;
    }
  }

  getRefreshToken() {
    try {
      const persisted = JSON.parse(localStorage.getItem('persist:orchestra'));
      return JSON.parse(persisted.authentication).refreshToken ?? null;
    } catch (error) {
      return null;
    }
  }

  async getProfile(accessToken) { 
    if (accessToken) {
      return (
        await axios.get(`${authApi}/profile`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
      ).data;
    }
    return (await axios.get(`${authApi}/profile`)).data;
  }

  async refreshToken() {
    try {
      const res = await axios.post(`${authApi}/refresh`, {
        refreshToken: this.getRefreshToken(),
      });
      this.dispatch({
        type: REFRESH_TOKEN_SUCCESS,
        payload: {
          accessToken: res.data.access_token,
          refreshToken: res.data.refresh_token,
        },
      });
      return {
        accessToken: res.data.access_token,
        refreshToken: res.data.refresh_token,
      };
    } catch (error) {
      this.dispatch({
        type: REFRESH_TOKEN_FAILURE,
        payload: error,
      });
      this.logout();
    }
  }

  logout() {
    localStorage.clear();
  }

  checkLocalStorageVersion() {
    const currentVersion = '1.0';
    const storedVersion = localStorage.getItem('version');

    if (storedVersion !== currentVersion) {
      localStorage.clear();
      localStorage.setItem('version', currentVersion);

      return true;
    }

    return false;
  }
}
