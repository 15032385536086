import {
  RESET_CREATE_CAMPAIGN,
  LOAD_CAMPAIGN_TYPE,
  LOAD_CAMPAIGN_TYPE_FAILURE,
  LOAD_CAMPAIGN_TYPE_SUCCESS,
  LOAD_CAMPAIGN_STATUS_SUCCESS,
  UPDATE_CAMPAIGN_DRAFT,
  UPDATE_STEP,
  INCREASE_STEP,
  DECREASE_STEP,
  LOAD_SUBJECT,
  LOAD_SUBJECT_FAILURE,
  LOAD_SUBJECT_SUCCESS,
  UPDATE_CAMPAIGN_TYPE,
  VALIDATE_NAME,
  VALIDATE_NAME_SUCCESS,
  VALIDATE_NAME_FAILURE,
  CREATE_CAMPAIGN,
  CREATE_CAMPAIGN_FAILURE,
  CREATE_CAMPAIGN_SUCCESS,
  GET_DISPLAY_TAGS_LIST,
  SET_DISPLAY_TAGS_LIST,
  VALIDATE_MANDATORY_TAGS
} from './types';
import { API_STATUS_CONSTANTS } from '../../app/constants/form-constants';

const initialState = {
  requestInProgress: false,
  nameValidationInProgress:false,
  activeStep: 0,
  stepCount: 0,
  campaignTypeCollection: [],
  campaignStatuses: [],
  campaignTypeLoaded: false,
  subjectCollection: [],
  subjectLoaded: false,
  campaignDraft: {
    countrySelected: '',
    launchDate: new Date(),
    name: '',
    tags: [],
  },
  displayTagsList: {
    data: [],
    status: API_STATUS_CONSTANTS.init,
  },
  validateErrors: {},
};

export function campaignCreateReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_CAMPAIGN_TYPE:
      return { ...state, requestInProgress: true };
    case LOAD_CAMPAIGN_TYPE_SUCCESS:
      return {
        ...state,
        requestInProgress: false,
        campaignTypeCollection: action.payload,
        campaignTypeLoaded: true,
      };
    case LOAD_CAMPAIGN_STATUS_SUCCESS:
      return {
        ...state,
        requestInProgress: false,
        campaignStatuses: action.payload,
        campaignTypeLoaded: true,
      };
    case LOAD_CAMPAIGN_TYPE_FAILURE: // handle error
      return { ...state, requestInProgress: false };
    case UPDATE_CAMPAIGN_TYPE:
      return { ...state, subjectLoaded: false, subjectCollection: [] };
    case LOAD_SUBJECT:
      return { ...state, requestInProgress: true };
    case LOAD_SUBJECT_SUCCESS:
      return {
        ...state,
        requestInProgress: false,
        subjectCollection: action.payload,
        subjectLoaded: true,
      };
    case LOAD_SUBJECT_FAILURE:
      return { ...state, requestInProgress: false };
    case VALIDATE_NAME:
      return { ...state, nameValidationInProgress: true };
    case VALIDATE_NAME_SUCCESS:
      return {
        ...state,
        nameValidationInProgress: false,
        validateErrors: { ...state.validateErrors, name: action.payload },
      };
    case VALIDATE_NAME_FAILURE:
      return {
        ...state,
        nameValidationInProgress: false,
      };
    case VALIDATE_MANDATORY_TAGS:
      return {
        ...state,
        validateErrors: { ...state.validateErrors, tags: action.payload }
      }
    case CREATE_CAMPAIGN:
      return { ...state, requestInProgress: true };
    case CREATE_CAMPAIGN_SUCCESS:
      return { ...state, requestInProgress: false };
    case CREATE_CAMPAIGN_FAILURE:
      return { ...state, requestInProgress: false };
    case UPDATE_CAMPAIGN_DRAFT:
      return { ...state, campaignDraft: action.payload };
    case UPDATE_STEP:
      return { ...state, activeStep: 1, stepCount: 1 };
    case INCREASE_STEP:
      return { ...state, activeStep: state.activeStep + 1 };
    case DECREASE_STEP:
      return { ...state, activeStep: state.activeStep - 1 };
    case GET_DISPLAY_TAGS_LIST:
      return {
        ...state,
        displayTagsList: {
          data: [],
          status: API_STATUS_CONSTANTS.loading,
        },
      };
    case SET_DISPLAY_TAGS_LIST:
      return {
        ...state,
        displayTagsList: {
          data: action.payload.data,
          status: action.payload.status,
        },
      };
    case RESET_CREATE_CAMPAIGN:
      return initialState;
    default:
      return state;
  }
}
