import {
  LOAD_CAMPAIGN_LIST,
  LOAD_CAMPAIGN_LIST_FAILURE,
  LOAD_CAMPAIGN_LIST_SUCCESS,
  UPDATE_SELECTED_CAMPAIGN,
} from './types';

const initialState = {
  requestInProgress: false,
  activeStep: 0,
  campaignListCollection: [],
  campaignListLoaded: false,
  selectedType: null,
  selectedCampaign: 'allcampaign',
};

export function campaignListingAllReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_CAMPAIGN_LIST:
      return { ...state, requestInProgress: true };
    case LOAD_CAMPAIGN_LIST_SUCCESS:
      return {
        ...state,
        requestInProgress: false,
        campaignListCollection: action.payload?.map((campaign) => {
          let teamsList = campaign.CAMPAIGN_TEAM?.split(',');
          teamsList = teamsList?.map((ele) => {
            const member = ele.split(':');
            return { wwid: member[0], name: member[1] };
          });
          return { ...campaign, CAMPAIGN_TEAM: teamsList || [] };
        }),
        campaignListLoaded: true,
      };
    case LOAD_CAMPAIGN_LIST_FAILURE: // handle error
      return { ...state, requestInProgress: false };
    case UPDATE_SELECTED_CAMPAIGN:
      // const index = state.campaignListCollection.findIndex(
      //   (campaign) => campaign.id === action.payload.id,
      // );
      // let newList = [...state.campaignListCollection];
      // if (index > -1) {
      //   newList[index] = action.payload;
      // } else {
      //   newList = [action.payload, ...newList];
      // }
      // return { ...state, campaignListCollection: newList, selectedCampaign: action.payload };
      return { ...state, selectedCampaign: action.payload };
    default:
      return state;
  }
}
