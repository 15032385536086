import { State } from "../default"

const commonState = {
    pending: false,
    error: false,
    fullfilled: false
}

export const initialState = {
    profile: {
        data: [],
        ...commonState
    },
    country: {
        data: [],
        ...commonState
    },
    team: {
        data: [],
        ...commonState
    }
}

interface SubFeatureState extends State {
    data: any[]
}

export interface FeatureState {
    profile: SubFeatureState
    country: SubFeatureState
    team: SubFeatureState
}