import { CircularProgress } from '@mui/material';
import { green } from '@mui/material/colors';
import { BriefRequest } from '../../new-store/brief-requests/types';
import { Brief } from '../../new-store/briefs/types';
import { Product } from '../../new-store/products/types';
import { Therapeutic } from '../../new-store/therapeutics/types';

export const buildSwitchEditorUrl = (pageId: number) => {
  const digitCount = `${pageId}`.length;
  const displayId = 'SWP' + '0'.repeat(7 - digitCount) + pageId;

  return `/switch/website/page/${displayId}`;
};

export const slugify = (value: string) =>
  value
    ?.toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

export const haveRequestInProgress = (briefRequests: BriefRequest[]): boolean => {
  if (briefRequests.length === 0) {
    return false;
  }

  const item = briefRequests.find((briefRequest: BriefRequest) => {
    if ([1, 16].includes(briefRequest.status_id || 0)) {
      return false;
    }

    return true;
  });

  return !!item;
};

export const getProductIdFromBrief = (brief: Brief) => {
  if (`${brief.website_type_id}` === '0') {
    return brief.product_id ? parseInt(`${brief.product_id}`, 10) : 0;
  }

  return 0;
};

export const getTherapeuticIdFromBrief = (brief: Brief) => {
  if (`${brief.website_type_id}` === '1') {
    return brief.therapeutic_id ? parseInt(`${brief.therapeutic_id}`, 10) : 0;
  }

  return 0;
};

export const getBriefById = (briefList: Brief[], id: number): Brief => {
  return briefList.find((brief: Brief) => brief.id === id) || ({} as Brief);
};

export const getBriefRequestById = (briefRequestList: BriefRequest[], id: number): BriefRequest => {
  return (
    briefRequestList.find((briefRequest: BriefRequest) => briefRequest.id === id) ||
    ({} as BriefRequest)
  );
};

type entity = {
  id: number;
};

export function getEntityById<T extends entity>(entityList: T[], id: number | undefined): T {
  return entityList.find((entity: T) => entity.id === id) || ({} as T);
}

export const CircularLoader = () => (
  <CircularProgress
    color="success"
    size={24}
    sx={{
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: '-12px',
      marginLeft: '-12px',
    }}
  />
);

export const getPreviewEnvironmentUrl = (websiteType: string) => {
  switch (websiteType) {
    case '1':
      return 'https://www.preview.janssenmedicaments.fr';

    default:
      return 'https://www.preview.janssenmedicalcloud.fr';
  }
};

export const formatRequestType = (value: string) => {
  switch (value) {
    case 'U':
      return 'Update';
    case 'D':
      return 'Delation';
    case 'C':
      return 'Creation';
    default:
      return 'Unknown';
  }
};

export const getCategoryName = (
  brief: Brief,
  therapeuticList: Therapeutic[],
  productList: Product[],
): string => {
  if (`${brief.website_type_id}` === '1') {
    const record = getEntityById<Therapeutic>(therapeuticList, brief.therapeutic_id);

    if (record) return record.urlValue || record.name;
  } else {
    const record: Product = getEntityById<Product>(productList, brief.product_id);

    if (record) return record.name;
  }

  return 'no-therapeutic-area';
};

export const getFakeTemplates = () => {
  return [
    {
      id: 9998,
      name: 'Template Figma',
      description: 'template figma',
      linkedToEvent: false,
      type: 'jmc',
    },
    { id: 9999, name: 'Other', description: 'template figma', linkedToEvent: false, type: 'jmc' },
  ];
};

export const inputLabelSX = () => {
  return {
    color: '#2D3748',
    fontSize: '16px',
  };
};

export const allowedCommentAttachmentTypes = [
  'text/csv',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'image/tiff',
  'image/webp',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

export enum BriefRequestStatuses {
  DRAFT = 'Draft',
  MISSING_INFORMATION = 'Missing information',
  READY_FOR_PAGE_DESIGN = 'Ready for page design',
  DESIGN_IN_PROGRESS = 'Design in progress',
  READY_FOR_BUSINESS_APPROVAL = 'Ready for Business Approval',
  LAYOUT_UPDATES_REQUESTED = 'Layout update requested by Business',
  READY_FOR_REGULATORY_APPROVAL = 'Ready for Regulatory Approval',
  REGULATORY_UPDATES_REQUESTED = 'Regulatory updates requested',
  READY_FOR_INTEGRATION = 'Ready for integration (CMS)',
  READY_FOR_QA = 'Ready for QA release',
  PAGE_UPDATES_REQUESTED = 'Page update requested by Business',
  READY_FOR_PRODUCTION = 'Ready for production release',
  RELEASED = 'Released',
  COMPLETED = 'Completed',
  CANCELLED = 'Request cancelled',
}

export const readOnlyStatuses: string[] = [
  BriefRequestStatuses.RELEASED,
  BriefRequestStatuses.COMPLETED,
  BriefRequestStatuses.CANCELLED
];

export const previewStatuses: string[] = [
  BriefRequestStatuses.READY_FOR_QA,
  BriefRequestStatuses.PAGE_UPDATES_REQUESTED,
  BriefRequestStatuses.READY_FOR_PRODUCTION,
  BriefRequestStatuses.RELEASED,
  BriefRequestStatuses.COMPLETED
];

export const liveStatuses: string[] = [
  BriefRequestStatuses.RELEASED,
  BriefRequestStatuses.COMPLETED
];

export const integrationPhaseStatuses: string[] = [
  BriefRequestStatuses.READY_FOR_INTEGRATION,
  BriefRequestStatuses.READY_FOR_QA,
  BriefRequestStatuses.PAGE_UPDATES_REQUESTED,
  BriefRequestStatuses.READY_FOR_PRODUCTION,
  BriefRequestStatuses.RELEASED
];

export const approvalStatuses: string[] = [
  BriefRequestStatuses.READY_FOR_BUSINESS_APPROVAL,
  BriefRequestStatuses.READY_FOR_REGULATORY_APPROVAL
];

export const uploadReminderStatuses: string[] = [
  BriefRequestStatuses.READY_FOR_PAGE_DESIGN,
  BriefRequestStatuses.READY_FOR_INTEGRATION
];
