import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '../default';

import { getWebsiteStatusList } from './actions';
import { WebsiteStatusState } from './types';

export default createReducer(initialState as WebsiteStatusState, builder => {
  builder

    .addCase(getWebsiteStatusList.pending, state => {
      state.pending = true;
    })
    .addCase(getWebsiteStatusList.fulfilled, (state, { payload }) => {
      state.pending = false;
      state.fullfilled = true;
      state.data = payload;
    })
    .addCase(getWebsiteStatusList.rejected, state => {
      state.pending = false;
      state.error = true;
    });
});
