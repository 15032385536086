import { createReducer } from "@reduxjs/toolkit";
import { initialState, CloneCampaignState } from './types';
import { clearCloneCampaignState, cloneCampaign, getCampaign, getCampaignTypeList, getProductList, getTags, getTargetBookmarks } from "./actions";

export default createReducer(initialState as CloneCampaignState, builder => {
    builder
        .addCase(clearCloneCampaignState, state => {
            state.campaign = initialState.campaign;
            state.campaignTypes = initialState.campaignTypes;
            state.products = initialState.products;
            state.targetBookmarks = initialState.targetBookmarks;
            state.tags = initialState.tags;
            state.form = initialState.form;
        })

        .addCase(getCampaign.pending, state => {
            state.campaign.pending = true;
        })
        .addCase(getCampaign.fulfilled, (state, { payload }) => {
            state.campaign.pending = false;
            state.campaign.fullfilled = true;
            state.campaign.data = payload;
        })
        .addCase(getCampaign.rejected, state => {
            state.campaignTypes.pending = false;
            state.campaignTypes.error = true;
        })

        .addCase(getCampaignTypeList.pending, state => {
            state.campaignTypes.pending = true;
        })
        .addCase(getCampaignTypeList.fulfilled, (state, { payload }) => {
            state.campaignTypes.pending = false;
            state.campaignTypes.fullfilled = true;
            state.campaignTypes.data = payload;
        })
        .addCase(getCampaignTypeList.rejected, state => {
            state.campaignTypes.pending = false;
            state.campaignTypes.error = true;
        })

        .addCase(getProductList.pending, state => {
            state.products.pending = true;
        })
        .addCase(getProductList.fulfilled, (state, { payload }) => {
            state.products.pending = false;
            state.products.fullfilled = true;
            state.products.data = payload.Product;
        })
        .addCase(getProductList.rejected, state => {
            state.products.pending = false;
            state.products.error = true;
        })

        .addCase(getTargetBookmarks.pending, state => {
            state.targetBookmarks.pending = true;
        })
        .addCase(getTargetBookmarks.fulfilled, (state, { payload }) => {
            state.targetBookmarks.pending = false;
            state.targetBookmarks.fullfilled = true;
            state.targetBookmarks.data = payload;
        })
        .addCase(getTargetBookmarks.rejected, state => {
            state.targetBookmarks.pending = false;
            state.targetBookmarks.error = true;
        })

        .addCase(getTags.pending, state => {
            state.tags.pending = true;
        })
        .addCase(getTags.fulfilled, (state, { payload }) => {
            state.tags.pending = false;
            state.tags.fullfilled = true;
            state.tags.data = payload;
        })
        .addCase(getTags.rejected, state => {
            state.tags.pending = false;
            state.tags.error = true;
        })

        .addCase(cloneCampaign.pending, state => {
            state.form.pending = true;
        })
        .addCase(cloneCampaign.fulfilled, (state, { payload }) => {
            state.form.pending = false;
            state.form.error = false;
            state.form.fullfilled = true;
            state.form.errorMessage = null;
        })
        .addCase(cloneCampaign.rejected, (state, { payload }: any) => {
            state.form.pending = false;
            state.form.error = true;
            state.form.errorMessage = payload.message;
        })
});