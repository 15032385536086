import {
  LOAD_USER_NOTIFICATION_SUCCESS,
  LOAD_USER_NOTIFICATION_FAILURE,
  UPDATED_USER_NOTIFICATION_FAILURE,
  SEND_FOR_REVIEW_NOTIFICATION_CREATE_SUCCESS,
  SEND_FOR_REVIEW_NOTIFICATION_CREATE_FAILURE,
} from './types';

const initialState = {
  unreadNotificationCount: '',
  userNotifications: [],
  userNotificationsLoaded: false,
};

export const UserNotificationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_USER_NOTIFICATION_SUCCESS:
      return {
        unreadNotificationCount: payload.unReadNotificationCount,
        userNotifications: payload.notifications,
        userNotificationsLoaded: true,
      };
    case LOAD_USER_NOTIFICATION_FAILURE:
    case UPDATED_USER_NOTIFICATION_FAILURE:
    case SEND_FOR_REVIEW_NOTIFICATION_CREATE_SUCCESS:
    case SEND_FOR_REVIEW_NOTIFICATION_CREATE_FAILURE:
      return state;
    default:
      return state;
  }
};
