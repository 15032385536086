import axios from '../../api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { emailer } from '../../app/constants/api-constants';
import { INotificationMailData } from './types';

export const getBriefCommentsList = createAsyncThunk(
  'briefCommentsList',
  async (payload: { briefRequestId: number }) => {
    const response = await axios.get(
      `/switch/briefRequestComments/byBriefRequest/${payload.briefRequestId}`,
    );
    return response.data;
  },
);

export const createComment = createAsyncThunk(
  'createComment',
  async (payload: { briefRequestId: number; content: string, recipients?: string[], attachment?: { fileKey: string } }) => {
    const response = await axios.post(
      `/switch/briefRequestComments/byBriefRequest/${payload.briefRequestId}`,
      { content: payload.content, recipients: payload.recipients, attachment: payload.attachment },
    );
    return response.data;
  },
);

export async function sendNotificationMail(data: INotificationMailData) {
  try {
    await axios.post(`/emailer/send-comments-notification`, data);
  } catch (error) {
    console.log('sending email notification failed', error);
  }
}