import axios from 'axios'; 
import { profile } from '../../app/constants/api-constants'; 
import { LOAD_PROFILES } from './types'

export const getProfiles = () => async (dispatch) => {
  try {
    const wProfiles = await axios.get(`${profile}/get-all-profiles`);
    dispatch ({ type: LOAD_PROFILES, payload: wProfiles.data });  
  } catch (error) {
    console.log(error);
  }
};
