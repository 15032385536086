import { isString } from 'lodash';

function normalize(str) {
  if(isString(str))
    return str.normalize("NFD").replace(/\p{Diacritic}/gu, "");
  return '';
}

export function extractTextContentToCheck(variable) {
  switch(variable?.definition?.type) {
    case 'html':
    case 'string':
      return normalize(variable.value);
    case 'link':
      return normalize(variable.value?.buttonText);
    default:
      return null;
  }
}

export function convertWordsList(list) {
  return list.map((word) => ({
    label: word,
    check: new RegExp(`\\b${normalize(word).toLowerCase()}\\b`, 'i'),
  }));
}