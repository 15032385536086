import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '../default';

import { createBriefRequest, deleteBriefRequest, getBriefRequestList, handleBriefRequestStatusAutomation, updateBriefRequest } from './actions';
import { BriefRequestState, BriefRequest } from './types';

export default createReducer({ ...initialState, brief_id: 0 } as BriefRequestState, builder => {
  builder

    // BriefRequest Listing
    .addCase(getBriefRequestList.pending, state => {
      state.pending = true;
    })
    .addCase(getBriefRequestList.fulfilled, (state, { payload }) => {
      state.pending = false;
      state.fullfilled = true;
      state.data = payload;
      if (payload.length > 0) {
        state.brief_id = payload[0].brief_id;
      }
    })
    .addCase(getBriefRequestList.rejected, state => {
      state.pending = false;
      state.error = true;
    })

    // BriefRequest Creation
    .addCase(createBriefRequest.pending, state => {
      state.pending = true;
    })
    .addCase(createBriefRequest.fulfilled, (state, { payload }) => {
      state.pending = false;
      state.data = state.data.concat(payload);
    })
    .addCase(createBriefRequest.rejected, state => {
      state.pending = false;
      state.error = true;
    })

    // BriefRequest Update
    .addCase(updateBriefRequest.pending, state => {
      state.pending = true;
    })
    .addCase(updateBriefRequest.fulfilled, (state, { payload }) => {
      state.pending = false;
      const dataWithoutUpdatedRecord = state.data.filter((briefRequest: BriefRequest) => briefRequest.id !== payload.id);
      state.data = dataWithoutUpdatedRecord.concat(payload);
    })
    .addCase(updateBriefRequest.rejected, state => {
      state.pending = false;
      state.error = true;
    })

    // BriefRequest Deletion
    .addCase(deleteBriefRequest.pending, state => {
      state.pending = true;
    })
    .addCase(deleteBriefRequest.fulfilled, (state, { payload }) => {
      state.pending = false;
      state.data = state.data.filter((briefRequest: BriefRequest) => briefRequest.id !== payload.id);
    })
    .addCase(deleteBriefRequest.rejected, state => {
      state.pending = false;
      state.error = true;
    })

    // BriefRequest Status Automation
    .addCase(handleBriefRequestStatusAutomation.pending, state => {
      state.pending = true;
    })
    .addCase(handleBriefRequestStatusAutomation.fulfilled, (state, { payload }) => {
      state.pending = false;
      const dataWithoutUpdatedRecord = state.data.filter((briefRequest: BriefRequest) => briefRequest.id !== payload.id);
      state.data = dataWithoutUpdatedRecord.concat(payload);
    })
    .addCase(handleBriefRequestStatusAutomation.rejected, state => {
      state.pending = false;
      state.error = true;
    });

});
