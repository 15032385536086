type ValidationErrorProps = {
    message: string;
}

const ValidationError = ({ message }: ValidationErrorProps) => {
    return (
        <div style={{ color: '#d32f2f', fontSize: '0.75rem', paddingTop: '4px' }}>{message}</div>
    )
}

export default ValidationError;