import {
  LOAD_CALL_SUCCESS,
  LOAD_CALL_FAILURE,
  NAVIGATE_STEP_CALLS,
  UPDATE_CALL_DRAFT,
  CREATE_CALL_SUCCESS,
  SEND_FOR_REVIEW_SUCCESS,
  SEND_FOR_REVIEW_FAILURE,
  RESET_CALLS,
  LOAD_CALLS_TAGS_LIST,
  LOAD_CALLS_TAGS_LIST_FAILURE,
  LOAD_PARENT_CALLS_SUCCESS,
  LOAD_KEY_MESSAGES_SUCCESS,
  LOAD_PARENT_CALLS_FAILED,
  LOAD_KEY_MESSAGES_FAILED,
} from './types';

const initialState = {
  requestInProgress: false,
  activeStep: 0,
  callEditDataLoaded: false,
  callDraft: {
    stepId: -1,
    general: {},
    tags: [],
    canvasCustomTag: [],
    isSaved: 1,
    campaignDetails: [],
    validateErrors: {},
    missingFieldErrors: {},
    prevNodeEndDate: '',
    createdBy: '',
    isCustomerRequestsEdited: 0,
    parentCalls: [],
    keyMessages: []
  },
  validateErrors: {},
  isSentForReview: false,
  formStatus: {},
  stepDisplayId: '',
  tagsListData: [],
  parentCallsData: [],
  parentCallsDataLoaded: false,
  keyMessagesData: [],
  keyMessagesDataLoaded: false
};

export function callCreateReducer(state = initialState, action) {
  let res = [];
  switch (action.type) {
    case LOAD_CALL_SUCCESS:
      res = loadCallData(action.payload, action.stepId, action.prevNodeEndDate);
      return {
        ...state,
        requestInProgress: false,
        callDraft: { ...state.callDraft, ...res },
        callEditDataLoaded: true,
        isSentForReview: !action.payload.status.userEditable,
        formStatus: action.payload.status,
        stepDisplayId: action.payload.stepDisplayId,
      };
    case LOAD_CALL_FAILURE: // handle error
      return { ...state, requestInProgress: false };
    case UPDATE_CALL_DRAFT:
      return {
        ...state,
        callDraft: action.payload,
        validateErrors: {},
      };
    case NAVIGATE_STEP_CALLS:
      return { ...state, activeStep: action.step };
    case RESET_CALLS:
      return initialState;
    case CREATE_CALL_SUCCESS:
      return {
        ...state,
        requestInProgress: false,
        callEditDataLoaded: false,
      };
    case SEND_FOR_REVIEW_SUCCESS:
      return { ...state, isSentForReview: true, callEditDataLoaded: false };
    case SEND_FOR_REVIEW_FAILURE: // handle error
      return { ...state, callEditDataLoaded: false };
    case LOAD_CALLS_TAGS_LIST:
      return {
        ...state,
        tagsListData: action.payload,
      };
    case LOAD_PARENT_CALLS_SUCCESS:
      return { ...state, parentCallsData: action.payload, parentCallsDataLoaded: true }
    case LOAD_KEY_MESSAGES_SUCCESS:
      return { ...state, keyMessagesData: action.payload, keyMessagesDataLoaded: true }
    case LOAD_PARENT_CALLS_FAILED:
    case LOAD_KEY_MESSAGES_FAILED:
    case LOAD_CALLS_TAGS_LIST_FAILURE:
    default:
      return state;
  }
}

export function loadCallData(payload, stepId, prevNodeEndDate) {
  const temp = payload;
  const res = {
    stepId: stepId,
    general: {},
    tags: [],
    canvasCustomTag: [],
    isSaved: 1,
    campaignDetails: [],
    validateErrors: {},
    default_values: {},
    prevNodeEndDate: '',
    parentCalls: [],
    keyMessages: []
  };

  if (temp.parentCalls) {
    res.parentCalls = temp.parentCalls;
  }

  if (temp.keyMessages) {
    res.keyMessages = temp.keyMessages;
  }

  if (temp.calls) {
    res.general = temp.calls;
  }
  if (temp.tags) {
    res.tags = temp.tags;
  }
  if (temp.canvasCustomTag) {
    res.canvasCustomTag = temp.canvasCustomTag;
  }
  if (prevNodeEndDate?.endDate) {
    res.prevNodeEndDate = prevNodeEndDate.endDate;
  }
  return res;
}
