import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '../default';

import { getBriefCommentsList, createComment } from './actions';
import { BriefCommentsState } from './types';

export default createReducer(initialState as BriefCommentsState, builder => {
  builder

    // Brief comments Listing
    .addCase(getBriefCommentsList.pending, state => {
      state.pending = true;
    })
    .addCase(getBriefCommentsList.fulfilled, (state, { payload }) => {
      state.pending = false;
      state.data = payload;
      state.fullfilled = true;
    })
    .addCase(getBriefCommentsList.rejected, state => {
      state.pending = false;
      state.error = true;
    })

    // Brief comments Creation
    .addCase(createComment.pending, state => {
      state.pending = true;
    })
    .addCase(createComment.fulfilled, (state, { payload }) => {
      state.pending = false;
      state.data = [...state.data, payload];
    })
    .addCase(createComment.rejected, state => {
      state.pending = false;
      state.error = true;
    });
});
