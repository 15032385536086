import { ENQUEUE_NOTIFICATION, REMOVE_NOTIFICATION } from './types';

const initialState = {
  notifications: [],
};

export function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case ENQUEUE_NOTIFICATION:
      return { ...state, notifications: [...state.notifications, { ...action.payload }] };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter((el) => el.key !== action.payload),
      };
    default:
      return state;
  }
}
