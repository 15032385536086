import { LOAD_ADMIN_LIST } from './types';

const initialState = {
  campaignsList: [],
};

export function AdminListReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_ADMIN_LIST:
      return {
        ...state,
        campaignsList: action.payload?.map((nodeList) => {
          let teamsList = nodeList.TEAM?.split(',');
          teamsList = teamsList?.map((ele) => {
            const member = ele.split(':');
            return { wwid: member[0], name: member[1] };
          });
          //if assign to is not empty then add the entry to teams list
          if (nodeList?.STEP_ASSIGNED_TO && nodeList?.STEP_ASSIGNED_TO_WWID) {
            teamsList = [
              ...teamsList,
              { wwid: `${nodeList?.STEP_ASSIGNED_TO_WWID}`, name: nodeList?.STEP_ASSIGNED_TO },
            ];
          }
          return { ...nodeList, TEAM: teamsList || [] };
        }),
      };
    default:
      return state;
  }
}
