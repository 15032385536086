import { customTagsMapping, tagsMapping } from '../data-mapping';
import {
  LOAD_EMAIL_TEMPLATES_FAILURE_FRAGMENT,
  LOAD_EMAIL_TEMPLATES_SUCCESS_FRAGMENT,
  LOAD_EMAIL_USED_TECHNOLOGY_SUCCESS,
  LOAD_EMAIL_USED_TECHNOLOGY_FAILURE,
  LOAD_FRAGMENT_FAILURE,
  LOAD_FRAGMENT_SUCCESS,
  LOAD_CTA_LABELS_SUCCESS,
  LOAD_CTA_LABELS_FAILURE,
  RESET_FRAGMENT,
  NAVIGATE_STEP,
  UPDATE_FRAGMENT_DRAFT,
  SEND_FOR_REVIEW_SUCCESS,
  SEND_FOR_REVIEW_FAILURE,
  CREATE_FRAGMENT_SUCCESS,
  LOAD_FRAGMENT_TAGS_LIST,
  LOAD_FRAGMENT_TAGS_LIST_FAILURE,
  SET_MESSAGE_DETAILS,
  SET_CHANNEL_DETAILS,
} from './types';

const initialState = {
  requestInProgress: false,
  activeStep: 0,
  emailEditDataLoaded: false,
  fragmentDraft: {
    stepId: -1,
    general: {},
    cta: [],
    files: [],
    filesId: [],
    tags: [],
    canvasCustomTag: [],
    isSaved: 1,
    campaignDetails: [],
    validateErrors: {},
    switchEmailId: null,
  },
  emailTemplates: [],
  validateErrors: {},
  isSentForReview: false,
  formStatus: {},
  stepDisplayId: '',
  tagsListData: [],
  teamsIntegrationDetails: {}
};

export function fragmentCreateReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_EMAIL_TEMPLATES_SUCCESS_FRAGMENT:
      let templ = [];

      for (let l = 0; l < action.payload.length; l++) {
        let tempObg = {
          email_node_node_id: action.payload[l].id,
          name: action.payload[l].name,
          tags: tagsMapping(action.payload[l].tags),
          canvasCustomTag: customTagsMapping(action.payload[l].tags),
          mailFormId: action.payload[l].id,
          startDate: action.payload[l].startDate,
          endDate: action.payload[l].endDate,
          managedFiles: action.payload[l].attachments,
          templateData: {
            name: action.payload[l].name,
            imrNumber: action.payload[l].payload?.imr_number,
            subtype: action.payload[l].emailSubtype,
          },
          statusId: action.payload[l].status?.id,
          status: action.payload[l].status,
        };
        templ.push(tempObg);
      }
      return {
        ...state,
        requestInProgress: false,
        emailTemplates: templ,
        emailTemplateLoaded: true,
      };
    case LOAD_EMAIL_TEMPLATES_FAILURE_FRAGMENT: // handle error
      return { ...state, requestInProgress: false };
    case LOAD_CTA_LABELS_SUCCESS:
      let res_arr = [];
      if (action.payload) {
        res_arr = action.payload;
      }
      return {
        ...state,
        requestInProgress: false,
        ctaLabels: res_arr,
        ctaLabelsLoaded: true,
      };
    case LOAD_CTA_LABELS_FAILURE: // handle error
      return { ...state, requestInProgress: false };
    case CREATE_FRAGMENT_SUCCESS:
      return {
        ...state,
        requestInProgress: false,
        emailEditDataLoaded: false,
      };
    case LOAD_FRAGMENT_SUCCESS:
      const temp = action.payload;
      let res = {
        stepId: action.fragmentId,
        general: {},
        cta: [],
        files: [],
        tags: [],
        canvasCustomTag: [],
        isSaved: 1,
        validateErrors: {},
        default_values: {},
        filesId: [],
        campaignDetails: [],
        switchEmailId: null,
      };

      if (temp.fragment) {
        res.general = temp.fragment;
      }
      if (temp.cta) {
        res.cta = temp.cta;
      }

      if (temp.managedFiles) {
        res.files = temp.managedFiles;
      }
      if (temp.tags) {
        res.tags = temp.tags;
      }
      if (temp.canvasCustomTag) {
        res.canvasCustomTag = temp.canvasCustomTag;
      }

      if (temp.fragment && temp.fragment.switchEmailId) {
        res.switchEmailId = temp.fragment.switchEmailId;
      }

      return {
        ...state,
        requestInProgress: false,
        fragmentDraft: res,
        emailEditDataLoaded: true,
        isSentForReview: !action.payload.status.userEditable,
        formStatus: action.payload.status,
        stepDisplayId: action.payload.stepDisplayId,
        teamsIntegrationDetails: action.payload.teamsIntegrationDetails,
      };
    case LOAD_FRAGMENT_FAILURE: // handle error
      return { ...state, requestInProgress: false };
    case LOAD_EMAIL_USED_TECHNOLOGY_SUCCESS:
      return {
        ...state,
        requestInProgress: false,
        emailUsedTechnology: action.payload,
        emailUsedTechnologyLoaded: true,
      };
    case LOAD_EMAIL_USED_TECHNOLOGY_FAILURE: // handle error
      return { ...state, requestInProgress: false };
    case UPDATE_FRAGMENT_DRAFT:
      return { ...state, fragmentDraft: action.payload, validateErrors: {} };
    case RESET_FRAGMENT:
      return {
        ...initialState,
        fragmentDraft: {
          stepId: -1,
          general: {},
          cta: [],
          files: [],
          filesId: [],
          tags: [],
          canvasCustomTag: [],
          isSaved: 1,
          campaignDetails: [],
          validateErrors: {},
        },
      };
    case NAVIGATE_STEP:
      return { ...state, activeStep: action.step };
    case SEND_FOR_REVIEW_SUCCESS:
      return { ...state, isSentForReview: true, emailEditDataLoaded: false };
    case SEND_FOR_REVIEW_FAILURE: // handle error
      return { ...state, emailEditDataLoaded: false };
    case LOAD_FRAGMENT_TAGS_LIST:
      return {
        ...state,
        tagsListData: action.payload,
      };
    case SET_MESSAGE_DETAILS:
      return {
        ...state,
        teamsIntegrationDetails: {
          ...state.teamsIntegrationDetails,
          messageDetails: action.payload
        }
      }
    case SET_CHANNEL_DETAILS:
      return {
        ...state,
        teamsIntegrationDetails: {
          ...state.teamsIntegrationDetails,
          channelDetails: action.payload
        }
      }
    case LOAD_FRAGMENT_TAGS_LIST_FAILURE:
    default:
      return state;
  }
}
