import {
  LOAD_SMS_SUCCESS,
  LOAD_SMS_FAILURE,
  NAVIGATE_STEP,
  UPDATE_SMS_DRAFT,
  DELETE_FILE_FAILURE,
  DELETE_FILE_SUCCESS,
  CREATE_SMS_SUCCESS,
  SEND_FOR_REVIEW_SUCCESS,
  SEND_FOR_REVIEW_FAILURE,
  RESET_SMS,
  LOAD_SMS_TAGS_LIST,
  LOAD_SMS_TAGS_LIST_FAILURE,
} from './types';

const initialState = {
  requestInProgress: false,
  activeStep: 0,
  smsEditDataLoaded: false,
  smsDraft: {
    stepId: -1,
    general: {},
    files: [],
    tags: [],
    canvasCustomTag: [],
    isSaved: 1,
    campaignDetails: [],
    validateErrors: {},
    prevNodeEndDate: '',
  },
  validateErrors: {},
  isSentForReview: false,
  formStatus: {},
  stepDisplayId: '',
  tagsListData: [],
};

export function smsCreateReducer(state = initialState, action) {
  let res = [];
  switch (action.type) {
    case LOAD_SMS_SUCCESS:
      res = loadSmsData(action.payload, action.stepId, action.prevNodeEndDate);
      return {
        ...state,
        requestInProgress: false,
        smsDraft: res,
        smsEditDataLoaded: true,
        isSentForReview: !action.payload.status.userEditable,
        formStatus: action.payload.status,
        stepDisplayId: action.payload.stepDisplayId,
      };
    case LOAD_SMS_FAILURE: // handle error
      return { ...state, requestInProgress: false };
    case DELETE_FILE_FAILURE: // handle error
      return { ...state, requestInProgress: false };
    case DELETE_FILE_SUCCESS:
      return { ...state, requestInProgress: false };
    case UPDATE_SMS_DRAFT:
      return { ...state, smsDraft: action.payload, validateErrors: {} };
    case NAVIGATE_STEP:
      return { ...state, activeStep: action.step };
    case RESET_SMS:
      return initialState;
    case CREATE_SMS_SUCCESS:
      return {
        ...state,
        requestInProgress: false,
        smsEditDataLoaded: false,
      };
    case SEND_FOR_REVIEW_SUCCESS:
      return { ...state, isSentForReview: true, smsEditDataLoaded: false };
    case SEND_FOR_REVIEW_FAILURE: // handle error
      return { ...state, smsEditDataLoaded: false };
    case LOAD_SMS_TAGS_LIST:
      return {
        ...state,
        tagsListData: action.payload,
      };
    case LOAD_SMS_TAGS_LIST_FAILURE:
    default:
      return state;
  }
}

export function loadSmsData(payload, stepId, prevNodeEndDate) {
  const temp = payload;
  let res = {
    stepId: stepId,
    general: {},
    files: [],
    tags: [],
    canvasCustomTag: [],
    isSaved: 1,
    campaignDetails: [],
    validateErrors: {},
    default_values: {},
    prevNodeEndDate: '',
  };
  if (temp.sms) {
    res.general = temp.sms;
  }
  if (temp.managedFiles) {
    res.files = temp.managedFiles;
  }
  if (temp.tags) {
    res.tags = temp.tags;
  }
  if (temp.canvasCustomTag) {
    res.canvasCustomTag = temp.canvasCustomTag;
  }
  if (prevNodeEndDate?.endDate) {
    res.prevNodeEndDate = prevNodeEndDate.endDate;
  }

  return res;
}
