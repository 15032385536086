import axios from 'axios';
import { AuthService } from '../app/auth/AuthService';

const instance = axios.create({
  baseURL: '/api/v2',
  validateStatus: status => status >= 200 && status < 400,
  headers: {
    Accept: 'application/json',
  },
});

const auth = {
  accessToken: () => {
    try {
      const persisted = JSON.parse(localStorage.getItem('persist:orchestra') || '');
      return JSON.parse(persisted.authentication).accessToken;
    } catch (error) {
      return null;
    }
  },
};

instance.interceptors.request.use(
  async config => {
    const accessToken = auth.accessToken();

    if (accessToken) {
      config.headers.Authorization = `Bearer ${auth.accessToken()}`;
    }

    return config;
  },
  error => {
    Promise.reject(error);
  },
);

instance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      const service = new AuthService();
      const refresh = await service.refreshToken();

      if (refresh) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${refresh.accessToken}`;
        return instance(originalRequest);
      }
    }

    return Promise.reject(error);
  },
);

export default instance;
