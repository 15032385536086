import { TEXTFIELD_MAX_LENGTH } from '../app/constants/form-constants';
import moment from 'moment';

export const notIn = (a, b) => a.filter(value => b.indexOf(value) === -1);
export const intersection = (a, b) => a.filter(value => b.indexOf(value) !== -1);
export const union = (a, b) => [...a, ...notIn(b, a)];

export const createUrl = (path, params) => {
  let queryString = '';

  if (params && Object.keys(params).length) {
    queryString = Object.entries(params).reduce(
      (acc, [key, arr], index) =>
        acc.concat(arr.map((v, i) => `${index === 0 && i === 0 ? '?' : '&'}${key}=${v}`).join('')),
      '',
    );
  }

  return path + queryString;
};

export const camelToUnderscore = key => key.replace(/[A-Z]/g, $1 => '_' + $1).toLowerCase();
export const camelToUnderscoreObj = obj => {
  const newObj = {};
  for (const camel in obj) {
    newObj[camelToUnderscore(camel)] = obj[camel];
  }

  return newObj;
};

export const charLimitMessage = (value, t, max = TEXTFIELD_MAX_LENGTH) =>
  t('textField.characterLimitMessage', { count: max - value?.length });

export const getInitials = name => {
  if (name === '') {
    return name;
  } else {
    return name.split(' ').map(n => n[0].toUpperCase());
  }
};

/**Helper function to validate a date  */
export const dateIsValid = date => {
  return !Number.isNaN(new Date(date).getTime());
};

/**Helper function to validate date difference in-between */

export const startDateValidation = startDate => {
  const dateStart = moment(startDate);
  const currentDate = moment(new Date().toJSON().slice(0, 10).replace(/-/g, '/'));
  const dateDiff = dateStart.diff(currentDate);
  if (Math.sign(dateDiff) === -1) {
    return false;
  } else {
    return true;
  }
};

export const cleanUrlComponent = component => {
  return encodeURIComponent(component.replaceAll(' ', '-').toLowerCase());
}
