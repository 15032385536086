export interface State {
  fullfilled: boolean;
  pending: boolean;
  error: boolean;
  errorMessage?: string | null;
}

export const initialState = {
  data: [],
  pending: true,
  error: false,
  fullfilled: false,
  filterAssignedTo: null,
  selectedStepsId: [],
  filtersInitialised: false
};
