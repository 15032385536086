import axios from '../../api';
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { TargetBookmark } from './types';

export const getSegmentation = createAsyncThunk('segmentation', async (payload: Record<string, unknown>) => {
    const response = await axios.post('/campaigns/get-segmentation', payload);

    return response.data;
});

export const saveDefaultTargets = createAsyncThunk('save-default-targets', async (id: number) => {
    await axios.post(`/campaigns/save-default-targets/${id}`);
})

export const getTargets = createAsyncThunk('targets', async (payload: Record<string, unknown>) => {    
    const response = await axios.post('/campaigns/get-targets', payload);

    return response.data;
});

export const setTargetingData = createAsyncThunk('targeting-data', async (payload: Record<string, unknown>) => {
    await axios.post('/campaigns/set-targeting-data', payload);
});

export const getTargetBookmarks = createAsyncThunk('target-bookmarks', async (payload?: { therapeuticId: number, campaignTypeId: number, productId: number }) => {
    const response = await axios.get('/target-bookmarks', payload ? { 
        params: {
            therapeutic: payload.therapeuticId,
            campaignType: payload.campaignTypeId,
            product: payload.productId
        } 
    } : {});

    return response.data;
});

export const handleCustomTargetBookmark = createAction('custom-target-bookmark');

export const createTargetBookmark = createAsyncThunk('create-target-bookmark', async (payload: TargetBookmark) => {
    const response = await axios.post('/target-bookmarks', payload);

    return response.data;
});

export const deleteTargetBookmark = createAsyncThunk('delete-target-bookmark', async (id: number, { rejectWithValue }) => {
    try {
        await axios.delete(`/target-bookmarks/${id}`);
    
        return { id: id };
    } catch (error: any) {
        console.log(error, error.response, error.response.data);
        return rejectWithValue(error.response.data);
    }
});

export const updateTargetBookmark = createAsyncThunk('update-target-bookmark', async (payload: { id: number, body: Partial<TargetBookmark> }) => {
    const response = await axios.patch(`/target-bookmarks/${payload.id}`, payload.body);

    return response.data;
});

export const getAttributes = createAsyncThunk('attributes', async () => {
    const response = await axios.get('/attributes');

    return response.data;
});

export const saveResults = createAsyncThunk('save-results', async (payload: { campaignId: number, body: any }) => {
    await axios.patch(`/campaigns/${payload.campaignId}`, payload.body);
});
