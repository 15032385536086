import {
    NAVIGATE_STEP,
    LOAD_POST_MAIL_JOURNEY_SUCCESS,
    LOAD_POST_MAIL_JOURNEY_FAILURE,
    LOAD_POST_MAIL_TAGS_LIST,
    LOAD_POST_MAIL_TAGS_LIST_FAILURE,
    UPDATE_POST_MAIL_JOURNEY_DRAFT,
    CREATE_POST_MAIL_JOURNEY_SUCCESS,
    LOAD_POST_MAILS_SUCCESS,
    LOAD_POST_MAILS_FAILURE,
    SEND_FOR_REVIEW_FAILURE,
    RESET_POST_MAIL_JOURNEY
} from './types';

const initialState = {
    requestInProgress: false,
    activeStep: 0,
    stepDisplayId: '',
    postMailEditDataLoaded: false,
    journeyDraft: {
        stepId: -1,
        general: {},
        files: [],
        isSaved: 1,
        tags: [],
        canvasCustomTag: [],
        missingFieldErrors: {},
    },
    validateErrors: {},
    formStatus: {},
    tagsListData: [],
    isSentForReview: false,
    postMails: [],
    postMailsLoaded: false
}

export const postMailJourneyCreateReducer = (state = initialState, action) => {
    let res = [];
    switch (action.type) {
        case NAVIGATE_STEP:
            return { ...state, activeStep: action.step };
        case RESET_POST_MAIL_JOURNEY:
            return initialState;
        case LOAD_POST_MAILS_SUCCESS:
            return {
                ...state,
                requestInProgress: false,
                postMailsLoaded: true,
                postMails: action.payload
            }
        case LOAD_POST_MAILS_FAILURE:
            return { ...state, requestInProgress: false };
        case LOAD_POST_MAIL_JOURNEY_SUCCESS: 
            res = loadPostMailData(action.payload, action.stepId, action.prevNodeEndDate);
            return {
                ...state,
                journeyDraft: res,
                isSentForReview: !action.payload?.status.userEditable,
                postMailEditDataLoaded: true,
                formStatus: action.payload.status,
                stepDisplayId: action.payload.stepDisplayId,
                requestInProgress: false
            }
        case LOAD_POST_MAIL_JOURNEY_FAILURE:
            return { ...state, requestInProgress: false };
        case LOAD_POST_MAIL_TAGS_LIST:
            return {
                ...state,
                tagsListData: action.payload
            }
        case UPDATE_POST_MAIL_JOURNEY_DRAFT:
            return { ...state, journeyDraft: action.payload };
        case CREATE_POST_MAIL_JOURNEY_SUCCESS:
            return {
                ...state,
                requestInProgress: false,
                postMailEditDataLoaded: false,
            }
        case SEND_FOR_REVIEW_FAILURE:
            return { ...state, postMailEditDataLoaded: false };
        case LOAD_POST_MAIL_TAGS_LIST_FAILURE:
        default:
            return state;
    }
}

const loadPostMailData = (payload, stepId, prevNodeEndDate) => {
    const temp = payload;
    let res = {
        stepId: stepId,
        general: {},
        files: [],
        tags: [],
        canvasCustomTag: [],
        isSaved: 1,
        campaignDetails: [],
        validateErrors: {},
        default_values: {},
        prevNodeEndDate: ''
    }

    if (temp.postMail) {
        res.general.postMail = temp.postMail;
    }

    if (temp.managedFiles) {
        res.files = temp.managedFiles;
    }

    if (temp.tags) {
        res.tags = temp.tags;
    }

    if (temp.canvasCustomTag) {
        res.canvasCustomTag = temp.canvasCustomTag;
    }

    if (prevNodeEndDate?.endDate) {
        res.prevNodeEndDate = prevNodeEndDate.endDate;
    }

    return res;
}
