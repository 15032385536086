import axios from '../../api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { StatusChange } from './types';
import { updateLoadedBriefRequest } from '../../store/switch-editor/actions';

export const getBriefRequestList = createAsyncThunk('briefRequestList', async (briefId: number) => {
  const response = await axios.get('/switch/briefRequests?briefId=' + briefId);
  return response.data;
});

export const createBriefRequest = createAsyncThunk('createBriefRequest', async (payload: any) => {
  const response = await axios.post('/switch/briefRequests', payload);
  return response.data;
});

export const updateBriefRequest = createAsyncThunk('updateBriefRequest', async (payload: {id: string, briefRequest: any}) => {
  const response = await axios.patch(`/switch/briefRequests/${payload.id}`, payload.briefRequest);
  return response.data;
});

export const deleteBriefRequest = createAsyncThunk('deleteBriefRequest', async id => {
  const response = await axios.delete(`/switch/briefRequests/${id}`);
  return response.data;
});

export const handleBriefRequestStatusAutomation = createAsyncThunk(
  'handleBriefRequestStatusAutomation', 
  async (payload: {id: number, data: { direction: StatusChange }}, thunkAPI) => {
    const response = await axios.post(`/switch/briefRequests/${payload.id}/status-change`, payload.data);

    thunkAPI.dispatch(updateLoadedBriefRequest(response.data));

    return response.data;
  }
);

export const validateBriefRequestUrl = createAsyncThunk('validateBriefRequestUrl', async (payload: { id: number, url: string }) => {
  const response = await axios.post(`/switch/briefRequests/validate-url`, payload);
  return response.data;
});