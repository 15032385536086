import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '../default';

import { getAdminNodesList, setFilterAssignedTo, setFiltersInitialised, setSelectedSteps } from './actions';
import { AdminNode, AdminNodesListState } from './types';

export default createReducer(initialState as AdminNodesListState, builder => {
  builder
    .addCase(getAdminNodesList.pending, state => {
      state.pending = true;
    })
    .addCase(getAdminNodesList.fulfilled, (state, { payload }) => {
      state.pending = false;
      state.data = payload.filter((el: AdminNode) => ![3, 4, 5, 9, 10, 11].includes(el.STEP_TYPE));
    })
    .addCase(getAdminNodesList.rejected, state => {
      state.pending = false;
      state.error = true;
    })
    .addCase(setFilterAssignedTo, (state, { payload }) => {
      state.filterAssignedTo = payload;
    })
    .addCase(setSelectedSteps, ( state, { payload }) => {
      state.selectedStepsId = payload;
    })
    .addCase(setFiltersInitialised, (state, { payload }) => {
      state.filtersInitialised = payload;
    });
});
