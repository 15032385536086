import { createReducer } from "@reduxjs/toolkit";
import { initialState, TargetingState } from './types';
import { createTargetBookmark, deleteTargetBookmark, getAttributes, getSegmentation, getTargetBookmarks, getTargets, handleCustomTargetBookmark, setTargetingData, saveResults, updateTargetBookmark, saveDefaultTargets } from "./actions";

export default createReducer(initialState as TargetingState, builder => {
    builder
        // Refresh
        .addCase(setTargetingData.pending, state => {
            state.refresh.pending = true;
        })
        .addCase(setTargetingData.fulfilled, state => {
            state.refresh.pending = false;
            state.refresh.fullfilled = true;
        })
        .addCase(setTargetingData.rejected, state => {
            state.refresh.pending = false;
            state.refresh.error = true;
        })

        // Segmentation
        .addCase(getSegmentation.pending, state => {
            state.segmentation.pending = true;
        })
        .addCase(getSegmentation.fulfilled, (state, { payload }) => {            
            state.segmentation.pending = false;
            state.segmentation.fullfilled = true;
            state.segmentation.data = payload;
        })
        .addCase(getSegmentation.rejected, state => {
            state.segmentation.pending = false;
            state.segmentation.error = true;
        })

        // Save Default Targets
        .addCase(saveDefaultTargets.pending, state => {
            state.targets.pending = true;
        })
        .addCase(saveDefaultTargets.fulfilled, state => {
            state.targets.pending = false;
        })
        .addCase(saveDefaultTargets.rejected, state => {
            state.targets.pending = false;
        })

        // Targets
        .addCase(getTargets.pending, state => {
            state.targets.pending = true;
        })
        .addCase(getTargets.fulfilled, (state, { payload }) => {
            state.targets.pending = false;
            state.targets.fullfilled = true;
            state.targets.data = payload
        })
        .addCase(getTargets.rejected, state => {
            state.targets.pending = false;
            state.targets.error = true;
        })

        // TargetBookmarks - List
        .addCase(getTargetBookmarks.pending, state => {
            state.targetBookmarks.pending = true;
        })
        .addCase(getTargetBookmarks.fulfilled, (state, { payload }) => {
            state.targetBookmarks.pending = false;
            state.targetBookmarks.fullfilled = true;
            state.targetBookmarks.data = payload;
        })
        .addCase(getTargetBookmarks.rejected, state => {
            state.targetBookmarks.pending = false;
            state.targetBookmarks.error = true;
        })

        // TargetBookmarks - Create
        .addCase(createTargetBookmark.pending, state => {
            state.targetBookmarks.pending = true;
        })
        .addCase(createTargetBookmark.fulfilled, (state, { payload }) => {
            state.targetBookmarks.pending = false;
            state.targetBookmarks.error = false;
            state.targetBookmarks.data = state.targetBookmarks.data.concat(payload);
        })
        .addCase(createTargetBookmark.rejected, (state, { payload }) => {
            state.targetBookmarks.pending = false;
            state.targetBookmarks.error = true;
        })

        // TargetBookmarks - Delete
        .addCase(deleteTargetBookmark.pending, state => {
            state.targetBookmarks.pending = true;
        })
        .addCase(deleteTargetBookmark.fulfilled, (state, { payload }) => {
            state.targetBookmarks.pending = false;
            state.targetBookmarks.data = state.targetBookmarks.data.filter(bookmark => bookmark.id !== payload.id);
        })
        .addCase(deleteTargetBookmark.rejected, state => {
            state.targetBookmarks.pending = false;
            state.targetBookmarks.error = true;
        })

        // TargetBookmarks - Update
        .addCase(updateTargetBookmark.pending, state => {
            state.targetBookmarks.pending = true;
        })
        .addCase(updateTargetBookmark.fulfilled, (state, { payload }) => {
            state.targetBookmarks.pending = false;
            state.targetBookmarks.error = false;
            state.targetBookmarks.data = state.targetBookmarks.data.filter(bookmark => bookmark.id !== payload.id).concat(payload);
        })
        .addCase(updateTargetBookmark.rejected, state => {
            state.targetBookmarks.pending = false;
            state.targetBookmarks.error = true;
        })

        // Custom Segment (Target Bookmark)
        .addCase(handleCustomTargetBookmark, (state, { payload }) => {
            if (payload) {
                state.targetBookmarks.data = [payload, ...state.targetBookmarks.data];
            } else {
                state.targetBookmarks.data = state.targetBookmarks.data.filter(bookmark => bookmark.id !== 0);
            }
        })

        // Attributes
        .addCase(getAttributes.pending, state => {
            state.attributes.pending = true;
        })
        .addCase(getAttributes.fulfilled, (state, { payload }) => {
            state.attributes.pending = false;
            state.attributes.fullfilled = true;
            state.attributes.data = payload;
        })
        .addCase(getAttributes.rejected, state => {
            state.attributes.pending = false;
            state.attributes.error = true;
        })
});