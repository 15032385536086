export const LOAD_EMAIL_USED_TECHNOLOGY_SUCCESS = 'load_email_used_technology_success';
export const LOAD_EMAIL_USED_TECHNOLOGY_FAILURE = 'load_email_used_technology_failure';
export const UPDATE_AUTO_EMAIL_DRAFT = 'update_auto_email_draft';
export const SAVE_EMAIL_JOURNEY = 'save_email_journey';
export const RESET_AUTOMATED_MAIL = 'reset_automated_mail';
export const NAVIGATE_STEP = 'mass_email_navigate_step';
export const CREATE_AUTO_EMAIL_SUCCESS = 'create_auto_email_success';
export const CREATE_AUTO_EMAIL_FAILURE = 'create_auto_email_failure';
export const LOAD_EMAIL_JOURNEY = 'load_email_journey';
export const LOAD_AUTO_EMAIL_FAILURE = 'load_auto_email_failure';
export const LOAD_AUTO_EMAIL_SUCCESS = 'load_auto_email_success';
export const LOAD_CTA_LABELS_SUCCESS = 'load_cta_labels_success';
export const LOAD_CTA_LABELS_FAILURE = 'load_cta_labels_failure';
export const SEND_FOR_REVIEW_SUCCESS = 'send_for_review_success';
export const SEND_FOR_REVIEW_FAILURE = 'send_for_review_failure';
export const DELETE_FILE_FAILURE = 'delete_file_failure';
export const DELETE_FILE_SUCCESS = 'delete_file_success';
export const LOAD_AUTO_EMAIL_TYPE_AND_SUBTYPE = 'load_auto_email_type_and_subtype';
export const LOAD_AUTO_EMAIL_TYPE_AND_SUBTYPE_FAILURE = 'load_auto_email_type_and_subtype_failure';
export const LOAD_AUTO_EMAIL_TAGS_LIST = 'load_auto_email_tags_list';
export const LOAD_AUTO_EMAIL_TAGS_LIST_FAILURE = 'load_auto_email_tags_list_failure';
export const GET_EVENT_DETAILS = 'get_mass_email_event_details';
export const SET_EVENT_DETAILS = 'set_mass_email_event_details';
export const GET_INTEGRATION_TEAM = 'get_mass_email_integration_team';
export const SET_INTEGRATION_TEAM = 'set_mass_email_integration_team';
export const GET_SENDERS_LIST = 'get_mass_email_senders_list';
export const SET_SENDERS_LIST = 'set_mass_email_senders_list';
export const RECALL_FROM_REVIEW_SUCCESS = 'mass_email_recall_from_review_success';
export const RECALL_FROM_REVIEW_FAILURE = 'mass_email_recall_from_review_failure';
export const GET_MASS_MAIL_TEMPLATE = 'get_mass_email_template';
export const SET_MASS_MAIL_TEMPLATE = 'set_mass_email_template';
export const GET_EMAIL_AND_MASS_MAIL_TEMPLATE = 'get_email_and_mass_mail_template';
export const SET_EMAIL_AND_MASS_MAIL_TEMPLATE = 'set_email_and_mass_mail_template';
export const GET_MASS_MAIL_SEND_TO_LIST = 'get_mass_mail_send_to_list';
export const SET_MASS_MAIL_SEND_TO_LIST = 'set_mass_mail_send_to_list';
export const GET_MASS_MAIL_REPLY_TO_LIST = 'get_mass_mail_reply_to_list';
export const SET_MASS_MAIL_REPLY_TO_LIST = 'set_mass_mail_reply_to_list';
export const RESET_MASS_MAIL_EDIT_LOADED_STATE = 'reset_mass_mail_edit_loaded_state';
export const GET_MASS_MAIL_EVENT_FORM_LINK_STATUS = 'get_mass_mail_event_form_link_status';
export const GET_LINKED_WEBSITES = 'get_linked_websites';
export const SET_MESSAGE_DETAILS = 'set_message_details';
export const SET_CHANNEL_DETAILS = 'set_channel_details';