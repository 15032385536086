import axios from '../../api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getBriefList = createAsyncThunk('briefList', async () => {
  const response = await axios.get('/switch/briefs');
  return response.data;
});

export const createBrief = createAsyncThunk('createBrief', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await axios.post('/switch/briefs', payload);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const updateBrief = createAsyncThunk(
  'updateBrief',
  async (payload: { id: string; brief: any }) => {
    const response = await axios.patch(`/switch/briefs/${payload.id}`, payload.brief);
    return response.data;
  },
);

export const deleteBrief = createAsyncThunk('deleteBrief', async id => {
  const response = await axios.delete(`/switch/briefs/${id}`);
  return response.data;
});
