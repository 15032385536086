import { AppBar, Box, Divider, Modal, Dialog, Toolbar as ToolbarMui, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import orchestraLogo from '../../pages/resources/Logo/orchestraLogo.svg';
import { UserAvatar } from '../user-avatar/UserAvatar';
import CountrySelect from '../country-select/CountrySelect';
import MenuTabs from '../menu-tabs/MenuTabs';
import { useLocation } from 'react-router-dom';
import styles from './Topbar.module.scss';
import { Link } from 'react-router-dom';
import Notification from './components/notification';
import { ReactComponent as BugIcon } from './components/notification/icons/bug.svg';
import { ReactComponent as LightBulb } from './components/notification/icons/lightbulb.svg';
import { useTranslation } from 'react-i18next';
import './TopBar.scss';
import ChangeUserProfile from './ChangeUserProfile';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { useState, useMemo } from 'react';
import ContactSupport from './components/contact-support/ContactSupport';
import { decideHomePage, defaultHomePages } from '../../../access-control/helpers';
import { featureSelector } from '../../../new-store/features/selectors';

//to over-write material ui css
const toolTipCss = makeStyles(() => ({
  tooltip: {
    backgroundColor: '#42a5f5',
    color: 'white',
  },
}));

const TopBar = () => {
  const location = useLocation();
  const tooltipClass = toolTipCss();
  const { t } = useTranslation();
  const [supportModalOpen, setSupportModalOpen] = useState(false);

  const user = useSelector(({ authentication }) => authentication.profile);
  const featureState = useSelector(featureSelector);

  const homepage = useMemo(() => {
    return decideHomePage(user, featureState.country.data, featureState.profile.data, featureState.team.data);
  }, [user, featureState.country.data, featureState.profile.data, featureState.team.data]);

  const getMenuTabValue = () => {
    const path = location.pathname;
    if (path.includes('analytics')) {
      return 'analytics';
    }

    if (path.includes('briefs')) {
      return 'briefs';
    }

    if (path.includes('websites')) {
      return 'websites';
    }

    if (path.includes('content-production') || path.includes('campaign/brief')) {
      return 'content-production';
    }

    // default
    return 'planning';
  };

  return (
    <div className={styles.container}>
      <AppBar className={styles.appBar} position="relative" color="secondary" elevation={0}>
        <ToolbarMui className={styles.toolbar}>
          <div className="logo-div">
            <Link to="/" style={{ textDecoration: 'none', display: 'flex' }}>
              <img height="45" width="124" src={orchestraLogo} alt="Orchestra logo" />
            </Link>
          </div>
          <MenuTabs value={getMenuTabValue()} homepage={homepage} />
          <div className="action-div">
            <ChangeUserProfile />

            {
              defaultHomePages.includes(homepage) &&
              <Notification />
            }

            <Tooltip classes={tooltipClass} title='Contact Support'>
              <ContactSupportIcon onClick={() => setSupportModalOpen(true)} className='support-icon' />
            </Tooltip>

            {/* Hidden until proper internationalisation and language selection is implemented. */}
            {/* <Divider className={styles.divider} orientation="vertical" flexItem /> */}
            {/* <CountrySelect /> */}

            <Divider className={styles.divider} orientation="vertical" flexItem />
            <Tooltip
              title={
                <div style={{ textAlign: 'center' }}>
                  {user?.name}
                  <br />
                  {user?.profile?.name}
                </div>
              }
              classes={tooltipClass}
            >
              <div className="userIcon">
                <UserAvatar user={user} />
              </div>
            </Tooltip>
          </div>
        </ToolbarMui>
        <Divider />
      </AppBar>

      <Dialog
        open={supportModalOpen}
        onClose={() => setSupportModalOpen(false)}
      >
        <ContactSupport handleClose={() => setSupportModalOpen(false)} />
      </Dialog>
    </div>
  );
};

export default TopBar;
