export const UPDATE_SMS_DRAFT = 'update_sms_draft';
export const SAVE_SMS = 'save_sms';
export const RESET_SMS = 'reset_sms';
export const NAVIGATE_STEP = 'navigate_step';
export const CREATE_SMS_SUCCESS = 'create_sms_success';
export const CREATE_SMS_FAILURE = 'create_sms_failure';
export const LOAD_SMS = 'load_sms';
export const LOAD_SMS_FAILURE = 'load_sms_failure';
export const LOAD_SMS_SUCCESS = 'load_sms_success';
export const DELETE_FILE_FAILURE = 'delete_file_failure';
export const DELETE_FILE_SUCCESS = 'delete_file_success';
export const SEND_FOR_REVIEW_SUCCESS = 'send_for_review_success';
export const SEND_FOR_REVIEW_FAILURE = 'send_for_review_failure';
export const LOAD_CANVAS_SUCCESS = 'store/sms/load_canvas_success';
export const LOAD_CANVAS_FAILURE = 'store/sms/load_canvas_failure';
export const LOAD_SMS_TAGS_LIST = 'load_sms_tags_list';
export const LOAD_SMS_TAGS_LIST_FAILURE = 'load_sms_tags_list_failure';
