import axios from 'axios';
import { steps, campaign } from '../../app/constants/api-constants';
import {
  GET_STATUS_OPTIONS,
  GET_STATUS_OPTIONS_FAILED,
  GET_FORMS_TODO_LIST,
  SET_FORMS_TODO_LIST,
} from './types';
import { API_STATUS_CONSTANTS } from '../../app/constants/form-constants';

export const getStatusOptions = () => async (dispatch) => {
  try {
    const res = await axios.get(`${steps}/statuses`);
    dispatch({
      type: GET_STATUS_OPTIONS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_STATUS_OPTIONS_FAILED,
    });
  }
};

export const updateCampaignStartAndEndDate = ({ campaignId, nodeId }) => async (dispatch) => {
  try {
    const url = `${campaign}/update-dates/${campaignId}${nodeId ? '/' + nodeId : ''}`;
    await axios.post(url);
  } catch (error) {
    console.log(error);
  }
};

export const getFormsTodoList = () => async (dispatch) => {
  const url = `${steps}/types?todos=1`;

  try {
    dispatch({ type: GET_FORMS_TODO_LIST });

    const res = await axios.get(url);

    if (res.status === 200) {
      dispatch({
        type: SET_FORMS_TODO_LIST,
        payload: { data: res.data, status: API_STATUS_CONSTANTS.success },
      });
    } else {
      dispatch({
        type: SET_FORMS_TODO_LIST,
        payload: { data: {}, staus: API_STATUS_CONSTANTS.failed },
      });
    }
  } catch (err) {
    dispatch({
      type: SET_FORMS_TODO_LIST,
      payload: { data: {}, staus: API_STATUS_CONSTANTS.failed },
    });
  }
};
