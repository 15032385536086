import { LOAD_CAMPAIGN_STEP_COMPLETION } from './types';

const initialState = {
  campaignStepCompletion: [],
};

export function campaignStepCompletionReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_CAMPAIGN_STEP_COMPLETION:
      return { ...state, campaignStepCompletion: action.payload }; 
    default:
      return state;
  }
}
