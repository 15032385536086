export const LOAD_EMAIL_TEMPLATES_FRAGMENT = 'load_email_templates_fragment';
export const LOAD_EMAIL_TEMPLATES_SUCCESS_FRAGMENT = 'load_email_templates_success_fragment';
export const LOAD_EMAIL_TEMPLATES_FAILURE_FRAGMENT = 'load_email_templates_failure_fragment';
export const LOAD_EMAIL_USED_TECHNOLOGY_SUCCESS = 'load_email_used_technology_success';
export const LOAD_EMAIL_USED_TECHNOLOGY_FAILURE = 'load_email_used_technology_failure';
export const UPDATE_FRAGMENT_DRAFT = 'update_fragment_draft';
export const SAVE_FRAGMENT = 'save_fragment';
export const RESET_FRAGMENT = 'reset_fragment';
export const NAVIGATE_STEP = 'navigate_step';
export const CREATE_FRAGMENT_SUCCESS = 'create_fragment_success';
export const CREATE_FRAGMENT_FAILURE = 'create_fragment_failure';
export const LOAD_FRAGMENT = 'load_fragment';
export const LOAD_FRAGMENT_FAILURE = 'load_fragment_failure';
export const LOAD_FRAGMENT_SUCCESS = 'load_fragment_success';
export const DELETE_FILE_FAILURE = 'delete_file_failure';
export const DELETE_FILE_SUCCESS = 'delete_file_success';
export const LOAD_CTA_LABELS_SUCCESS = 'load_cta_labels_success';
export const LOAD_CTA_LABELS_FAILURE = 'load_cta_labels_failure';
export const SEND_FOR_REVIEW_SUCCESS = 'send_for_review_success';
export const SEND_FOR_REVIEW_FAILURE = 'send_for_review_failure';
export const LOAD_CANVAS_SUCCESS = 'store/fragment/load_canvas_success';
export const LOAD_CANVAS_FAILURE = 'store/fragment/load_canvas_failure';
export const LOAD_FRAGMENT_TAGS_LIST = 'load_fragment_tags_list';
export const LOAD_FRAGMENT_TAGS_LIST_FAILURE = 'load_fragment_tags_list_failure';
export const SET_MESSAGE_DETAILS = 'set_message_details';
export const SET_CHANNEL_DETAILS = 'set_channel_details';