import { createReducer } from '@reduxjs/toolkit';
import { clearCampaignDetails, deleteCampaign, getCampaignDetails, getCampaignTypeList, getCountryList, getEvent, getLightViewList, getProductList, getStepTypeList, getUserList, saveCampaignBuilder, updateCampaignBuilder } from './actions';
import { initialState, CampaignBuilderState } from './types';

export default createReducer(initialState as CampaignBuilderState, builder => {
    builder
        .addCase(getLightViewList.pending, state => {
            state.list.pending = true;
        })
        .addCase(getLightViewList.fulfilled, (state, { payload }) => {
            state.list.pending = false;
            state.list.fullfilled = true;
            state.list.data = payload;
        })
        .addCase(getLightViewList.rejected, state => {
            state.list.pending = false;
            state.list.error = true;
        })

        .addCase(getProductList.pending, state => {
            state.products.pending = true;
        })
        .addCase(getProductList.fulfilled, (state, { payload }) => {
            state.products.pending = false;
            state.products.fullfilled = true;
            state.products.data = payload.Product;
        })
        .addCase(getProductList.rejected, state => {
            state.products.pending = false;
            state.products.error = true;
        })

        .addCase(getCountryList.pending, state => {
            state.countries.pending = true;
        })
        .addCase(getCountryList.fulfilled, (state, { payload }) => {
            state.countries.pending = false;
            state.countries.fullfilled = true;
            state.countries.data = payload;
        })
        .addCase(getCountryList.rejected, state => {
            state.countries.pending = false;
            state.countries.error = true;
        })

        .addCase(getUserList.pending, state => {
            state.users.pending = true;
        })
        .addCase(getUserList.fulfilled, (state, { payload }) => {
            state.users.pending = false;
            state.users.fullfilled = true;
            state.users.data = payload;
        })
        .addCase(getUserList.rejected, state => {
            state.users.pending = false;
            state.users.error = true;
        })

        .addCase(getCampaignTypeList.pending, state => {
            state.campaignTypes.pending = true;
        })
        .addCase(getCampaignTypeList.fulfilled, (state, { payload }) => {
            state.campaignTypes.pending = false;
            state.campaignTypes.fullfilled = true;
            state.campaignTypes.data = payload;
        })
        .addCase(getCampaignTypeList.rejected, state => {
            state.campaignTypes.pending = false;
            state.campaignTypes.error = true;
        })

        .addCase(getStepTypeList.pending, state => {
            state.stepTypes.pending = true;
        })
        .addCase(getStepTypeList.fulfilled, (state, { payload }) => {
            state.stepTypes.pending = false;
            state.stepTypes.fullfilled = true;
            state.stepTypes.data = payload;
        })
        .addCase(getStepTypeList.rejected, state => {
            state.stepTypes.pending = false;
            state.stepTypes.error = true;
        })

        .addCase(getEvent.pending, state => {
            state.event.pending = true;
        })
        .addCase(getEvent.fulfilled, (state, { payload }) => {
            state.event.pending = false;
            state.event.fullfilled = true;
            state.event.data = payload;
        })
        .addCase(getEvent.rejected, state => {
            state.event.pending = false;
            state.event.error = true;
        })

        .addCase(getCampaignDetails.pending, state => {
            state.campaign.pending = true;
        })
        .addCase(getCampaignDetails.fulfilled, (state, { payload }) => {
            state.campaign.pending = false;
            state.campaign.fullfilled = true;
            state.campaign.data = payload;
        })
        .addCase(getCampaignDetails.rejected, state => {
            state.campaign.pending = false;
            state.campaign.error = true;
        })

        .addCase(clearCampaignDetails, state => {
            state.campaign.data = null;
            state.form.error = false;
            state.form.errorMessage = null;
        })

        .addCase(saveCampaignBuilder.pending, state => {
            state.form.pending = true;
        })
        .addCase(saveCampaignBuilder.fulfilled, (state, { payload }) => {
            state.form.pending = false;
            state.form.error = false;
            state.form.fullfilled = true;
            state.form.errorMessage = null;
            state.campaign.data = payload;
        })
        .addCase(saveCampaignBuilder.rejected, (state, { payload }: any) => {
            state.form.pending = false;
            state.form.error = true;
            state.form.errorMessage = payload.message;
        })

        .addCase(updateCampaignBuilder.pending, state => {
            state.form.pending = true;
        })
        .addCase(updateCampaignBuilder.fulfilled, (state, { payload }) => {
            state.form.pending = false;
            state.form.fullfilled = true;
            state.campaign.data = payload;
        })
        .addCase(updateCampaignBuilder.rejected, state => {
            state.form.pending = false;
            state.form.error = true;
        })

        .addCase(deleteCampaign.pending, state => {
            state.delete.pending = true;
        })
        .addCase(deleteCampaign.fulfilled, state => {
            state.delete.pending = false;
            state.delete.fullfilled = true;
        })
        .addCase(deleteCampaign.rejected, state => {
            state.delete.pending = false;
            state.delete.error = true;
        })
});