import axios from '../../api';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

export const getAdminNodesList = createAsyncThunk('adminNodesList', async () => {
  const response = await axios.get('/campaigns/admin-nodes-list');
  return response.data;
});

export const setFilterAssignedTo = createAction('setFilterAssignedTo', (value: string | null) => {
  return { payload: value };
});

export const setSelectedSteps = createAction('setSelectedSteps', (value: string[]) => {
  return { payload: value };
});

export const setFiltersInitialised = createAction('setFiltersInitialised', (value: boolean) => {
  return { payload: value };
});