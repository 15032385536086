import * as types from './authTypes';
const initialState = {
  error: undefined,
};

export const authErrorReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_CODE_FAILURE:
    case types.FETCH_PROFILE_FAILURE:
    case types.FETCH_ACCESS_TOKENS_FAILURE:
    case types.REFRESH_TOKEN_FAILURE:
      return { error: action.payload };
    default:
      break;
  }
  return state;
};
