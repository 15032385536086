export enum AdminListViewValue {
    USER = 'user',
    ADMIN = 'admin'
};

export type TeamMember = { id: number; username: string; name: string; wwid: string; };

export interface AdminListViewState {
    value: AdminListViewValue;
    integrationTeamMembers: TeamMember[];
    isLoadingAssigment: boolean;
}