import { useSelector } from 'react-redux';

export const userTypes = {
  admin: 'admin',
  owner: 'owner',
  notOwner: 'notOwner',
};

// Note: campaign creater or member of the campaign team is campaign owner
// campaign owner has write access only if the status is prep. or missing information
// admin has write access irrespective of status.
// other users only read access
export const useCheckForOwnership = () => {
  const currentUserProfile = useSelector(({ authentication }) => authentication.profile);
  const currentUserWwid = currentUserProfile.wwid;
  const campaignTeamMembers = useSelector(
    ({ campaignProfile }) => campaignProfile.campaignProfile.teamDetails,
  );
  const userPartOfCampaignTeam = campaignTeamMembers?.find(
    (member) => member.wwid === currentUserWwid,
  );

  if (currentUserProfile.role?.toLowerCase() === 'admin' || currentUserProfile.role?.toLowerCase() === 'superadmin') {
    return { user: userTypes.admin, disableField: false };
  } else if (userPartOfCampaignTeam) {
    return { user: userTypes.owner, disableField: false };
  } else {
    return { user: userTypes.notOwner, disableField: true };
  }
};
