import axios from 'axios';
import {
  LOAD_USER_NOTIFICATION_SUCCESS,
  LOAD_USER_NOTIFICATION_FAILURE,
  UPDATED_USER_NOTIFICATION_FAILURE,
  SEND_FOR_REVIEW_NOTIFICATION_CREATE_SUCCESS,
  SEND_FOR_REVIEW_NOTIFICATION_CREATE_FAILURE,
} from './types';
import { userNotificationApi, users } from '../../app/constants/api-constants';

export const getUserNotification = ({ userId }) => async dispatch => {
  try {
    const url = `${userNotificationApi}/user-notifications/${userId}`;
    const res = await axios.get(url);
    const data = res.data;

    dispatch({
      type: LOAD_USER_NOTIFICATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LOAD_USER_NOTIFICATION_FAILURE,
    });
    console.log(error);
  }
};

export const updateNotificationBookmark = ({ data, userId, notificationId }) => async dispatch => {
  try {
    const url = `${userNotificationApi}/${notificationId}`;
    await axios.patch(url, data);
  } catch (error) {
    dispatch({
      type: UPDATED_USER_NOTIFICATION_FAILURE,
    });
    console.log(error);
  }
};

export const createSendForReviewNotification = ({ campaignId, nodeId, currentUserId }) => async (
  dispatch,
  getState,
) => {
  try {
    const campaignCountries = getState()
      .campaignProfile.campaignProfile?.campaignDetails?.countries.map(country => country.id)
      .join(',');
    const getAdminUser = await axios.get(users, {
      params: {
        profile: 'Admin',
        countries: campaignCountries
      }
    });
    getAdminUser.data?.forEach(async user => {
      const createNotification = {
        type: {
          id: 8,
        },
        campaign: {
          id: campaignId,
        },
        step: {
          id: nodeId,
        },
        updatedBy: {
          id: currentUserId,
        },
        user: { id: user.id },
        bookmark: true,
      };
      await axios.post(userNotificationApi, createNotification);
    });
    dispatch({
      type: SEND_FOR_REVIEW_NOTIFICATION_CREATE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: SEND_FOR_REVIEW_NOTIFICATION_CREATE_FAILURE,
    });
    console.log(error);
  }
};
