import {
  LOAD_CAMPAIGN_PROFILE,
  UPDATE_CAMPAIGN_PROFILE,
  GET_TARGET_PRESSURE,
  GET_FRAGMENT_DATA,
  SET_CAMPAIGN_TEAMS,
  SET_MESSAGE_DETAILS,
  SET_CHANNEL_DETAILS,
  SET_CAMPAIGN_OWNER
} from './types';

const initialState = {
  loading: true,
  campaignProfile: {},
  targetPressure: {},
  fragmentData: [],
  fragmentDataLoading: true
};

export function campaignProfileReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_CAMPAIGN_PROFILE:
      return { ...state, campaignProfile: action.payload };
    case UPDATE_CAMPAIGN_PROFILE:
      return { ...state, campaignProfile: { ...state.campaignProfile, ...action.payload } };
    case GET_TARGET_PRESSURE:
      return {
        ...state,
        targetPressure: action.payload,
      };
    case GET_FRAGMENT_DATA:
        return {
          ...state,
          fragmentData: action.payload,
          fragmentDataLoading: false
        };  
    case SET_CAMPAIGN_TEAMS:
      return {
        ...state,
        campaignProfile: { ...state.campaignProfile, teamDetails: action.payload.team },
      };
    case SET_CAMPAIGN_OWNER:
      return {
        ...state,
        campaignProfile: { 
          ...state.campaignProfile, 
          campaignDetails: {
            ...state.campaignProfile.campaignDetails,
            owner: action.payload.owner 
          }
        }
      }
    case SET_MESSAGE_DETAILS:
      return {
        ...state,
        campaignProfile: { 
          ...state.campaignProfile, 
          teamsIntegrationDetails: {
            ...state.campaignProfile.teamsIntegrationDetails,
            messageDetails: action.payload 
          }
        }
      }
    case SET_CHANNEL_DETAILS:
      return {
        ...state,
        campaignProfile: { 
          ...state.campaignProfile, 
          teamsIntegrationDetails: { 
            ...state.campaignProfile.teamsIntegrationDetails, 
            channelDetails: action.payload 
          } 
        }
      }
    default:
      return state;
  }
}
