import axios from '../../api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getTagList = createAsyncThunk(
  'tagList',
  async ({ productId, therapeuticId }: { productId: number; therapeuticId: number }) => {
    const response = await axios.get(`/tags/tags-by-category/${productId}/${therapeuticId}/7`);
    return response.data;
  },
);
