import { GET_CALENDER_DATA, SET_CALENDER_DATA } from './types';
import { API_STATUS_CONSTANTS } from '../../app/constants/form-constants';

const initialState = {
  campaignListCollection: [],
  campaignListStatus: API_STATUS_CONSTANTS.init,
};

export function CalenderReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CALENDER_DATA:
      return {
        ...state,
        campaignListCollection: [],
        campaignListStatus: API_STATUS_CONSTANTS.loading,
      };
    case SET_CALENDER_DATA:
      return {
        ...state,
        campaignListCollection: payload.data,
        campaignListStatus: payload.status,
      };
    default:
      return state;
  }
}
