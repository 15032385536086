import * as types from './types';

const initialState = {
  data: null,
  pending: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TEAM_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.GET_TEAM_SUCCESS:
      const integrationList = action.payload.filter(el => el.type.id === 1);
      const internalList = action.payload.filter(el => el.type.id === 2);
      const designList = action.payload.filter(el => el.type.id === 3);

      return {
        ...state,
        data: {
          integration: integrationList,
          internal: internalList,
          design: [{ id: 'default', name: 'No design' }, ...designList],
        },
        pending: false,
      };
    case types.GET_DESIGN_TEAM_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          design: [{ id: 'default', name: 'No design' }, ...action.payload]
        },
        pending: false,
      }
    case types.GET_INTEGRATION_TEAM_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          integration: action.payload
        },
        pending: false,
      }
    case types.GET_INTERNAL_TEAM_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          internal: action.payload
        },
        pending: false,
      }
    case types.GET_TEAM_FAILURE:
      return {
        ...state,
        error: action.payload,
        pending: false,
      };
    default:
      return state;
  }
};

export default reducer;
