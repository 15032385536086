import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '../default';

import { getWebsitePagesList } from './actions';
import { WebsitePageState } from './types';

export default createReducer(initialState as WebsitePageState, builder => {
  builder

    .addCase(getWebsitePagesList.pending, state => {
      state.pending = true;
    })
    .addCase(getWebsitePagesList.fulfilled, (state, { payload }) => {
      state.pending = false;
      state.fullfilled = true;
      state.data = payload;
    })
    .addCase(getWebsitePagesList.rejected, state => {
      state.pending = false;
      state.error = true;
    });
});
