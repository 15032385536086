import { State } from '../default';

const commonState = {
    pending: true,
    error: false,
    fullfilled: false
}

export const initialState = {
    list: {
        data: [],
        ...commonState
    },
    products: {
        data: [],
        ...commonState,
        pending: false
    },
    countries: {
        data: [],
        ...commonState
    },
    users: {
        data: [],
        ...commonState
    },
    campaignTypes: {
        data: [],
        ...commonState
    },
    stepTypes: {
        data: [],
        ...commonState
    },
    event: {
        data: null,
        ...commonState,
        pending: false
    },
    campaign: {
        data: null,
        ...commonState,
        pending: false
    },
    form: {
        ...commonState,
        pending: false
    },
    delete: {
        ...commonState,
        pending: false
    }
}

interface ListState extends State { data: any[] }
interface ProductsState extends State { data: any[] }
interface CountriesState extends State { data: any[] }
interface UsersState extends State { data: any[] }
interface CampaignTypesState extends State { data: any[] }
interface StepTypesState extends State { data: any[] }
interface EventState extends State { data: any }
interface CampaignState extends State { data: any }
interface FormState extends State { }

export interface CampaignBuilderState {
    list: ListState
    products: ProductsState
    countries: CountriesState
    users: UsersState
    campaignTypes: CampaignTypesState
    stepTypes: StepTypesState
    event: EventState
    campaign: CampaignState
    form: FormState
    delete: State
}