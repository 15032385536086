import { User, Feature, FeatureAccess } from './types';

export const defaultHomePages = [
    '/content-production',
    '/campaign/my-list'
];

export const decideHomePage = (user: User, countryFeatures: FeatureAccess[], profileFeatures: FeatureAccess[], teamFeatures: FeatureAccess[]) => { 
    // First priority: feature flag directly on user
    if (user.lightView) return '/campaign-builder';

    // Second priority: feature based on country
    const homePageFeaturesForCountry = countryFeatures 
        ? countryFeatures.filter(fa => fa.feature.type === 'page' && fa.feature.group === 'home_page' && fa.accessType === 'RW') 
        : [];

    const homePageFeaturesForProfile = profileFeatures
        ? profileFeatures.filter(fa => fa.feature.type === 'page' && fa.feature.group === 'home_page' && fa.accessType === 'RW')
        : [];

    if (homePageFeaturesForCountry.some(fa => fa.feature.code === 'default_view')) {
        return homePageFeaturesForProfile.some(fa => fa.feature.code === 'content_production') ? '/content-production' : '/campaign/my-list';
    }

    if (homePageFeaturesForCountry.some(fa => fa.feature.code === 'light_view')) {
        return '/campaign-builder';
    }

    return '/access-denied';
}