import { LOAD_PROFILES } from './types';

const initialState = {
    profileList:[]
};

export function profileSelectReducer(state = initialState, action) {
  switch (action.type) { 
    case LOAD_PROFILES:
      return { ...state, profileList: action.payload }; 
    default:
      return state;
  }
}