import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { Feature, FeatureAccess } from "../../../access-control/types";
import { Navigate } from 'react-router-dom';
import { featureSelector } from "../../../new-store/features/selectors";

interface Props {
    code: string
    fallback?: string | null | undefined
    children: ReactElement
}

const allowedAccessTypes = ['RW', 'RO'];

const PageControl = ({ code, fallback, children }: Props) => {
    // @ts-ignore
    const profile = useSelector(({ authentication }) => authentication.profile);
    const featureState = useSelector(featureSelector);

    const pageFeaturesForCountry = featureState.country.data 
        ? featureState.country.data.filter((fa: FeatureAccess) => fa.feature.type === 'page' && fa.feature.code === code && allowedAccessTypes.includes(fa.accessType))
        : [];
    const pageFeaturesForTeam = featureState.team.data
        ? featureState.team.data.filter((fa: FeatureAccess) => fa.feature.type === 'page' && fa.feature.code === code && allowedAccessTypes.includes(fa.accessType))
        : [];
    const pageFeaturesForProfile = featureState.profile.data
        ? featureState.profile.data.filter((fa: FeatureAccess) => fa.feature.type === 'page' && fa.feature.code === code && allowedAccessTypes.includes(fa.accessType))
        : [];

    const explicitDeniesForProfile = featureState.profile.data
        ? featureState.profile.data.filter((fa: FeatureAccess) => fa.feature.type === 'page' && fa.feature.code === code && fa.accessType === 'NA')
        : [];

    const allPageFeatures = [
        ...pageFeaturesForCountry, 
        ...pageFeaturesForTeam, 
        ...pageFeaturesForProfile
    ];

    // Special case to handle users that have the feature flag directly on ORC_USER
    if (code === 'light_view' && profile.lightView) {
        return children;
    }

    if (allPageFeatures.length === 0 || explicitDeniesForProfile.length > 0) {
        return <Navigate to={fallback ? fallback : '/access-denied'} />;
    }

    return children;
}

export default PageControl;
