import axios from 'axios';

export const addTokenToRequest = (authenticationService) => {
  axios.interceptors.request.use(
    async (config) => {
      const token = await authenticationService.getAccessToken();
      if (token != null && !config.url.includes('itx-bil-orchestra-filetransfer.s3')) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (err) => {
      return Promise.reject(err);
    },
  );
};
