import { State } from "../default";

const commonState = {
    pending: true,
    error: false,
    fullfilled: false,
}

export const initialState = {
    refresh: {
        ...commonState,
        pending: false
    },
    segmentation: {
        data: {},
        ...commonState
    },
    targets: {
        data: [],
        ...commonState
    },
    targetBookmarks: {
        data: [],
        ...commonState
    },
    attributes: {
        data: [],
        ...commonState
    }
}

interface SegmentationState extends State {
    data: any;
}

interface TargetsState extends State {
    data: any[];
}

export interface TargetBookmark {
    id?: number
    name: string
    filters: Record<string, string>
    numberOfUse?: number
    lastUseDate?: Date
    therapeutic?: { id: number }
    campaignType?: { id: number }
    product?: { id: number }
    isDefault?: boolean
    grouping?: string | null
    displayOrder?: number | null
    helpText?: string | null
}

interface TargetBookmarksState extends State {
    data: TargetBookmark[];
}

export interface Attribute {
    id: number
    name: string
    type: { id: number; name: string }
    subtype: { id: number; name: string } | null
}

interface AttributesState extends State {
    data: Attribute[];
}

interface RefreshState extends State { }

export interface TargetingState {
    refresh: RefreshState;
    segmentation: SegmentationState;
    targets: TargetsState;
    targetBookmarks: TargetBookmarksState;
    attributes: AttributesState;
}