import {
  LOAD_SURVEY_SUCCESS,
  LOAD_SURVEY_FAILURE,
  NAVIGATE_STEP_SURVEY,
  UPDATE_SURVEY_DRAFT,
  DELETE_FILE_FAILURE,
  DELETE_FILE_SUCCESS,
  CREATE_SURVEY_SUCCESS,
  RESET_SURVEY,
  SEND_FOR_REVIEW_SUCCESS,
  SEND_FOR_REVIEW_FAILURE,
  LOAD_SURVEY_TAGS_LIST,
  LOAD_SURVEY_TAGS_LIST_FAILURE,
  GET_SURVEY_TYPES_PENDING,
  GET_SURVEY_TYPES_SUCCESS,
  GET_SURVEY_TYPES_FAILURE
} from './types';

const initialState = {
  requestInProgress: false,
  activeStep: 0,
  surveyEditDataLoaded: false,
  surveyDraft: {
    stepId: -1,
    general: {},
    files: [],
    tags: [],
    canvasCustomTag: [],
    isSaved: 1,
    campaignDetails: [],
    validateErrors: {},
    prevNodeEndDate: '',
  },
  validateErrors: {},
  isSentForReview: false,
  formStatus: {},
  stepDisplayId: '',
  tagsListData: [],
};

export function surveyCreateReducer(state = initialState, action) {
  let res = [];
  switch (action.type) {
    case LOAD_SURVEY_SUCCESS:
      res = loadSurveyData(action.payload, action.stepId, action.prevNodeEndDate, action.prevNodeStartDate);
      return {
        ...state,
        requestInProgress: false,
        surveyDraft: res,
        surveyEditDataLoaded: true,
        isSentForReview: !action.payload.status.userEditable,
        formStatus: action.payload.status,
        stepDisplayId: action.payload.stepDisplayId,
      };
    case LOAD_SURVEY_FAILURE: // handle error
      return { ...state, requestInProgress: false };
    case DELETE_FILE_FAILURE: // handle error
      return { ...state, requestInProgress: false };
    case DELETE_FILE_SUCCESS:
      return { ...state, requestInProgress: false };
    case UPDATE_SURVEY_DRAFT:
      return { ...state, surveyDraft: action.payload, validateErrors: {} };
    case NAVIGATE_STEP_SURVEY:
      return { ...state, activeStep: action.step };
    case CREATE_SURVEY_SUCCESS:
      return {
        ...state,
        requestInProgress: false,
        surveyEditDataLoaded: false,
      };
    case RESET_SURVEY:
      return initialState;
    case SEND_FOR_REVIEW_SUCCESS:
      return { ...state, isSentForReview: true, surveyEditDataLoaded: false };
    case SEND_FOR_REVIEW_FAILURE: // handle error
      return { ...state, surveyEditDataLoaded: false };
    case LOAD_SURVEY_TAGS_LIST:
      return {
        ...state,
        tagsListData: action.payload,
      };
    case LOAD_SURVEY_TAGS_LIST_FAILURE:
    default:
      return state;
  }
}

export function loadSurveyData(payload, stepId, prevNodeEndDate, prevNodeStartDate) {
  const temp = payload;
  const res = {
    stepId: stepId,
    general: {},
    files: [],
    tags: [],
    canvasCustomTag: [],
    isSaved: 1,
    campaignDetails: [],
    validateErrors: {},
    default_values: {},
    prevNodeEndDate: '',
    prevNodeStartDate: ''
  };

  if (temp.survey) {
    res.general = { ...temp.survey };
  }
  if (temp.managedFiles) {
    res.files = temp.managedFiles;
  }
  if (temp.tags) {
    res.tags = temp.tags;
  }
  if (temp.canvasCustomTag) {
    res.canvasCustomTag = temp.canvasCustomTag;
  }
  if (prevNodeEndDate?.endDate) {
    res.prevNodeEndDate = prevNodeEndDate.endDate;
  }
  if (prevNodeStartDate?.startDate) {
    res.prevNodeStartDate = prevNodeStartDate.startDate;
  }

  return res;
}


export const surveyTypesReducer = (
  state = {
    data: null,
    pending: false,
    error: null,
  },
  action,
) => {
  switch (action.type) {
    case GET_SURVEY_TYPES_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_SURVEY_TYPES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        pending: false,
      };
    case GET_SURVEY_TYPES_FAILURE:
      return {
        ...state,
        error: action.payload,
        pending: false,
      };
    default:
      return state;
  }
};