import { createReducer } from '@reduxjs/toolkit';
import { setAdminListViewValue, getIntegrationTeamMembers, assignStepsToMember } from './actions';
import { AdminListViewState, AdminListViewValue } from './types';

const initialState: AdminListViewState = {
    value: AdminListViewValue.ADMIN,
    integrationTeamMembers: [],
    isLoadingAssigment: false,
}

export default createReducer(initialState, builder => {
    builder
        .addCase(setAdminListViewValue, (state, action) => {
            state.value = action.payload;
        })
        .addCase(getIntegrationTeamMembers.fulfilled, (state, action) => {
            state.integrationTeamMembers = action.payload;
        })
        .addCase(assignStepsToMember.pending, state => {
            state.isLoadingAssigment = true;
        })
        .addCase(assignStepsToMember.fulfilled, state => {
            state.isLoadingAssigment = false;
        })
        .addCase(assignStepsToMember.rejected, state => {
            state.isLoadingAssigment = false;
        });
})